import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function InventoryGrid({ sizes, brims, dataMap }) {
  // Generate column definitions
  const columnDefs = useMemo(() => {
    const columns = [
      {
        headerName: 'Size',
        field: 'sizeLabel',
        pinned: 'left',
        lockPinned: true,
        cellClass: 'size-column',
        suppressMovable: true,
        suppressSizeToFit: true,
        suppressResize: true,
        width: 80,
        headerClass: 'size-header'
      }
    ];

    // Create column groups for each brim and its shapes
    brims.forEach(brim => {
      const groupChildren = brim.shapes.map(shape => ({
        headerName: shape,
        field: `${brim.id}-${shape}`,
        cellClass: 'data-cell',
        headerClass: 'data-header',
        suppressMovable: true,
        suppressResize: true,
        width: 80,
        valueFormatter: params => {
          return params.value === undefined || params.value === null ? '' : params.value;
        }
      }));

      columns.push({
        headerName: brim.label,
        marryChildren: true,
        suppressMovable: true,
        children: groupChildren,
        headerClass: 'brim-group-header'
      });
    });

    return columns;
  }, [brims]);

  // Prepare row data
  const rowData = useMemo(() => {
    return sizes.map(size => {
      const row = { sizeLabel: size.label };
      brims.forEach(brim => {
        brim.shapes.forEach(shape => {
          const value = dataMap[size.id]?.[brim.id]?.[shape] ?? '';
          row[`${brim.id}-${shape}`] = value;
        });
      });
      return row;
    });
  }, [sizes, brims, dataMap]);

  const gridOptions = {
    defaultColDef: {
      resizable: false,
      sortable: false,
      filter: false,
      suppressMovable: true,
      valueFormatter: params => {
        return params.value === undefined || params.value === null ? '' : params.value;
      }
    },
    suppressHorizontalScroll: false,
    columnDefs: columnDefs,
    rowData: rowData,
    headerHeight: 40,
    groupHeaderHeight: 45,
    rowHeight: 35,
    suppressMenuHide: true
  };

  return (
    <div className="inventory-container">
      <div 
        className="ag-theme-quartz custom-grid" 
        style={{ width: '100%', height: '600px' }}
      >
        <AgGridReact {...gridOptions} />
      </div>
      <style>
        {`
          .custom-grid {
            --ag-header-height: 40px;
            --ag-header-foreground-color: #181D1F;
            --ag-header-background-color: #f8f9fa;
            --ag-header-cell-hover-background-color: #f8f9fa;
            --ag-header-cell-moving-background-color: #f8f9fa;
            --ag-borders: solid 1px;
            --ag-border-color: #181D1F26;
            --ag-cell-horizontal-border: solid #181D1F26;
            --ag-row-border-color: #181D1F26;
          }

          .ag-theme-quartz .ag-header-group-cell {
            border-right: 2px solid #181D1F40 !important;
          }

          .size-column {
            background-color: #f8f9fa;
            font-weight: 600;
            text-align: center;
            border-right: 2px solid #181D1F40 !important;
          }

          .data-cell {
            text-align: center;
          }

          .ag-header-cell {
            font-weight: 600;
            text-align: center;
          }

          .brim-group-header {
            background-color: #f1f3f5 !important;
            font-weight: 700;
            text-align: center;
            border-bottom: 2px solid #181D1F40;
          }

          .size-header {
            background-color: #f1f3f5 !important;
            font-weight: 700;
            border-bottom: 2px solid #181D1F40;
          }

          .ag-header-row {
            background-color: #f8f9fa;
          }
        `}
      </style>
    </div>
  );
}

function App() {
  // Test data
  const sizes = [
    { id: 51, label: '51' },
    { id: 52, label: '52' },
    { id: 53, label: '53' },
    { id: 54, label: '54' },
    { id: 55, label: '55' },
    { id: 56, label: '56' },
    { id: 57, label: '57' },
    { id: 58, label: '58' },
    { id: 59, label: '59' },
    { id: 60, label: '60' },
    { id: 61, label: '61' }
  ];

  const brims = [
    { id: '70', label: '70', shapes: ['R','L','XL','XR'] },
    { id: '74', label: '74', shapes: ['R','L','XL','XR'] },
    { id: '76', label: '76', shapes: ['R','L','XL','XR'] },
    { id: '78', label: '78', shapes: ['R','L','XL','XR'] },
    { id: '82', label: '82', shapes: ['R','L','XL','XR'] },
    { id: '86', label: '86', shapes: ['R','L','XL','XR'] },
    { id: '88', label: '88', shapes: ['R','L','XL','XR'] },
    { id: '90', label: '90', shapes: ['R','L','XL','XR'] },
    { id: '95', label: '95', shapes: ['R','L','XL','XR'] },
    { id: '100', label: '100', shapes: ['R','L','XL','XR'] },
    { id: '105', label: '105', shapes: ['R','L','XL','XR'] },
    { id: '110', label: '110', shapes: ['R','L','XL','XR'] },
    { id: '115', label: '115', shapes: ['R','L','XL','XR'] }
  ];

  // Example data map (random values)
  const dataMap = {
    51: {
      '70': { R: 1, L: 3, XL: 2, XR: 2 },
      '74': { R: 2, L: 1, XL: 2, XR: 2 },
      '76': { R: 1, L: 4, XL: 1, XR: 3 },
      '78': { R: 2, L: 2, XL: 1, XR: 0 },
      '82': { R: 9, L: 5, XL: 4, XR: 2 },
      '86': { R: 1, L: 0, XL: 1, XR: 1 },
      '88': { R: 2, L: 1, XL: 1, XR: 3 },
      '90': { R: 0, L: 2, XL: 2, XR: 1 },
      '95': { R: 1, L: 1, XL: 0, XR: 2 },
      '100': { R: 2, L: 0, XL: 0, XR: 1 },
      '105': { R: 3, L: 1, XL: 1, XR: 1 },
      '110': { R: 0, L: 0, XL: 0, XR: 0 },
      '115': { R: 0, L: 0, XL: 0, XR: 0 }
    },
    // Add partial/random data for other sizes to demonstrate structure
    52: {
      '70': { R: 4, L: 1, XL: 1, XR: 1 },
      '74': { R: 2, L: 2, XL: 2, XR: 2 },
      '76': { R: 1, L: 1, XL: 3, XR: 2 },
      '78': { R: 2, L: 2, XL: 1, XR: 2 }
    },
    // ... you can add more if needed
  };

  return (
    <>
      <h1 style={{ textAlign: 'center', margin: '20px 0' }}>Inventory Spreadsheet Test</h1>
      <InventoryGrid sizes={sizes} brims={brims} dataMap={dataMap} />
      <style>
        {`
          .custom-grid {
            border: 1px solid #ccc;
            font-family: Arial, sans-serif;
            font-size: 14px;
          }

          .ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-cell {
            border-right: 1px solid #ccc !important;
            border-bottom: 1px solid #ccc !important;
          }

          .ag-theme-alpine .ag-header-viewport {
            border-bottom: 2px solid #333;
          }

          .size-column {
            background-color: #b3e5fc; /* Light cyan-ish background */
            font-weight: bold;
            text-align: center;
          }

          .data-cell {
            text-align: center;
          }

          .ag-header-cell {
            background-color: #e0f7fa;
            font-weight: bold;
            text-align: center;
          }

          .brim-group-header {
            background-color: #00bcd4 !important;
            color: #fff;
            text-align: center;
          }

          .size-header {
            background-color: #00acc1 !important;
            color: #fff;
          }
        `}
      </style>
    </>
  );
}

export default      App;
