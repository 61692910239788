import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
} from '@mui/material';
import {
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  getInventoryCheckById,
  createInventoryCheck,
  updateInventoryCheck,
  getWarehouseItems,
} from '../services/api';

const InventoryCheckForm = () => {
  const { warehouseId, checkId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    warehouse_id: warehouseId,
    reset_uncounted_items: true,
    notes: '',
    items: []
  });
  
  const [warehouseItems, setWarehouseItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const items = await getWarehouseItems(warehouseId);
        setWarehouseItems(items);

        if (checkId) {
          const checkData = await getInventoryCheckById(checkId);
          setFormData({
            ...checkData,
            warehouse_id: warehouseId,
            items: checkData.items.map(item => ({
              warehouse_item_id: item.warehouse_item_id,
              previous_quantity: item.previous_quantity,
              counted_quantity: item.counted_quantity,
              notes: item.notes || ''
            }))
          });
        }
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to fetch data: ' + error.message,
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [warehouseId, checkId]);

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      const searchTerm = event.target.value.toLowerCase();
      const foundItem = warehouseItems.find(item => 
        item.item_name.toLowerCase().includes(searchTerm) ||
        item.item_code?.toLowerCase().includes(searchTerm)
      );
      
      if (foundItem) {
        handleAddItemToCount(foundItem);
        // Clear the search input
        event.target.value = '';
      } else {
        setSnackbar({
          open: true,
          message: 'Item not found',
          severity: 'warning'
        });
      }
    }
  };

  const handleAddItemToCount = (itemToAdd) => {
    const existingItemIndex = formData.items.findIndex(
      item => item.warehouse_item_id === itemToAdd.warehouse_item_id
    );

    if (existingItemIndex !== -1) {
      const newItems = [...formData.items];
      newItems[existingItemIndex] = {
        ...newItems[existingItemIndex],
        counted_quantity: (newItems[existingItemIndex].counted_quantity || 0) + 1
      };
      setFormData({ ...formData, items: newItems });
    } else {
      const newItem = {
        warehouse_item_id: itemToAdd.warehouse_item_id,
        previous_quantity: itemToAdd.quantity,
        counted_quantity: 1,
        notes: ''
      };
      setFormData({ ...formData, items: [...formData.items, newItem] });
    }
  };

  const handleRemoveItem = (warehouseItemId) => {
    setFormData({
      ...formData,
      items: formData.items.filter(item => item.warehouse_item_id !== warehouseItemId)
    });
  };

  const handleNotesChange = (index, value) => {
    const newItems = [...formData.items];
    newItems[index] = {
      ...newItems[index],
      notes: value
    };
    setFormData({ ...formData, items: newItems });
  };

  const handleSubmit = async () => {
    try {
      if (checkId) {
        await updateInventoryCheck(checkId, formData);
      } else {
        await createInventoryCheck(formData);
      }
      setSnackbar({
        open: true,
        message: `Inventory check ${checkId ? 'updated' : 'created'} successfully`,
        severity: 'success'
      });
      navigate(`/warehouses/${warehouseId}`);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to ${checkId ? 'update' : 'create'} inventory check`,
        severity: 'error'
      });
    }
  };

  const renderSearchField = () => (
    <Box sx={{ mb: 3 }}>
      <TextField
        fullWidth
        label="Scan barcode or type item name and press Enter"
        onKeyDown={handleSearchKeyDown}
        sx={{ maxWidth: 400 }}
        autoFocus
      />
    </Box>
  );

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3 }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5">
            {checkId ? 'Edit Inventory Check' : 'New Inventory Check'}
          </Typography>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(`/warehouses/${warehouseId}`)}
          >
            Back to Warehouse
          </Button>
        </Box>

        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Notes"
              multiline
              rows={4}
              value={formData.notes}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.reset_uncounted_items}
                  onChange={(e) => setFormData({ 
                    ...formData, 
                    reset_uncounted_items: e.target.checked 
                  })}
                />
              }
              label="Reset uncounted items to 0"
            />
          </Grid>
        </Grid>

        {renderSearchField()}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Previous Quantity</TableCell>
                <TableCell>Counted Quantity</TableCell>
                <TableCell>Difference</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.items.map((item, index) => {
                const warehouseItem = warehouseItems.find(wi => wi.warehouse_item_id === item.warehouse_item_id);
                const difference = item.counted_quantity - item.previous_quantity;
                
                return (
                  <TableRow key={item.warehouse_item_id}>
                    <TableCell>{warehouseItem?.item_name}</TableCell>
                    <TableCell>{warehouseItem?.item_type}</TableCell>
                    <TableCell>{item.previous_quantity}</TableCell>
                    <TableCell>{item.counted_quantity}</TableCell>
                    <TableCell>
                      <Typography
                        color={difference > 0 ? 'success.main' : difference < 0 ? 'error.main' : 'text.primary'}
                      >
                        {difference > 0 ? '+' : ''}{difference}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={item.notes}
                        onChange={(e) => handleNotesChange(index, e.target.value)}
                        size="small"
                        placeholder="Add notes..."
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveItem(item.warehouse_item_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={() => setConfirmDialog(true)}
          >
            {checkId ? 'Update' : 'Create'} Inventory Check
          </Button>
        </Box>
      </Paper>

      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle>Confirm {checkId ? 'Update' : 'Creation'}</DialogTitle>
        <DialogContent>
          Are you sure you want to {checkId ? 'update' : 'create'} this inventory check?
          {formData.reset_uncounted_items && (
            <Typography color="warning.main" sx={{ mt: 2 }}>
              Warning: Uncounted items will be reset to 0 when this check is confirmed.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default InventoryCheckForm; 