import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://pimbyeli.com/api/customer'
  : '/api/customer';

const customerApi = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 30000,
  headers: {
    'Accept': 'application/json'
  }
});

customerApi.interceptors.request.use((config) => {
  const publicPaths = [
    '/auth/request-reset',
    '/auth/reset-password',
    '/auth/login'
  ];
  
  if (!publicPaths.some(path => config.url.endsWith(path))) {
    const customerToken = localStorage.getItem('customerToken');
    if (customerToken) {
      config.headers['Authorization'] = `Bearer ${customerToken}`;
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

customerApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('customerToken');
      localStorage.removeItem('customerData');
      window.location.href = '/customer/login';
    }
    return Promise.reject(error);
  }
);

export const customerLogin = async (credentials) => {
  try {
    const response = await customerApi.post('/auth/login', credentials);
    if (response.data.token) {
      localStorage.setItem('customerToken', response.data.token);
      localStorage.setItem('customerData', JSON.stringify(response.data.customer));
    }
    return response;
  } catch (error) {
    console.error('Login error:', error.response?.data || error.message);
    throw error;
  }
};

export const checkCustomerAuth = () => {
  const token = localStorage.getItem('customerToken');
  if (!token) return false;
  
  try {
    // You might want to add token expiration check here if your token includes exp
    return true;
  } catch (error) {
    return false;
  }
};

export const customerLogout = () => {
  localStorage.removeItem('customerToken');
  localStorage.removeItem('customerData');
};

export const requestPasswordReset = async (email) => {
  try {
    const response = await customerApi.post('/auth/request-reset', { email }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Password reset request error:', error);
    throw error;
  }
};

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await customerApi.post('/auth/reset-password', {
      token,
      newPassword
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Password reset error:', error);
    throw error;
  }
};

export const getCustomerProfile = async () => {
  try {
    const response = await customerApi.get('/profile');
    return response.data;
  } catch (error) {
    console.error('Error fetching profile:', error);
    throw error;
  }
};

export const getOrderDetails = async (orderId) => {
  try {
    const response = await customerApi.get(`/orders/${orderId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching order details:', error);
    throw error;
  }
};

export const addOrderComment = async (orderId, commentText) => {
  try {
    const response = await customerApi.post(`/orders/${orderId}/comments`, {
      comment_text: commentText
    });
    return response.data;
  } catch (error) {
    console.error('Error adding comment:', error);
    throw error;
  }
};

export const getCustomerOrders = async () => {
  try {
    const response = await customerApi.get('/orders');
    return response.data;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

export const createCustomerOrder = async (orderData) => {
  try {
    const response = await customerApi.post('/orders', {
      order_number: orderData.order_number || '',
      notes: orderData.notes,
      customer_notes: orderData.customer_notes,
      eta_type: orderData.eta_type,
      requested_eta_date: orderData.requested_eta_date || '',
      items: orderData.items.map(item => ({
        item_id: item.item_id,
        quantity: item.quantity,
        notes: item.notes || ''
      }))
    });
    return response.data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const getAllItems = async () => {
  try {
    const response = await customerApi.get('/items');
    return response.data;
  } catch (error) {
    console.error('Error fetching items:', error);
    throw error;
  }
};

export const getSpreadsheetData = async (type) => {
  try {
    const response = await customerApi.get(`/spreadsheet/${type}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching spreadsheet data:', error);
    throw error;
  }
};

export default customerApi;