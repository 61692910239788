import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Typography,
  Button,
  CircularProgress,
  FormControl,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Menu,
  MenuItem,
  Snackbar,
  IconButton,
  Fade,
  Backdrop
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/system';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import { MultiSelect } from 'primereact/multiselect';
import {
  calculatePercentages,
  getAllItems,
  getItemsByLocation,
  getLocations,
  updateReplenishLevel,
  resetManualReplenishLevels,
  changeReplenishToAutomatic
} from '../services/api';
import { MoreVert as MoreVertIcon, Edit as EditIcon, Autorenew as AutorenewIcon, VisibilityOff as VisibilityOffIcon, Fullscreen as FullscreenIcon, FullscreenExit as FullscreenExitIcon } from '@mui/icons-material';
import { classNames } from 'primereact/utils';

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  width: '100%',
  maxWidth: '100vw',
  boxSizing: 'border-box',
});

const StyledTableContainer = styled(Paper)({
  width: '90%',
  margin: 'auto',
  padding: '1rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
  },
}));

const SpreadsheetContainer = styled('div')({
  overflowX: 'auto',
  padding: '1rem',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
});

const SpreadsheetGrid = styled('div')({
  display: 'grid',
  gap: '1px',
  backgroundColor: '#f0f0f0',
  '& > div': {
    backgroundColor: '#fff',
    padding: '8px',
    minWidth: '100px',
    textAlign: 'center',
  },
});

const SpreadsheetHeader = styled('div')({
  fontWeight: 'bold',
  backgroundColor: '#f5f5f5 !important',
});

const SpreadsheetCell = styled('div')(({ isHeader, isEmpty }) => ({
  padding: '8px',
  border: '1px solid #e0e0e0',
  backgroundColor: isEmpty ? '#f9f9f9' : '#fff',
  color: isEmpty ? '#999' : '#000',
  ...(isHeader && {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
  }),
}));

const SpreadsheetWrapper = styled('div')({
  padding: '1rem',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  width: '100%',
  overflow: 'hidden',
});

const SpreadsheetToolbar = styled('div')({
  display: 'flex',
  gap: '1rem',
  marginBottom: '1rem',
  padding: '0.5rem',
  backgroundColor: '#f5f5f5',
  borderRadius: '4px',
  alignItems: 'center',
  flexWrap: 'wrap',
});

const SpreadsheetTable = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  backgroundColor: '#fff',
  '& th, & td': {
    border: '1px solid #e0e0e0',
    padding: '6px 8px',
    position: 'relative',
    textAlign: 'center',
    minWidth: '70px',
    fontSize: '13px',
    height: '24px',
    lineHeight: '1.2',
  },
  '& th': {
    backgroundColor: '#f8f9fa',
    fontWeight: '500',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    color: '#202124',
    borderBottom: '2px solid #9aa0a6',
  },
  '& tr:first-child th': {
    borderTop: '1px solid #e0e0e0',
    borderBottom: '1px solid #e0e0e0',
    '&:first-child': {
      borderLeft: '2px solid #9aa0a6',
    },
    '&:last-child': {
      borderRight: '2px solid #9aa0a6',
    },
  },
  '& td': {
    backgroundColor: '#fff',
    color: '#202124',
    '&:hover': {
      backgroundColor: '#f8f9fa',
    },
    '&.empty': {
      backgroundColor: '#f8f9fa',
      color: '#70757a',
    },
  },
  '& .sticky-corner': {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    backgroundColor: '#f8f9fa',
    borderRight: '2px solid #9aa0a6',
    fontWeight: '500',
  },
  '& th[colspan]': {
    borderLeft: '1px solid #e0e0e0',
    borderRight: '1px solid #e0e0e0',
    fontWeight: '500',
    backgroundColor: '#f8f9fa',
  },
  '& tbody th': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#f8f9fa',
    zIndex: 1,
    borderRight: '2px solid #9aa0a6',
    fontWeight: '500',
  },
  '& tbody tr': {
    '&:hover': {
      '& td': {
        backgroundColor: '#f8f9fa',
      },
    },
    '&:last-child': {
      '& td, & th': {
        borderBottom: '2px solid #9aa0a6',
      },
    },
  },
  '& td.brim-group-start': {
    borderLeft: '2px solid #9aa0a6',
  },
  '& td.brim-group-end': {
    borderRight: '2px solid #9aa0a6',
  },
  '& .brim-header': {
    borderLeft: '2px solid #9aa0a6',
    borderRight: '2px solid #9aa0a6',
    backgroundColor: '#f8f9fa',
  },
  '& input': {
    width: '100%',
    padding: '2px 4px',
    border: 'none',
    background: 'transparent',
    textAlign: 'center',
    fontSize: '13px',
    '&:focus': {
      outline: '2px solid #1a73e8',
      outlineOffset: '-2px',
      backgroundColor: '#fff',
    },
  },
  '& .edited': {
    backgroundColor: '#e8f0fe',
  },
  '& td.total-cell': {
    backgroundColor: '#f8f9fa',
    fontWeight: '500',
    color: '#202124',
  },
});

const ToggleButton = styled(IconButton)(({ visible }) => ({
  padding: '2px',
  position: 'absolute',
  right: '2px',
  top: '2px',
  opacity: visible ? 0.7 : 0,
  visibility: visible ? 'visible' : 'hidden',
  transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  border: '1px solid #dadce0',
  '&:hover': {
    opacity: 1,
    backgroundColor: '#f8f9fa',
  },
  '.MuiSvgIcon-root': {
    fontSize: '0.875rem',
    color: '#5f6368',
  },
}));

const ControlsPanel = styled('div')({
  display: 'flex',
  gap: '0.75rem',
  padding: '0.75rem',
  backgroundColor: '#f8f9fa',
  borderRadius: '4px',
  marginBottom: '1rem',
  flexWrap: 'wrap',
  alignItems: 'center',
  border: '1px solid #e0e0e0',
});

const ControlGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  padding: '0.5rem',
  borderRadius: '4px',
  backgroundColor: '#fff',
  border: '1px solid #e0e0e0',
  '&:hover': {
    borderColor: '#9aa0a6',
  },
});

const StyledSelect = styled('select')({
  padding: '6px 24px 6px 8px',
  borderRadius: '4px',
  border: '1px solid #dadce0',
  backgroundColor: '#fff',
  minWidth: '120px',
  fontSize: '13px',
  color: '#202124',
  appearance: 'none',
  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23202124' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 4px center',
  backgroundSize: '16px',
  '&:focus': {
    outline: 'none',
    borderColor: '#1a73e8',
    boxShadow: '0 0 0 2px rgba(26,115,232,0.2)',
  },
  '&:hover': {
    borderColor: '#9aa0a6',
  },
});

const DataTypeSelect = styled(StyledSelect)({
  minWidth: '200px',
});

const TotalCell = styled('td')({
  backgroundColor: '#f8f9fa !important',
  fontWeight: '500',
  color: '#202124',
  borderBottom: '2px solid #9aa0a6 !important',
  borderRight: '2px solid #9aa0a6 !important',
});

const TotalHeaderCell = styled('th')({
  backgroundColor: '#f8f9fa !important',
  fontWeight: '500',
  color: '#202124',
  borderBottom: '2px solid #9aa0a6 !important',
  borderLeft: '2px solid #9aa0a6 !important',
  borderRight: '2px solid #9aa0a6 !important',
});

const FullscreenWrapper = styled('div')(({ isFullscreen }) => ({
  position: isFullscreen ? 'fixed' : 'relative',
  top: isFullscreen ? 0 : 'auto',
  left: isFullscreen ? 0 : 'auto',
  right: isFullscreen ? 0 : 'auto',
  bottom: isFullscreen ? 0 : 'auto',
  zIndex: isFullscreen ? 1300 : 'auto',
  background: '#fff',
  padding: isFullscreen ? '1rem' : 0,
  width: '100%',
  height: isFullscreen ? '100vh' : 'auto',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const LocationSpreadsheetView = ({ items, locations, selectedLocations, onLocationChange }) => {
  const [selectedType, setSelectedType] = useState(() => {
    return localStorage.getItem('locationSpreadsheetType') || '';
  });
  const [hiddenSizes, setHiddenSizes] = useState(() => {
    const savedHidden = new Set(JSON.parse(localStorage.getItem('locationHiddenSizes') || '[]'));
    return new Set(savedHidden);
  });
  const [hiddenBrims, setHiddenBrims] = useState(() => {
    const savedHidden = new Set(JSON.parse(localStorage.getItem('locationHiddenBrims') || '[]'));
    return new Set([...savedHidden, '73', '79', '85', '91']);
  });
  const [hiddenShapes, setHiddenShapes] = useState(() => {
    return new Set(JSON.parse(localStorage.getItem('locationHiddenShapes') || '[]'));
  });
  const [showHideControls, setShowHideControls] = useState(false);
  const [tableSize, setTableSize] = useState(() => {
    return localStorage.getItem('locationSpreadsheetTableSize') || '100';
  });
  const [selectedDataType, setSelectedDataType] = useState(() => {
    return localStorage.getItem('locationSpreadsheetDataType') || 'inventory';
  });
  const [hideZeros, setHideZeros] = useState(() => {
    return localStorage.getItem('locationSpreadsheetHideZeros') === 'true';
  });
  const [isFullscreen, setIsFullscreen] = useState(false);

  const types = useMemo(() => {
    const uniqueTypes = [...new Set(items.map(item => item.item_type))].sort();
    if (!selectedType && uniqueTypes.length > 0) {
      const savedType = localStorage.getItem('locationSpreadsheetType');
      setSelectedType(savedType || uniqueTypes[0]);
    }
    return uniqueTypes;
  }, [items, selectedType]);

  useEffect(() => {
    if (selectedType) {
      localStorage.setItem('locationSpreadsheetType', selectedType);
    }
  }, [selectedType]);

  useEffect(() => {
    localStorage.setItem('locationHiddenSizes', JSON.stringify([...hiddenSizes]));
    localStorage.setItem('locationHiddenBrims', JSON.stringify([...hiddenBrims]));
    localStorage.setItem('locationHiddenShapes', JSON.stringify([...hiddenShapes]));
  }, [hiddenSizes, hiddenBrims, hiddenShapes]);

  useEffect(() => {
    localStorage.setItem('locationSpreadsheetTableSize', tableSize);
  }, [tableSize]);

  useEffect(() => {
    localStorage.setItem('locationSpreadsheetDataType', selectedDataType);
  }, [selectedDataType]);

  useEffect(() => {
    localStorage.setItem('locationSpreadsheetHideZeros', hideZeros);
  }, [hideZeros]);

  const dataTypeOptions = [
    { value: 'inventory', label: 'Inventory Levels' },
    { value: 'replenish', label: 'Replenish Levels' },
    { value: 'manual', label: 'Manual/Auto Status' },
  ];

  const spreadsheetData = useMemo(() => {
    if (!selectedType || selectedLocations.length !== 1) return null;

    const typeItems = items.filter(item => 
      item.item_type === selectedType && 
      item.location_id === selectedLocations[0]
    );
    
    if (typeItems.length === 0) return null;

    const allBrims = [...new Set(typeItems.map(item => item.brim))]
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .filter(brim => !hiddenBrims.has(brim));
      
    const shapeOrder = { 'XR': 0, 'R': 1, 'L': 2, 'X': 3 };
    const allShapes = [...new Set(typeItems.map(item => item.shape))]
      .sort((a, b) => (shapeOrder[a] || 99) - (shapeOrder[b] || 99))
      .filter(shape => !hiddenShapes.has(shape));

    const allSizes = [...new Set(typeItems.map(item => item.size))]
      .filter(size => !hiddenSizes.has(size))
      .sort((a, b) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (isNaN(numA) || isNaN(numB)) {
          return a.localeCompare(b);
        }
        return numA - numB;
      });

    const itemsMap = {};
    typeItems.forEach(item => {
      if (!hiddenSizes.has(item.size)) {
        const key = `${item.size}-${item.brim}-${item.shape}`;
        itemsMap[key] = item;
      }
    });

    return {
      sizes: allSizes,
      brims: allBrims,
      shapes: allShapes,
      itemsMap
    };
  }, [selectedType, items, selectedLocations, hiddenSizes, hiddenBrims, hiddenShapes]);

  const toggleSize = (size) => {
    setHiddenSizes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(size)) {
        newSet.delete(size);
      } else {
        newSet.add(size);
      }
      return newSet;
    });
  };

  const toggleBrim = (brim) => {
    setHiddenBrims(prev => {
      const newSet = new Set(prev);
      if (newSet.has(brim)) {
        newSet.delete(brim);
      } else {
        newSet.add(brim);
      }
      return newSet;
    });
  };

  const toggleShape = (shape) => {
    setHiddenShapes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(shape)) {
        newSet.delete(shape);
      } else {
        newSet.add(shape);
      }
      return newSet;
    });
  };

  const handleUnhideAll = (type) => {
    switch (type) {
      case 'sizes':
        setHiddenSizes(new Set());
        break;
      case 'brims':
        setHiddenBrims(new Set());
        break;
      case 'shapes':
        setHiddenShapes(new Set());
        break;
      case 'all':
        setHiddenSizes(new Set());
        setHiddenBrims(new Set());
        setHiddenShapes(new Set());
        break;
    }
  };

  const sizeOptions = [
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "85", label: "85%" },
    { value: "100", label: "100%" },
    { value: "125", label: "125%" },
    { value: "150", label: "150%" },
    { value: "175", label: "175%" },
    { value: "200", label: "200%" },
  ];

  const getCellValue = useCallback((item) => {
    if (!item) return '-';
    
    let value;
    switch (selectedDataType) {
      case 'inventory':
        value = item.inventory_level || item.total_inventory || 0;
        break;
      case 'replenish':
        value = item.replenish_level || item.total_replenish_level || 0;
        break;
      case 'manual':
        value = item.manual_item_replenish ? 'M' : 'A';
        break;
      case 'percentTotal':
        value = item.percent_of_total || 0;
        break;
      case 'percentType':
        value = item.percent_of_type || 0;
        break;
      case 'sales':
        value = item.total_sales || 0;
        break;
      default:
        value = item.inventory_level || item.total_inventory || 0;
    }

    if (hideZeros && (value === 0 || value === '0') && selectedDataType !== 'manual') {
      return '';
    }

    return value;
  }, [selectedDataType, hideZeros]);

  const handleLocationSelect = (e) => {
    const locationId = e.target.value;
    onLocationChange({ 
      value: locationId ? [parseInt(locationId)] : [] 
    });
  };

  if (!spreadsheetData) return null;

  return (
    <FullscreenWrapper isFullscreen={isFullscreen}>
      <SpreadsheetWrapper>
        <ControlsPanel>
          <ControlGroup>
            <Typography variant="subtitle2">Location:</Typography>
            <StyledSelect
              value={selectedLocations[0] || ''}
              onChange={handleLocationSelect}
            >
              <option value="">Select Location</option>
              {locations.map(location => (
                <option key={location.db_location_id} value={location.db_location_id}>
                  {location.name}
                </option>
              ))}
            </StyledSelect>
          </ControlGroup>

          <ControlGroup>
            <Typography variant="subtitle2">Type:</Typography>
            <StyledSelect
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">Select Type</option>
              {types.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </StyledSelect>
          </ControlGroup>

          <ControlGroup>
            <Typography variant="subtitle2">Data Type:</Typography>
            <DataTypeSelect
              value={selectedDataType}
              onChange={(e) => setSelectedDataType(e.target.value)}
            >
              {dataTypeOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </DataTypeSelect>
          </ControlGroup>

          <ControlGroup>
            <Typography variant="subtitle2">Table Size:</Typography>
            <StyledSelect
              value={tableSize}
              onChange={(e) => setTableSize(e.target.value)}
            >
              {sizeOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </StyledSelect>
          </ControlGroup>

          <ControlGroup>
            <Button
              variant="contained"
              size="small"
              onClick={() => setShowHideControls(!showHideControls)}
              startIcon={<VisibilityOffIcon />}
              color={showHideControls ? "primary" : "inherit"}
            >
              {showHideControls ? 'Hide Controls' : 'Show Hide Controls'}
            </Button>
          </ControlGroup>

          {showHideControls && (
            <>
              <ControlGroup>
                <StyledSelect
                  onChange={(e) => {
                    if (e.target.value) {
                      handleUnhideAll(e.target.value);
                      e.target.value = '';
                    }
                  }}
                  value=""
                >
                  <option value="">Unhide Elements...</option>
                  <option value="sizes">All Sizes</option>
                  <option value="brims">All Brims</option>
                  <option value="shapes">All Shapes</option>
                  <option value="all">Everything</option>
                </StyledSelect>
              </ControlGroup>

              <ControlGroup>
                <Typography variant="subtitle2" color="textSecondary">
                  Hidden: Sizes ({hiddenSizes.size}), Brims ({hiddenBrims.size}), Shapes ({hiddenShapes.size})
                </Typography>
              </ControlGroup>

              <ControlGroup>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setHideZeros(!hideZeros)}
                  color={hideZeros ? "primary" : "inherit"}
                >
                  {hideZeros ? 'Show Zeros' : 'Hide Zeros'}
                </Button>
              </ControlGroup>
            </>
          )}

          <ControlGroup>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setIsFullscreen(!isFullscreen)}
              startIcon={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            >
              {isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
            </Button>
          </ControlGroup>
        </ControlsPanel>

        {selectedLocations.length === 1 && spreadsheetData ? (
          <div className="overflow-auto" style={{ 
            height: isFullscreen ? 'calc(100vh - 150px)' : '70vh',
            position: 'relative',
            width: `${parseInt(tableSize) > 100 ? '100%' : 'auto'}`,
            maxWidth: `${parseInt(tableSize) > 100 ? '100vw' : 'none'}`,
            overflow: 'auto',
            margin: '0 auto',
          }}>
            <SpreadsheetTable style={{ 
              transform: `scale(${parseInt(tableSize) / 100})`, 
              transformOrigin: 'top left',
              width: `${parseInt(tableSize) > 100 ? `${10000 / parseInt(tableSize)}%` : '100%'}`,
              margin: '0 auto',
            }}>
              <thead>
                <tr>
                  <th className="sticky-corner">
                    Size
                    <div style={{ fontSize: '0.8em', color: '#666' }}>
                      {dataTypeOptions.find(opt => opt.value === selectedDataType)?.label}
                    </div>
                  </th>
                  {spreadsheetData.brims.map(brim => (
                    <th 
                      key={brim}
                      colSpan={spreadsheetData.shapes.length}
                      className="brim-header"
                    >
                      {brim}
                      <ToggleButton 
                        size="small"
                        onClick={() => toggleBrim(brim)}
                        visible={showHideControls}
                      >
                        <VisibilityOffIcon fontSize="small" />
                      </ToggleButton>
                    </th>
                  ))}
                  <th rowSpan="2" className="sticky-corner">Total</th>
                </tr>
                <tr>
                  <th></th>
                  {spreadsheetData.brims.map(brim => 
                    spreadsheetData.shapes.map(shape => (
                      <th key={`${brim}-${shape}`} className="shape-header">
                        {shape}
                        <ToggleButton 
                          size="small"
                          onClick={() => toggleShape(shape)}
                          visible={showHideControls}
                        >
                          <VisibilityOffIcon fontSize="small" />
                        </ToggleButton>
                      </th>
                    ))
                  )}
                </tr>
              </thead>
              <tbody>
                {spreadsheetData.sizes.map(size => (
                  <tr key={size}>
                    <th>
                      {size}
                      <ToggleButton 
                        size="small"
                        onClick={() => toggleSize(size)}
                        visible={showHideControls}
                      >
                        <VisibilityOffIcon fontSize="small" />
                      </ToggleButton>
                    </th>
                    {spreadsheetData.brims.map(brim =>
                      spreadsheetData.shapes.map((shape, shapeIndex) => {
                        const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                        return (
                          <td 
                            key={`${size}-${brim}-${shape}`}
                            className={classNames({ 
                              empty: !item,
                              'brim-group-start': shapeIndex === 0,
                              'brim-group-end': shapeIndex === spreadsheetData.shapes.length - 1
                            })}
                          >
                            {getCellValue(item)}
                          </td>
                        );
                      })
                    )}
                    <TotalCell>
                      {spreadsheetData.brims.reduce((total, brim) =>
                        total + spreadsheetData.shapes.reduce((brimTotal, shape) => {
                          const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                          const value = item ? parseFloat(getCellValue(item)) || 0 : 0;
                          return brimTotal + value;
                        }, 0)
                      , 0).toFixed(2)}
                    </TotalCell>
                  </tr>
                ))}
                {/* Shape Totals Row */}
                <tr>
                  <TotalHeaderCell>Shape Totals</TotalHeaderCell>
                  {spreadsheetData.brims.map(brim =>
                    spreadsheetData.shapes.map((shape, shapeIndex) => (
                      <TotalCell key={`total-${brim}-${shape}`}>
                        {spreadsheetData.sizes.reduce((total, size) => {
                          const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                          const value = item ? parseFloat(getCellValue(item)) || 0 : 0;
                          return total + value;
                        }, 0).toFixed(2)}
                      </TotalCell>
                    ))
                  )}
                  <TotalCell>
                    {/* Grand Total */}
                    {spreadsheetData.sizes.reduce((total, size) =>
                      total + spreadsheetData.brims.reduce((brimTotal, brim) =>
                        brimTotal + spreadsheetData.shapes.reduce((shapeTotal, shape) => {
                          const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                          const value = item ? parseFloat(getCellValue(item)) || 0 : 0;
                          return shapeTotal + value;
                        }, 0)
                      , 0)
                    , 0).toFixed(2)}
                  </TotalCell>
                </tr>
                {/* Brim Totals Row */}
                <tr>
                  <TotalHeaderCell>Brim Totals</TotalHeaderCell>
                  {spreadsheetData.brims.map(brim => (
                    <TotalCell 
                      key={`brim-total-${brim}`} 
                      colSpan={spreadsheetData.shapes.length}
                      className="brim-total"
                    >
                      {spreadsheetData.sizes.reduce((total, size) =>
                        total + spreadsheetData.shapes.reduce((shapeTotal, shape) => {
                          const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                          const value = item ? parseFloat(getCellValue(item)) || 0 : 0;
                          return shapeTotal + value;
                        }, 0)
                      , 0).toFixed(2)}
                    </TotalCell>
                  ))}
                  <TotalCell></TotalCell>
                </tr>
              </tbody>
            </SpreadsheetTable>
          </div>
        ) : (
          <Typography 
            variant="h6" 
            style={{ 
              textAlign: 'center', 
              marginTop: '2rem',
              color: '#666'
            }}
          >
            {!selectedLocations.length ? 
              'Please select a location to view the spreadsheet' : 
              'No data available for the selected location and type'}
          </Typography>
        )}
      </SpreadsheetWrapper>
    </FullscreenWrapper>
  );
};

const ReplenishLevelSpreadsheetView = ({ items, locations, selectedLocations, onLocationChange, setItemsByLocation }) => {
  const [selectedType, setSelectedType] = useState(() => {
    return localStorage.getItem('replenishSpreadsheetType') || '';
  });
  const [hiddenSizes, setHiddenSizes] = useState(() => {
    const savedHidden = new Set(JSON.parse(localStorage.getItem('replenishHiddenSizes') || '[]'));
    return new Set(savedHidden);
  });
  const [hiddenBrims, setHiddenBrims] = useState(() => {
    const savedHidden = new Set(JSON.parse(localStorage.getItem('replenishHiddenBrims') || '[]'));
    return new Set([...savedHidden, '73', '79', '85', '91']);
  });
  const [hiddenShapes, setHiddenShapes] = useState(() => {
    return new Set(JSON.parse(localStorage.getItem('replenishHiddenShapes') || '[]'));
  });
  const [showHideControls, setShowHideControls] = useState(false);
  const [tableSize, setTableSize] = useState(() => {
    return localStorage.getItem('replenishSpreadsheetTableSize') || '100';
  });
  const [selectedDataType, setSelectedDataType] = useState('replenish');
  const [hideZeros, setHideZeros] = useState(() => {
    return localStorage.getItem('replenishSpreadsheetHideZeros') === 'true';
  });
  const [editedCells, setEditedCells] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isFullscreen, setIsFullscreen] = useState(false);

  const types = useMemo(() => {
    const uniqueTypes = [...new Set(items.map(item => item.item_type))].sort();
    if (!selectedType && uniqueTypes.length > 0) {
      const savedType = localStorage.getItem('replenishSpreadsheetType');
      setSelectedType(savedType || uniqueTypes[0]);
    }
    return uniqueTypes;
  }, [items, selectedType]);

  useEffect(() => {
    if (selectedType) {
      localStorage.setItem('replenishSpreadsheetType', selectedType);
    }
  }, [selectedType]);

  useEffect(() => {
    localStorage.setItem('replenishHiddenSizes', JSON.stringify([...hiddenSizes]));
    localStorage.setItem('replenishHiddenBrims', JSON.stringify([...hiddenBrims]));
    localStorage.setItem('replenishHiddenShapes', JSON.stringify([...hiddenShapes]));
  }, [hiddenSizes, hiddenBrims, hiddenShapes]);

  useEffect(() => {
    localStorage.setItem('replenishSpreadsheetTableSize', tableSize);
  }, [tableSize]);

  const spreadsheetData = useMemo(() => {
    if (!selectedType || selectedLocations.length !== 1) return null;

    const typeItems = items.filter(item => 
      item.item_type === selectedType && 
      item.location_id === selectedLocations[0]
    );
    
    if (typeItems.length === 0) return null;

    const allBrims = [...new Set(typeItems.map(item => item.brim))]
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .filter(brim => !hiddenBrims.has(brim));
      
    const shapeOrder = { 'XR': 0, 'R': 1, 'L': 2, 'X': 3 };
    const allShapes = [...new Set(typeItems.map(item => item.shape))]
      .sort((a, b) => (shapeOrder[a] || 99) - (shapeOrder[b] || 99))
      .filter(shape => !hiddenShapes.has(shape));

    const allSizes = [...new Set(typeItems.map(item => item.size))]
      .filter(size => !hiddenSizes.has(size))
      .sort((a, b) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (isNaN(numA) || isNaN(numB)) {
          return a.localeCompare(b);
        }
        return numA - numB;
      });

    const itemsMap = {};
    typeItems.forEach(item => {
      if (!hiddenSizes.has(item.size)) {
        const key = `${item.size}-${item.brim}-${item.shape}`;
        itemsMap[key] = item;
      }
    });

    return {
      sizes: allSizes,
      brims: allBrims,
      shapes: allShapes,
      itemsMap
    };
  }, [selectedType, items, selectedLocations, hiddenSizes, hiddenBrims, hiddenShapes]);

  const handleCellEdit = (item, value) => {
    if (!item) return;
    
    const cellKey = `${item.item_id}-${item.location_id}`;
    setEditedCells(prev => ({
      ...prev,
      [cellKey]: {
        itemId: item.item_id,
        locationId: item.location_id,
        value: parseInt(value) || 0
      }
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const updates = Object.values(editedCells);
      
      for (const update of updates) {
        await updateReplenishLevel(
          update.itemId,
          update.locationId,
          update.value
        );
      }
      
      setSnackbarMessage('Replenish levels updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setEditedCells({});
      setConfirmDialogOpen(false);
      
      // Refresh data after saving changes
      const itemsByLocationData = await getItemsByLocation();
      setItemsByLocation(itemsByLocationData);
      
    } catch (error) {
      console.error('Error updating replenish levels:', error);
      setSnackbarMessage('Failed to update replenish levels');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const getCellValue = useCallback((item) => {
    if (!item) return '-';
    
    const cellKey = `${item.item_id}-${item.location_id}`;
    const editedValue = editedCells[cellKey]?.value;
    
    if (editedValue !== undefined) {
      return editedValue;
    }
    
    return item.replenish_level || 0;
  }, [editedCells]);

  const handleLocationSelect = (e) => {
    const locationId = e.target.value;
    onLocationChange({ 
      value: locationId ? [parseInt(locationId)] : [] 
    });
  };

  const toggleSize = (size) => {
    setHiddenSizes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(size)) {
        newSet.delete(size);
      } else {
        newSet.add(size);
      }
      return newSet;
    });
  };

  const toggleBrim = (brim) => {
    setHiddenBrims(prev => {
      const newSet = new Set(prev);
      if (newSet.has(brim)) {
        newSet.delete(brim);
      } else {
        newSet.add(brim);
      }
      return newSet;
    });
  };

  const toggleShape = (shape) => {
    setHiddenShapes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(shape)) {
        newSet.delete(shape);
      } else {
        newSet.add(shape);
      }
      return newSet;
    });
  };

  const handleUnhideAll = (type) => {
    switch (type) {
      case 'sizes':
        setHiddenSizes(new Set());
        break;
      case 'brims':
        setHiddenBrims(new Set());
        break;
      case 'shapes':
        setHiddenShapes(new Set());
        break;
      case 'all':
        setHiddenSizes(new Set());
        setHiddenBrims(new Set());
        setHiddenShapes(new Set());
        break;
    }
  };

  const sizeOptions = [
    { value: "50", label: "50%" },
    { value: "75", label: "75%" },
    { value: "85", label: "85%" },
    { value: "100", label: "100%" },
    { value: "125", label: "125%" },
    { value: "150", label: "150%" },
    { value: "175", label: "175%" },
    { value: "200", label: "200%" },
  ];

  if (!selectedLocations.length) {
    return (
      <Typography 
        variant="h6" 
        style={{ 
          textAlign: 'center', 
          marginTop: '2rem',
          color: '#666'
        }}
      >
        Please select a location to view the spreadsheet
      </Typography>
    );
  }

  return (
    <FullscreenWrapper isFullscreen={isFullscreen}>
      <SpreadsheetWrapper>
        <ControlsPanel>
          <ControlGroup>
            <Typography variant="subtitle2">Location:</Typography>
            <StyledSelect
              value={selectedLocations[0] || ''}
              onChange={handleLocationSelect}
            >
              <option value="">Select Location</option>
              {locations.map(location => (
                <option key={location.db_location_id} value={location.db_location_id}>
                  {location.name}
                </option>
              ))}
            </StyledSelect>
          </ControlGroup>

          <ControlGroup>
            <Typography variant="subtitle2">Type:</Typography>
            <StyledSelect
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value="">Select Type</option>
              {types.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </StyledSelect>
          </ControlGroup>

          <ControlGroup>
            <Typography variant="subtitle2">Table Size:</Typography>
            <StyledSelect
              value={tableSize}
              onChange={(e) => setTableSize(e.target.value)}
            >
              {sizeOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </StyledSelect>
          </ControlGroup>

          {Object.keys(editedCells).length > 0 && (
            <ControlGroup>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setConfirmDialogOpen(true)}
              >
                Save Changes ({Object.keys(editedCells).length})
              </Button>
            </ControlGroup>
          )}

          <ControlGroup>
            <Button
              variant="contained"
              size="small"
              onClick={() => setShowHideControls(!showHideControls)}
              startIcon={<VisibilityOffIcon />}
              color={showHideControls ? "primary" : "inherit"}
            >
              {showHideControls ? 'Hide Controls' : 'Show Hide Controls'}
            </Button>
          </ControlGroup>

          {showHideControls && (
            <>
              <ControlGroup>
                <StyledSelect
                  onChange={(e) => {
                    if (e.target.value) {
                      handleUnhideAll(e.target.value);
                      e.target.value = '';
                    }
                  }}
                  value=""
                >
                  <option value="">Unhide Elements...</option>
                  <option value="sizes">All Sizes</option>
                  <option value="brims">All Brims</option>
                  <option value="shapes">All Shapes</option>
                  <option value="all">Everything</option>
                </StyledSelect>
              </ControlGroup>

              <ControlGroup>
                <Typography variant="subtitle2" color="textSecondary">
                  Hidden: Sizes ({hiddenSizes.size}), Brims ({hiddenBrims.size}), Shapes ({hiddenShapes.size})
                </Typography>
              </ControlGroup>

              <ControlGroup>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setHideZeros(!hideZeros)}
                  color={hideZeros ? "primary" : "inherit"}
                >
                  {hideZeros ? 'Show Zeros' : 'Hide Zeros'}
                </Button>
              </ControlGroup>
            </>
          )}

          <ControlGroup>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setIsFullscreen(!isFullscreen)}
              startIcon={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            >
              {isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
            </Button>
          </ControlGroup>
        </ControlsPanel>

        {spreadsheetData ? (
          <div className="overflow-auto" style={{ 
            height: isFullscreen ? 'calc(100vh - 150px)' : '70vh',
            position: 'relative',
            width: `${parseInt(tableSize) > 100 ? '100%' : 'auto'}`,
            maxWidth: `${parseInt(tableSize) > 100 ? '100vw' : 'none'}`,
            overflow: 'auto',
            margin: '0 auto',
          }}>
            <SpreadsheetTable style={{ 
              transform: `scale(${parseInt(tableSize) / 100})`, 
              transformOrigin: 'top left',
              width: `${parseInt(tableSize) > 100 ? `${10000 / parseInt(tableSize)}%` : '100%'}`,
              margin: '0 auto',
            }}>
              <thead>
                <tr>
                  <th className="sticky-corner">Size</th>
                  {spreadsheetData.brims.map(brim => (
                    <th 
                      key={brim}
                      colSpan={spreadsheetData.shapes.length}
                      className="brim-header"
                    >
                      {brim}
                      <ToggleButton 
                        size="small"
                        onClick={() => toggleBrim(brim)}
                        visible={showHideControls}
                      >
                        <VisibilityOffIcon fontSize="small" />
                      </ToggleButton>
                    </th>
                  ))}
                  <th rowSpan="2" className="sticky-corner">Total</th>
                </tr>
                <tr>
                  <th></th>
                  {spreadsheetData.brims.map(brim => 
                    spreadsheetData.shapes.map(shape => (
                      <th key={`${brim}-${shape}`} className="shape-header">
                        {shape}
                        <ToggleButton 
                          size="small"
                          onClick={() => toggleShape(shape)}
                          visible={showHideControls}
                        >
                          <VisibilityOffIcon fontSize="small" />
                        </ToggleButton>
                      </th>
                    ))
                  )}
                </tr>
              </thead>
              <tbody>
                {spreadsheetData.sizes.map(size => (
                  <tr key={size}>
                    <th>
                      {size}
                      <ToggleButton 
                        size="small"
                        onClick={() => toggleSize(size)}
                        visible={showHideControls}
                      >
                        <VisibilityOffIcon fontSize="small" />
                      </ToggleButton>
                    </th>
                    {spreadsheetData.brims.map(brim =>
                      spreadsheetData.shapes.map((shape, shapeIndex) => {
                        const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                        const cellKey = item ? `${item.item_id}-${item.location_id}` : null;
                        const editedValue = cellKey && editedCells[cellKey] ? editedCells[cellKey].value : null;
                        
                        return (
                          <td 
                            key={`${size}-${brim}-${shape}`}
                            className={classNames({ 
                              empty: !item,
                              'brim-group-start': shapeIndex === 0,
                              'brim-group-end': shapeIndex === spreadsheetData.shapes.length - 1,
                              'edited': cellKey && editedCells[cellKey]
                            })}
                          >
                            {item ? (
                              <input
                                type="number"
                                value={editedValue !== null ? editedValue : (item.replenish_level || '')}
                                onChange={(e) => handleCellEdit(item, e.target.value)}
                                style={{
                                  width: '100%',
                                  border: 'none',
                                  background: 'transparent',
                                  textAlign: 'center'
                                }}
                              />
                            ) : '-'}
                          </td>
                        );
                      })
                    )}
                    <TotalCell>
                      {spreadsheetData.brims.reduce((total, brim) =>
                        total + spreadsheetData.shapes.reduce((brimTotal, shape) => {
                          const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                          const value = item ? parseFloat(getCellValue(item)) || 0 : 0;
                          return brimTotal + value;
                        }, 0)
                      , 0).toFixed(2)}
                    </TotalCell>
                  </tr>
                ))}
              </tbody>
            </SpreadsheetTable>
          </div>
        ) : (
          <Typography 
            variant="h6" 
            style={{ 
              textAlign: 'center', 
              marginTop: '2rem',
              color: '#666'
            }}
          >
            No data available for the selected location and type
          </Typography>
        )}

        <StyledDialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <DialogTitle>Confirm Changes</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to save these changes? This will set the replenish levels to manual mode.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
            <Button onClick={handleSaveChanges} color="primary" variant="contained">
              Save Changes
            </Button>
          </DialogActions>
        </StyledDialog>

        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <MuiAlert 
            elevation={6} 
            variant="filled" 
            severity={snackbarSeverity}
            onClose={() => setSnackbarOpen(false)}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </SpreadsheetWrapper>
    </FullscreenWrapper>
  );
};

function Optimize() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const [itemsByLocation, setItemsByLocation] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    name: { value: null, matchMode: 'contains' },
    type: { value: null, matchMode: 'contains' },
    size: { value: null, matchMode: 'contains' },
    shape: { value: null, matchMode: 'contains' },
    brim: { value: null, matchMode: 'contains' },
    location_name: { value: null, matchMode: 'contains' },
    inventory_level: { value: null, matchMode: 'contains' },
    replenish_level: { value: null, matchMode: 'contains' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newReplenishLevel, setNewReplenishLevel] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [filterType, setFilterType] = useState('all');
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem('optimizeActiveTab') || '0';
  });
  const [selectedLocation, setSelectedLocation] = useState(() => {
    return parseInt(localStorage.getItem('optimizeSelectedLocation')) || null;
  });

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  useEffect(() => {
    localStorage.setItem('optimizeActiveTab', activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === '3' && selectedLocations.length === 1) {
      localStorage.setItem('optimizeSelectedLocation', selectedLocations[0]);
    }
  }, [selectedLocations, activeTab]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [allItemsData, itemsByLocationData, locationsData] = await Promise.all([
        getAllItems(),
        getItemsByLocation(),
        getLocations(),
      ]);
      setAllItems(allItemsData);
      setItemsByLocation(itemsByLocationData);
      setLocations(locationsData);
      
      if (activeTab === '3') {
        const cachedLocation = parseInt(localStorage.getItem('optimizeSelectedLocation'));
        if (cachedLocation && locationsData.some(loc => loc.db_location_id === cachedLocation)) {
          setSelectedLocations([cachedLocation]);
        } else if (locationsData.length > 0) {
          setSelectedLocations([locationsData[0].db_location_id]);
        } else {
          setSelectedLocations([]);
        }
      } else {
        setSelectedLocations(locationsData.map(location => location.db_location_id));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const handleOptimize = async () => {
    setLoading(true);
    try {
      const data = await calculatePercentages();
      setResult(data);
      fetchData();
      setSnackbarMessage('Percentages calculated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error calculating percentages:', error);
      setSnackbarMessage('Failed to calculate percentages');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setLoading(false);
  };

  const handleLocationChange = (event) => {
    if (activeTab === '3') {
      const newLocations = event.value.map(id => parseInt(id));
      setSelectedLocations(newLocations);
    } else {
      setSelectedLocations(event.value);
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = (tableTitle) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0">{tableTitle}</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </span>
        <IconButton onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {activeTab === '0' ? (
            <MenuItem onClick={handleOptimize}>Calculate Percentages</MenuItem>
          ) : (
            <>
              <MenuItem onClick={handleOptimize}>Calculate Percentages</MenuItem>
              <MenuItem onClick={handleResetManualLevels}>Reset All to AI</MenuItem>
              <MenuItem onClick={() => handleFilterChange('all')}>Show All</MenuItem>
              <MenuItem onClick={() => handleFilterChange('automatic')}>Filter by Automatic</MenuItem>
              <MenuItem onClick={() => handleFilterChange('manual')}>Filter by Manual</MenuItem>
            </>
          )}
        </Menu>
      </div>
    );
  };

  const handleResetManualLevels = async () => {
    try {
      const confirmReset = window.confirm("Are you sure you want to reset all manual replenish levels to AI? This action cannot be undone.");
      if (confirmReset) {
        await resetManualReplenishLevels();
        setSnackbarMessage('All manual replenish levels have been reset to AI');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        fetchData(); // Refresh data after reset
      }
    } catch (error) {
      console.error('Error resetting manual levels:', error);
      setSnackbarMessage('Failed to reset manual replenish levels');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    handleMenuClose();
  };

  const handleFilterChange = (filterType) => {
    setFilterType(filterType);
    handleMenuClose();
  };

  const filteredItemsByLocation = React.useMemo(() => {
    return itemsByLocation.filter(item => {
      const locationMatch = selectedLocations.includes(item.location_id);
      const filterMatch = filterType === 'all' ||
        (filterType === 'automatic' && !item.manual_item_replenish) ||
        (filterType === 'manual' && item.manual_item_replenish);
      return locationMatch && filterMatch;
    });
  }, [itemsByLocation, selectedLocations, filterType]);

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setNewReplenishLevel(item.replenish_level);
    setEditDialogOpen(true);
  };

  const handleDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedItem(null);
  };

  const handleReplenishLevelChange = (event) => {
    setNewReplenishLevel(event.target.value);
  };

  const handleConfirmEdit = async () => {
    try {
      await updateReplenishLevel(selectedItem.item_id, selectedItem.location_id, newReplenishLevel);
      setSnackbarMessage('Replenish level updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData(); // Refresh data after update
      handleDialogClose();
    } catch (error) {
      console.error('Error updating replenish level:', error);
      setSnackbarMessage('Failed to update replenish level');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangeToAutomatic = async (itemId, locationId) => {
    try {
      await changeReplenishToAutomatic(itemId, locationId);
      setSnackbarMessage('Replenish level changed to automatic successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchData(); // Refresh data after update
    } catch (error) {
      console.error('Error changing replenish to automatic:', error);
      setSnackbarMessage('Failed to change replenish to automatic');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const SpreadsheetView = ({ items }) => {
    const [selectedType, setSelectedType] = useState(() => {
      return localStorage.getItem('spreadsheetType') || '';
    });
    const [hiddenSizes, setHiddenSizes] = useState(() => {
      const savedHidden = new Set(JSON.parse(localStorage.getItem('hiddenSizes') || '[]'));
      return new Set(savedHidden);
    });
    const [hiddenBrims, setHiddenBrims] = useState(() => {
      const savedHidden = new Set(JSON.parse(localStorage.getItem('hiddenBrims') || '[]'));
      return new Set([...savedHidden, '73', '79', '85', '91']);
    });
    const [hiddenShapes, setHiddenShapes] = useState(() => {
      return new Set(JSON.parse(localStorage.getItem('hiddenShapes') || '[]'));
    });
    const [showHideControls, setShowHideControls] = useState(false);
    const [tableSize, setTableSize] = useState(() => {
      return localStorage.getItem('spreadsheetTableSize') || '100';
    });
    const [selectedDataType, setSelectedDataType] = useState(() => {
      return localStorage.getItem('spreadsheetDataType') || 'inventory';
    });
    const [hideZeros, setHideZeros] = useState(() => {
      return localStorage.getItem('spreadsheetHideZeros') === 'true';
    });
    const [isFullscreen, setIsFullscreen] = useState(false);

    const types = useMemo(() => {
      const uniqueTypes = [...new Set(items.map(item => item.type))].sort();
      if (!selectedType && uniqueTypes.length > 0) {
        const savedType = localStorage.getItem('spreadsheetType');
        setSelectedType(savedType || uniqueTypes[0]);
      }
      return uniqueTypes;
    }, [items, selectedType]);

    useEffect(() => {
      if (selectedType) {
        localStorage.setItem('spreadsheetType', selectedType);
      }
    }, [selectedType]);

    useEffect(() => {
      localStorage.setItem('hiddenSizes', JSON.stringify([...hiddenSizes]));
      localStorage.setItem('hiddenBrims', JSON.stringify([...hiddenBrims]));
      localStorage.setItem('hiddenShapes', JSON.stringify([...hiddenShapes]));
    }, [hiddenSizes, hiddenBrims, hiddenShapes]);

    useEffect(() => {
      localStorage.setItem('spreadsheetTableSize', tableSize);
    }, [tableSize]);

    useEffect(() => {
      localStorage.setItem('spreadsheetDataType', selectedDataType);
    }, [selectedDataType]);

    useEffect(() => {
      localStorage.setItem('spreadsheetHideZeros', hideZeros);
    }, [hideZeros]);

    const dataTypeOptions = [
      { value: 'inventory', label: 'Inventory Levels' },
      { value: 'replenish', label: 'Replenish Levels' },
      { value: 'percentTotal', label: '% of Total' },
      { value: 'percentType', label: '% of Type' },
      { value: 'sales', label: 'Total Sales' },
    ];

    const spreadsheetData = useMemo(() => {
      if (!selectedType) return null;

      const typeItems = items.filter(item => item.type === selectedType);
      
      const allBrims = [...new Set(typeItems.map(item => item.brim))]
        .sort((a, b) => parseFloat(a) - parseFloat(b))
        .filter(brim => !hiddenBrims.has(brim));
        
      const shapeOrder = { 'XR': 0, 'R': 1, 'L': 2, 'X': 3 };
      const allShapes = [...new Set(typeItems.map(item => item.shape))]
        .sort((a, b) => (shapeOrder[a] || 99) - (shapeOrder[b] || 99))
        .filter(shape => !hiddenShapes.has(shape));

      const allSizes = [...new Set(typeItems.map(item => item.size))]
        .filter(size => !hiddenSizes.has(size))
        .sort((a, b) => {
          const numA = parseFloat(a);
          const numB = parseFloat(b);
          if (isNaN(numA) || isNaN(numB)) {
            return a.localeCompare(b);
          }
          return numA - numB;
        });

      const itemsMap = {};
      typeItems.forEach(item => {
        if (!hiddenSizes.has(item.size)) {
          const key = `${item.size}-${item.brim}-${item.shape}`;
          itemsMap[key] = item;
        }
      });

      return {
        sizes: allSizes,
        brims: allBrims,
        shapes: allShapes,
        itemsMap
      };
    }, [selectedType, items, hiddenSizes, hiddenBrims, hiddenShapes]);

    const toggleSize = (size) => {
      setHiddenSizes(prev => {
        const newSet = new Set(prev);
        if (newSet.has(size)) {
          newSet.delete(size);
        } else {
          newSet.add(size);
        }
        return newSet;
      });
    };

    const toggleBrim = (brim) => {
      setHiddenBrims(prev => {
        const newSet = new Set(prev);
        if (newSet.has(brim)) {
          newSet.delete(brim);
        } else {
          newSet.add(brim);
        }
        return newSet;
      });
    };

    const toggleShape = (shape) => {
      setHiddenShapes(prev => {
        const newSet = new Set(prev);
        if (newSet.has(shape)) {
          newSet.delete(shape);
        } else {
          newSet.add(shape);
        }
        return newSet;
      });
    };

    const handleUnhideAll = (type) => {
      switch (type) {
        case 'sizes':
          setHiddenSizes(new Set());
          break;
        case 'brims':
          setHiddenBrims(new Set());
          break;
        case 'shapes':
          setHiddenShapes(new Set());
          break;
        case 'all':
          setHiddenSizes(new Set());
          setHiddenBrims(new Set());
          setHiddenShapes(new Set());
          break;
      }
    };

    const sizeOptions = [
      { value: "50", label: "50%" },
      { value: "75", label: "75%" },
      { value: "85", label: "85%" },
      { value: "100", label: "100%" },
      { value: "125", label: "125%" },
      { value: "150", label: "150%" },
      { value: "175", label: "175%" },
      { value: "200", label: "200%" },
    ];

    const getCellValue = useCallback((item) => {
      if (!item) return '-';
      
      let value;
      switch (selectedDataType) {
        case 'inventory':
          value = item.total_inventory || 0;
          break;
        case 'replenish':
          value = item.total_replenish_level || 0;
          break;
        case 'percentTotal':
          value = item.percent_of_total || 0;
          break;
        case 'percentType':
          value = item.percent_of_type || 0;
          break;
        case 'sales':
          value = item.total_sales || 0;
          break;
        default:
          value = item.total_inventory || 0;
      }

      if (hideZeros && (value === 0 || value === '0' || value === '0%')) {
        return '';
      }

      return selectedDataType.includes('percent') ? `${value}%` : value;
    }, [selectedDataType, hideZeros]);

    if (!spreadsheetData) return null;

    return (
      <FullscreenWrapper isFullscreen={isFullscreen}>
        <SpreadsheetWrapper>
          <ControlsPanel>
            <ControlGroup>
              <Typography variant="subtitle2">Type:</Typography>
              <StyledSelect
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <option value="">Select Type</option>
                {types.map(type => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </StyledSelect>
            </ControlGroup>

            <ControlGroup>
              <Typography variant="subtitle2">Data Type:</Typography>
              <DataTypeSelect
                value={selectedDataType}
                onChange={(e) => setSelectedDataType(e.target.value)}
              >
                {dataTypeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </DataTypeSelect>
            </ControlGroup>

            <ControlGroup>
              <Typography variant="subtitle2">Table Size:</Typography>
              <StyledSelect
                value={tableSize}
                onChange={(e) => setTableSize(e.target.value)}
              >
                {sizeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </StyledSelect>
            </ControlGroup>

            <ControlGroup>
              <Button
                variant="contained"
                size="small"
                onClick={() => setShowHideControls(!showHideControls)}
                startIcon={<VisibilityOffIcon />}
                color={showHideControls ? "primary" : "inherit"}
              >
                {showHideControls ? 'Hide Controls' : 'Show Hide Controls'}
              </Button>
            </ControlGroup>

            {showHideControls && (
              <>
                <ControlGroup>
                  <StyledSelect
                    onChange={(e) => {
                      if (e.target.value) {
                        handleUnhideAll(e.target.value);
                        e.target.value = '';
                      }
                    }}
                    value=""
                  >
                    <option value="">Unhide Elements...</option>
                    <option value="sizes">All Sizes</option>
                    <option value="brims">All Brims</option>
                    <option value="shapes">All Shapes</option>
                    <option value="all">Everything</option>
                  </StyledSelect>
                </ControlGroup>

                <ControlGroup>
                  <Typography variant="subtitle2" color="textSecondary">
                    Hidden: Sizes ({hiddenSizes.size}), Brims ({hiddenBrims.size}), Shapes ({hiddenShapes.size})
                  </Typography>
                </ControlGroup>

                <ControlGroup>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setHideZeros(!hideZeros)}
                    color={hideZeros ? "primary" : "inherit"}
                  >
                    {hideZeros ? 'Show Zeros' : 'Hide Zeros'}
                  </Button>
                </ControlGroup>
              </>
            )}

            <ControlGroup>
              <Button
                variant="outlined"
                size="small"
                onClick={() => setIsFullscreen(!isFullscreen)}
                startIcon={isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
              >
                {isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}
              </Button>
            </ControlGroup>
          </ControlsPanel>

          <div className="overflow-auto" style={{ 
            height: isFullscreen ? 'calc(100vh - 150px)' : '70vh',
            position: 'relative',
            width: `${parseInt(tableSize) > 100 ? '100%' : 'auto'}`,
            maxWidth: `${parseInt(tableSize) > 100 ? '100vw' : 'none'}`,
            overflow: 'auto',
            margin: '0 auto',
          }}>
            <SpreadsheetTable style={{ 
              transform: `scale(${parseInt(tableSize) / 100})`, 
              transformOrigin: 'top left',
              width: `${parseInt(tableSize) > 100 ? `${10000 / parseInt(tableSize)}%` : '100%'}`,
              margin: '0 auto',
            }}>
              <thead>
                <tr>
                  <th className="sticky-corner">Size</th>
                  {spreadsheetData.brims.map(brim => (
                    <th 
                      key={brim}
                      colSpan={spreadsheetData.shapes.length}
                      className="brim-header"
                    >
                      {brim}
                      <ToggleButton 
                        size="small"
                        onClick={() => toggleBrim(brim)}
                        visible={showHideControls}
                      >
                        <VisibilityOffIcon fontSize="small" />
                      </ToggleButton>
                    </th>
                  ))}
                  <th rowSpan="2" className="sticky-corner">Total</th>
                </tr>
                <tr>
                  <th></th>
                  {spreadsheetData.brims.map(brim => 
                    spreadsheetData.shapes.map(shape => (
                      <th key={`${brim}-${shape}`} className="shape-header">
                        {shape}
                        <ToggleButton 
                          size="small"
                          onClick={() => toggleShape(shape)}
                          visible={showHideControls}
                        >
                          <VisibilityOffIcon fontSize="small" />
                        </ToggleButton>
                      </th>
                    ))
                  )}
                </tr>
              </thead>
              <tbody>
                {spreadsheetData.sizes.map(size => (
                  <tr key={size}>
                    <th>
                      {size}
                      <ToggleButton 
                        size="small"
                        onClick={() => toggleSize(size)}
                        visible={showHideControls}
                      >
                        <VisibilityOffIcon fontSize="small" />
                      </ToggleButton>
                    </th>
                    {spreadsheetData.brims.map(brim =>
                      spreadsheetData.shapes.map((shape, shapeIndex) => {
                        const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                        return (
                          <td 
                            key={`${size}-${brim}-${shape}`}
                            className={classNames({ 
                              empty: !item,
                              'brim-group-start': shapeIndex === 0,
                              'brim-group-end': shapeIndex === spreadsheetData.shapes.length - 1
                            })}
                          >
                            {getCellValue(item)}
                          </td>
                        );
                      })
                    )}
                    <TotalCell>
                      {spreadsheetData.brims.reduce((total, brim) =>
                        total + spreadsheetData.shapes.reduce((brimTotal, shape) => {
                          const item = spreadsheetData.itemsMap[`${size}-${brim}-${shape}`];
                          const value = item ? parseFloat(getCellValue(item)) || 0 : 0;
                          return brimTotal + value;
                        }, 0)
                      , 0).toFixed(2)}
                    </TotalCell>
                  </tr>
                ))}
              </tbody>
            </SpreadsheetTable>
          </div>
        </SpreadsheetWrapper>
      </FullscreenWrapper>
    );
  };

  const handleTabChange = (e) => {
    const newTabIndex = e.index.toString();
    setActiveTab(newTabIndex);
    
    if (newTabIndex === '3') {
      if (selectedLocations.length !== 1) {
        if (locations.length > 0) {
          setSelectedLocations([locations[0].db_location_id]);
        } else {
          setSelectedLocations([]);
        }
      }
    } else if (selectedLocations.length <= 1) {
      setSelectedLocations(locations.map(location => location.db_location_id));
    }
  };

  return (
    <StyledContainer>
      <TabView 
        style={{ 
          width: '100%', 
          marginTop: '2rem',
          maxWidth: '100vw',
          overflow: 'hidden'
        }} 
        activeIndex={parseInt(activeTab)} 
        onTabChange={handleTabChange}
      >
        <TabPanel header="All Items">
          <StyledTableContainer>
            <DataTable
              value={allItems}
              paginator
              rows={10}
              dataKey="id"
              filters={filters}
              filterDisplay="menu"
              loading={loading}
              globalFilterFields={['name', 'type']}
              header={renderHeader('All Items')}
              emptyMessage="No items found."
              responsiveLayout="scroll"
            >
              <Column field="name" header="Name" filter filterPlaceholder="Search by name" sortable />
              <Column field="type" header="Type" filter filterPlaceholder="Search by type" sortable />
              <Column field="size" header="Size" sortable />
              <Column field="brim" header="Brim" sortable />
              <Column field="shape" header="Shape" sortable />
              <Column field="total_inventory" header="Total Inventory" sortable />
              <Column field="total_replenish_level" header="Total Replenish Level" sortable />
              <Column field="percent_of_total" header="% of Total" sortable body={(rowData) => `${rowData.percent_of_total}%`} />
              <Column field="percent_of_type" header="% of Type" sortable body={(rowData) => `${rowData.percent_of_type}%`} />
              <Column field="total_sales" header="Total Sales" sortable />
            </DataTable>
          </StyledTableContainer>
        </TabPanel>
        <TabPanel header="Items by Location">
          <FormControl variant="outlined" style={{ margin: '1rem', minWidth: 200 }}>
            <MultiSelect
              value={selectedLocations}
              options={locations}
              onChange={handleLocationChange}
              optionLabel="name"
              optionValue="db_location_id"
              placeholder="Locations"
              className="w-full md:w-20rem"
            />
          </FormControl>
          <StyledTableContainer>
            <DataTable
              value={filteredItemsByLocation}
              paginator
              rows={10}
              dataKey="id"
              filters={filters}
              filterDisplay="menu"
              loading={loading}
              globalFilterFields={['item_name', 'item_type', 'location_name', 'inventory_level', 'replenish_level', 'size', 'shape', 'brim']}
              header={renderHeader('Items by Location')}
              emptyMessage="No items found."
              responsiveLayout="scroll"
            >
              <Column field="item_name" header="Name" filter filterPlaceholder="Search by name" sortable />
              <Column field="item_type" header="Type" filter filterPlaceholder="Search by type" sortable />
              <Column field="size" header="Size" filter filterPlaceholder="Search by size" sortable />
              <Column field="shape" header="Shape" filter filterPlaceholder="Search by shape" sortable />
              <Column field="brim" header="Brim" filter filterPlaceholder="Search by brim" sortable />
              <Column field="location_name" header="Location" filter filterPlaceholder="Search by location" sortable />
              <Column field="inventory_level" header="Inventory Level" filter filterPlaceholder="Search by inventory" sortable />
              <Column field="replenish_level" header="Replenish Level" filter filterPlaceholder="Search by replenish" sortable />
              <Column
                header="Actions"
                body={(rowData) => (
                  <>
                    <IconButton color="primary" onClick={() => handleEditClick(rowData)}>
                      <EditIcon />
                    </IconButton>
                    {rowData.manual_item_replenish && (
                      <IconButton color="secondary" onClick={() => handleChangeToAutomatic(rowData.item_id, rowData.location_id)}>
                        <AutorenewIcon />
                      </IconButton>
                    )}
                  </>
                )}
              />
            </DataTable>
          </StyledTableContainer>
        </TabPanel>
        <TabPanel header="Items Table">
          <SpreadsheetView items={allItems} />
        </TabPanel>
        <TabPanel header="Locations Table">
          <LocationSpreadsheetView 
            items={itemsByLocation}
            locations={locations}
            selectedLocations={selectedLocations}
            onLocationChange={handleLocationChange}
          />
        </TabPanel>
        <TabPanel header="Replenish Levels">
          <ReplenishLevelSpreadsheetView
            items={itemsByLocation}
            locations={locations}
            selectedLocations={selectedLocations}
            onLocationChange={handleLocationChange}
            setItemsByLocation={setItemsByLocation}
          />
        </TabPanel>
      </TabView>

      <StyledDialog 
        open={editDialogOpen} 
        onClose={handleDialogClose}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle>Edit Replenish Level</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the replenish level for {selectedItem?.item_name}.
            <br />
            This level will remain manual until changed to AI-automatic.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Replenish Level"
            type="number"
            fullWidth
            value={newReplenishLevel}
            onChange={handleReplenishLevelChange}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmEdit} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </StyledDialog>

      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert 
          elevation={6} 
          variant="filled" 
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </StyledContainer>
  );
}

export default Optimize;
