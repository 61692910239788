import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  IconButton,
  Box,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  LinearProgress,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Chart } from 'primereact/chart';
import {
  getWarehouseById,
  getWarehouseItems,
  updateWarehouse,
  updateWarehouseItem,
  batchUpdateWarehouseItems,
  getProductionEntriesByWarehouse,
  confirmProductionEntry,
  cancelProductionEntry,
  getWarehouseInventoryChecks,
  updateInventoryCheckStatus,
} from '../services/api';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import LoadingOverlay from './LoadingOverlay';
import { format } from 'date-fns';
import { Add as AddIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import WarehouseSpreadsheet from './WarehouseSpreadsheet';
import WarehouseSpreadsheetView from './WarehouseSpreadsheetView';

const BatchUpdateProgress = ({ current, total, status }) => (
  <Box sx={{ 
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    minWidth: 300,
    textAlign: 'center'
  }}>
    <Typography variant="h6" gutterBottom>
      Updating Items
    </Typography>
    <Typography>
      Progress: {current} / {total} items
    </Typography>
    <LinearProgress 
      variant="determinate" 
      value={(current / total) * 100} 
      sx={{ my: 2 }}
    />
    <Typography variant="body2" color="text.secondary">
      {status}
    </Typography>
  </Box>
);

const WarehouseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState(null);
  const [items, setItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [editedValues, setEditedValues] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    item_name: { value: null, matchMode: 'contains' },
    item_type: { value: null, matchMode: 'contains' },
    quantity: { value: null, matchMode: 'equals' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [batchEditMode, setBatchEditMode] = useState(false);
  const [batchEditValues, setBatchEditValues] = useState({
    quantity: '',
    minimum_quantity: '',
    maximum_quantity: '',
    reorder_level: ''
  });
  const [visibleColumns, setVisibleColumns] = useState(() => ({
    item_name: true,
    item_type: true,
    size: true,
    shape: true,
    brim: true,
    quantity: true,
    minimum_quantity: true,
    maximum_quantity: true,
    reorder_level: true
  }));
  const [columnMenuAnchor, setColumnMenuAnchor] = useState(null);
  const [updateProgress, setUpdateProgress] = useState({ show: false, current: 0, total: 0, status: '' });
  const [productionEntries, setProductionEntries] = useState([]);
  const [activeTab, setActiveTab] = useState('items');
  const [inventoryChecks, setInventoryChecks] = useState([]);
  const [spreadsheetOpen, setSpreadsheetOpen] = useState(false);

  useEffect(() => {
    fetchWarehouseDetails();
    fetchWarehouseItems();
    fetchProductionEntries();
    fetchInventoryChecks();
  }, [id]);

  const fetchWarehouseDetails = async () => {
    try {
      const data = await getWarehouseById(id);
      setWarehouse(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch warehouse details',
        severity: 'error',
      });
    }
  };

  const fetchWarehouseItems = async () => {
    try {
      const data = await getWarehouseItems(id);
      setItems(data);
      setLoading(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch warehouse items',
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const fetchProductionEntries = async () => {
    try {
      const data = await getProductionEntriesByWarehouse(id);
      setProductionEntries(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch production entries',
        severity: 'error',
      });
    }
  };

  const fetchInventoryChecks = async () => {
    try {
      const data = await getWarehouseInventoryChecks(id);
      setInventoryChecks(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch inventory checks',
        severity: 'error',
      });
    }
  };

  const handleEditItem = (item) => {
    setEditingItem(item.warehouse_item_id);
    setEditedValues({
      quantity: item.quantity,
      minimum_quantity: item.minimum_quantity,
      maximum_quantity: item.maximum_quantity,
      reorder_level: item.reorder_level,
    });
  };

  const handleSaveItem = async () => {
    try {
      await updateWarehouseItem(id, editingItem, editedValues);
      setEditingItem(null);
      fetchWarehouseItems();
      setSnackbar({
        open: true,
        message: 'Item updated successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update item',
        severity: 'error',
      });
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const handleBatchUpdate = async () => {
    const BATCH_SIZE = 100;
    const totalItems = selectedItems.length;
    
    // Validate that we have items selected and at least one field to update
    if (selectedItems.length === 0) {
      setSnackbar({
        open: true,
        message: 'Please select items to update',
        severity: 'warning'
      });
      return;
    }

    // Check if at least one field has a value
    const hasValues = Object.values(batchEditValues).some(value => value !== '');
    if (!hasValues) {
      setSnackbar({
        open: true,
        message: 'Please enter at least one value to update',
        severity: 'warning'
      });
      return;
    }

    setUpdateProgress({
      show: true,
      current: 0,
      total: totalItems,
      status: 'Starting updates...'
    });

    try {
      // Create update items array with proper structure
      const updateItems = selectedItems.map(itemId => {
        // Ensure itemId is a number
        const numericItemId = typeof itemId === 'object' ? itemId.warehouse_item_id : itemId;
        
        return {
          warehouse_item_id: parseInt(numericItemId),
          quantity: batchEditValues.quantity !== '' ? parseInt(batchEditValues.quantity) : null,
          minimum_quantity: batchEditValues.minimum_quantity !== '' ? parseInt(batchEditValues.minimum_quantity) : null,
          maximum_quantity: batchEditValues.maximum_quantity !== '' ? parseInt(batchEditValues.maximum_quantity) : null,
          reorder_level: batchEditValues.reorder_level !== '' ? parseInt(batchEditValues.reorder_level) : null
        };
      });

      console.log('Sending batch update with items:', updateItems);

      // Process items in batches
      for (let i = 0; i < updateItems.length; i += BATCH_SIZE) {
        const batch = updateItems.slice(i, i + BATCH_SIZE);
        await batchUpdateWarehouseItems(id, batch);
        
        setUpdateProgress(prev => ({
          ...prev,
          current: Math.min(i + BATCH_SIZE, totalItems),
          status: `Processing items ${Math.min(i + BATCH_SIZE, totalItems)} of ${totalItems}...`
        }));
      }

      setSnackbar({
        open: true,
        message: `Successfully updated ${totalItems} items`,
        severity: 'success'
      });
      
      // Reset states
      setBatchEditMode(false);
      setBatchEditValues({
        quantity: '',
        minimum_quantity: '',
        maximum_quantity: '',
        reorder_level: ''
      });
      setSelectedItems([]);
      
      // Refresh data
      await fetchWarehouseItems();
    } catch (error) {
      console.error('Batch update error:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to update items',
        severity: 'error'
      });
    } finally {
      setUpdateProgress({ show: false, current: 0, total: 0, status: '' });
    }
  };

  const handleColumnVisibilityChange = (columnName) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnName]: !prev[columnName]
    }));
  };

  const columns = [
    { field: 'item_name', header: 'Item Name', sortable: true },
    { field: 'item_type', header: 'Type', sortable: true },
    { field: 'size', header: 'Size', sortable: true },
    { field: 'shape', header: 'Shape', sortable: true },
    { field: 'brim', header: 'Brim', sortable: true },
    { field: 'quantity', header: 'Quantity', sortable: true, editable: true },
    { field: 'minimum_quantity', header: 'Min Quantity', sortable: true, editable: true },
    { field: 'maximum_quantity', header: 'Max Quantity', sortable: true, editable: true },
    { field: 'reorder_level', header: 'Reorder Level', sortable: true, editable: true }
  ];

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between align-items-center">
        <div className="flex align-items-center">
          <h5 className="m-0">Warehouse Items</h5>
          <IconButton
            onClick={(e) => setColumnMenuAnchor(e.currentTarget)}
            style={{ marginLeft: '1rem' }}
          >
            <ViewColumnIcon />
          </IconButton>
        </div>
        <div className="flex align-items-center">
          {selectedItems.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setBatchEditMode(true)}
              style={{ marginRight: '1rem' }}
            >
              Edit Selected ({selectedItems.length})
            </Button>
          )}
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  const getCapacityChartData = () => {
    if (!warehouse || !items) return null;

    const itemsByType = items.reduce((acc, item) => {
      if (!acc[item.item_type]) {
        acc[item.item_type] = 0;
      }
      acc[item.item_type] += item.quantity;
      return acc;
    }, {});

    const colors = [
      '#FF6384',
      '#36A2EB',
      '#FFCE56',
      '#4BC0C0',
      '#9966FF',
      '#FF9F40',
      '#47B39C',
      '#EC6B56'
    ];

    const data = {
      labels: Object.keys(itemsByType),
      datasets: [
        {
          data: Object.values(itemsByType),
          backgroundColor: colors.slice(0, Object.keys(itemsByType).length),
        }
      ]
    };

    return data;
  };

  const capacityChartOptions = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
        }
      },
      title: {
        display: true,
        text: 'Warehouse Capacity Usage by Item Type',
        font: {
          size: 16
        }
      }
    },
    maintainAspectRatio: false
  };

  const editItemTemplate = (rowData) => {
    if (editingItem === rowData.warehouse_item_id) {
      return (
        <div className="flex gap-2">
          <IconButton onClick={handleSaveItem} color="primary" size="small">
            <SaveIcon />
          </IconButton>
          <IconButton onClick={() => setEditingItem(null)} color="secondary" size="small">
            <CancelIcon />
          </IconButton>
        </div>
      );
    }
    return (
      <IconButton onClick={() => handleEditItem(rowData)} color="primary" size="small">
        <EditIcon />
      </IconButton>
    );
  };

  const quantityTemplate = (rowData) => {
    if (editingItem === rowData.warehouse_item_id) {
      return (
        <TextField
          type="number"
          value={editedValues.quantity}
          onChange={(e) => setEditedValues({
            ...editedValues,
            quantity: parseInt(e.target.value)
          })}
          size="small"
        />
      );
    }
    return rowData.quantity;
  };

  const capacityChartData = useMemo(() => getCapacityChartData(), [items]);

  const handleCreateProduction = () => {
    navigate(`/warehouses/${id}/production/create`);
  };

  const handleConfirmProduction = async (productionId) => {
    try {
      await confirmProductionEntry(productionId);
      fetchProductionEntries();
      setSnackbar({
        open: true,
        message: 'Production entry confirmed successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to confirm production entry',
        severity: 'error',
      });
    }
  };

  const handleCancelProduction = async (productionId) => {
    try {
      await cancelProductionEntry(productionId);
      fetchProductionEntries();
      setSnackbar({
        open: true,
        message: 'Production entry cancelled successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to cancel production entry',
        severity: 'error',
      });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleStatusUpdate = async (checkId, newStatus) => {
    try {
      await updateInventoryCheckStatus(checkId, newStatus);
      fetchInventoryChecks();
      setSnackbar({
        open: true,
        message: 'Inventory check status updated successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update inventory check status',
        severity: 'error',
      });
    }
  };

  if (!warehouse) return <Typography>Loading...</Typography>;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">{warehouse.name}</Typography>
        <Button variant="outlined" onClick={() => navigate('/warehouses')}>
          Back to Warehouses
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Warehouse Details</Typography>
              <Typography>Code: {warehouse.code}</Typography>
              <Typography>Total Capacity: {warehouse.total_capacity}</Typography>
              <Typography>Available Capacity: {warehouse.available_capacity}</Typography>
              <Typography>Status: {warehouse.status}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent sx={{ height: '300px' }}>
              {capacityChartData && (
                <Chart
                  type="doughnut"
                  data={capacityChartData}
                  options={capacityChartOptions}
                  style={{ height: '100%' }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs 
                    value={activeTab} 
                    onChange={handleTabChange}
                    variant="fullWidth"
                    aria-label="warehouse management tabs"
                  >
                    <Tab label="Warehouse Items" value="items" />
                    <Tab label="Production Entries" value="production" />
                    <Tab label="Inventory Checks" value="inventory-checks" />
                  </Tabs>
                </Box>

                {/* Warehouse Items Tab */}
                {activeTab === 'items' && (
                  <Box sx={{ p: 2 }}>
                    <Card>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                          <Typography variant="h6">Warehouse Items</Typography>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<ViewColumnIcon />}
                              onClick={() => setSpreadsheetOpen(true)}
                            >
                              Spreadsheet View
                            </Button>
                            {selectedItems.length > 0 && (
                              <Button
                                variant="contained"
                                onClick={() => setBatchEditMode(true)}
                              >
                                Batch Edit ({selectedItems.length})
                              </Button>
                            )}
                            <IconButton
                              onClick={(e) => setColumnMenuAnchor(e.currentTarget)}
                            >
                              <ViewColumnIcon />
                            </IconButton>
                          </Box>
                        </Box>

                        <DataTable
                          value={items}
                          paginator
                          rows={10}
                          dataKey="warehouse_item_id"
                          filters={filters}
                          filterDisplay="menu"
                          loading={loading}
                          globalFilterFields={['item_name', 'item_type', 'size', 'shape', 'brim']}
                          header={renderHeader()}
                          emptyMessage="No items found."
                          responsiveLayout="scroll"
                          scrollHeight="600px"
                          selection={selectedItems}
                          onSelectionChange={(e) => setSelectedItems(e.value)}
                          selectionMode="multiple"
                        >
                          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
                          {columns.map(col => (
                            visibleColumns[col.field] && (
                              <Column
                                key={col.field}
                                field={col.field}
                                header={col.header}
                                sortable={col.sortable}
                                filter
                                filterPlaceholder={`Search by ${col.header.toLowerCase()}`}
                                body={col.editable ? (rowData) => (
                                  editingItem === rowData.warehouse_item_id ? (
                                    <TextField
                                      type="number"
                                      value={editedValues[col.field] || ''}
                                      onChange={(e) => setEditedValues({
                                        ...editedValues,
                                        [col.field]: parseInt(e.target.value)
                                      })}
                                      size="small"
                                    />
                                  ) : (
                                    rowData[col.field]
                                  )
                                ) : undefined}
                              />
                            )
                          ))}
                          <Column
                            header="Actions"
                            body={(rowData) => editItemTemplate(rowData)}
                            style={{ width: '100px' }}
                          />
                        </DataTable>
                      </CardContent>
                    </Card>
                  </Box>
                )}

                {/* Production Tab */}
                {activeTab === 'production' && (
                  <Box sx={{ p: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCreateProduction}
                        startIcon={<AddIcon />}
                      >
                        Create Production Entry
                      </Button>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Items Count</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productionEntries.map((entry) => (
                            <TableRow key={entry.production_id}>
                              <TableCell>{entry.production_id}</TableCell>
                              <TableCell>
                                {format(new Date(entry.created_at), 'MMM d, yyyy HH:mm')}
                              </TableCell>
                              <TableCell>
                                <Chip 
                                  label={entry.status}
                                  color={
                                    entry.status === 'pending' ? 'warning' :
                                    entry.status === 'confirmed' ? 'success' :
                                    entry.status === 'cancelled' ? 'error' :
                                    'default'
                                  }
                                />
                              </TableCell>
                              <TableCell>{entry.items?.length || 0}</TableCell>
                              <TableCell>{entry.notes}</TableCell>
                              <TableCell>
                                <IconButton 
                                  onClick={() => navigate(`/warehouses/${id}/production/${entry.production_id}/edit`)}
                                  disabled={entry.status !== 'pending'}
                                >
                                  <EditIcon />
                                </IconButton>
                                {entry.status === 'pending' && (
                                  <>
                                    <IconButton 
                                      color="success"
                                      onClick={() => handleConfirmProduction(entry.production_id)}
                                    >
                                      <CheckCircleIcon />
                                    </IconButton>
                                    <IconButton 
                                      color="error"
                                      onClick={() => handleCancelProduction(entry.production_id)}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}

                {/* Inventory Checks Tab */}
                {activeTab === 'inventory-checks' && (
                  <Box sx={{ p: 2 }}>
                    <Box sx={{ mb: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(`/warehouses/${id}/inventory-checks/create`)}
                        startIcon={<AddIcon />}
                      >
                        Create Inventory Check
                      </Button>
                    </Box>

                    <TableContainer component={Paper}>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Items Count</TableCell>
                            <TableCell>Reset Uncounted</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inventoryChecks.map((check) => (
                            <TableRow key={check.check_id}>
                              <TableCell>{check.check_id}</TableCell>
                              <TableCell>
                                {format(new Date(check.created_at), 'MMM d, yyyy HH:mm')}
                              </TableCell>
                              <TableCell>
                                <Chip 
                                  label={check.status}
                                  color={
                                    check.status === 'draft' ? 'default' :
                                    check.status === 'pending' ? 'warning' :
                                    check.status === 'confirmed' ? 'success' :
                                    check.status === 'cancelled' ? 'error' :
                                    'default'
                                  }
                                />
                              </TableCell>
                              <TableCell>{check.items?.length || 0}</TableCell>
                              <TableCell>
                                <Chip 
                                  label={check.reset_uncounted_items ? 'Yes' : 'No'}
                                  color={check.reset_uncounted_items ? 'warning' : 'default'}
                                />
                              </TableCell>
                              <TableCell>{check.notes}</TableCell>
                              <TableCell>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  {check.status === 'draft' && (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      onClick={() => navigate(`/warehouses/${id}/inventory-checks/${check.check_id}/edit`)}
                                    >
                                      Edit
                                    </Button>
                                  )}
                                  {check.status === 'pending' && (
                                    <>
                                      <Button
                                        size="small"
                                        variant="contained"
                                        color="success"
                                        onClick={() => handleStatusUpdate(check.check_id, 'confirmed')}
                                      >
                                        Confirm
                                      </Button>
                                      <Button
                                        size="small"
                                        variant="contained"
                                        color="error"
                                        onClick={() => handleStatusUpdate(check.check_id, 'cancelled')}
                                      >
                                        Cancel
                                      </Button>
                                    </>
                                  )}
                                  {check.status === 'draft' && (
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      onClick={() => handleStatusUpdate(check.check_id, 'pending')}
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Column Visibility Menu */}
      <Menu
        anchorEl={columnMenuAnchor}
        open={Boolean(columnMenuAnchor)}
        onClose={() => setColumnMenuAnchor(null)}
      >
        {columns.map(col => (
          <MenuItem key={col.field}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={visibleColumns[col.field]}
                  onChange={() => handleColumnVisibilityChange(col.field)}
                />
              }
              label={col.header}
            />
          </MenuItem>
        ))}
      </Menu>

      {/* Batch Edit Dialog */}
      <Dialog
        open={batchEditMode}
        onClose={() => setBatchEditMode(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Batch Edit Items</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit values for {selectedItems.length} selected items. Leave fields empty to keep current values.
          </DialogContentText>
          {Object.keys(batchEditValues).map(field => (
            <TextField
              key={field}
              label={columns.find(col => col.field === field)?.header}
              type="number"
              fullWidth
              value={batchEditValues[field]}
              onChange={(e) => setBatchEditValues({
                ...batchEditValues,
                [field]: e.target.value
              })}
              margin="normal"
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBatchEditMode(false)}>Cancel</Button>
          <Button onClick={handleBatchUpdate} color="primary" variant="contained">
            Update Items
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {updateProgress.show && (
        <BatchUpdateProgress
          current={updateProgress.current}
          total={updateProgress.total}
          status={updateProgress.status}
        />
      )}

      <Dialog
        fullScreen
        open={spreadsheetOpen}
        onClose={() => setSpreadsheetOpen(false)}
      >
        <WarehouseSpreadsheetView 
          warehouseId={id}
          onClose={() => setSpreadsheetOpen(false)}
        />
      </Dialog>
    </Container>
  );
};

export default WarehouseDetails; 