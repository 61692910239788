import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCustomerProfile, customerLogout, checkCustomerAuth } from '../services/customerapi';
import {
  Box,
  Container,
  Typography,
  Paper,
  Button,
  Grid,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from '@mui/icons-material/Logout';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getCustomerOrders } from '../services/customerapi';
import AddIcon from '@mui/icons-material/Add';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(4, 0),
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#ffffff',
  padding: theme.spacing(4, 0),
  marginBottom: theme.spacing(4),
}));

const InfoCard = styled(Card)(({ theme }) => ({
  height: '100%',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

function CustomerDashboard() {
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (!checkCustomerAuth()) {
      navigate('/customer/login');
      return;
    }
    
    fetchProfile();
    fetchOrders();
  }, [navigate]);

  const fetchProfile = async () => {
    try {
      const response = await getCustomerProfile();
      setProfile(response);
    } catch (err) {
      setError('Failed to load profile data');
      console.error('Error fetching profile:', err);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await getCustomerOrders();
      setOrders(response);
    } catch (err) {
      setError('Failed to load orders');
      console.error('Error fetching orders:', err);
    }
  };

  const handleLogout = () => {
    customerLogout();
    navigate('/customer/login');
  };

  const renderOrdersTab = () => (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">My Orders</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/customer/orders/create')}
        >
          Create New Order
        </Button>
      </Box>
      
      <DataTable
        value={orders}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        emptyMessage="No orders found"
      >
        <Column field="order_number" header="Order Number" sortable />
        <Column field="order_date" header="Order Date" sortable 
          body={(rowData) => new Date(rowData.order_date).toLocaleDateString()} />
        <Column field="request_status" header="Status" sortable 
          body={(rowData) => (
            <Typography
              sx={{
                color: rowData.request_status === 'awaiting_approval' ? 'warning.main' :
                       rowData.request_status === 'rejected' ? 'error.main' :
                       rowData.request_status === 'processing' ? 'info.main' :
                       rowData.request_status === 'shipped' ? 'success.main' :
                       'text.primary'
              }}
            >
              {rowData.request_status.replace('_', ' ').toUpperCase()}
            </Typography>
          )}
        />
        <Column field="eta_type" header="ETA Type" sortable
          body={(rowData) => rowData.eta_type === 'asap' ? 'ASAP' : 'Specific Date'} />
        <Column field="requested_eta_date" header="Requested ETA" sortable
          body={(rowData) => rowData.requested_eta_date ? 
            new Date(rowData.requested_eta_date).toLocaleDateString() : 'N/A'} />
        <Column 
          body={(rowData) => (
            <Button
              variant="outlined"
              size="small"
              onClick={() => navigate(`/customer/orders/${rowData.order_id}`)}
            >
              View Details
            </Button>
          )}
        />
      </DataTable>
    </Box>
  );

  if (!profile) {
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Box>
      <Header>
        <Container>
          <Typography variant="h4" gutterBottom>
            Welcome to Pim By Eli Customer Portal
          </Typography>
          <Typography variant="subtitle1">
            Manage your account and view your business information
          </Typography>
        </Container>
      </Header>

      <Container>
        <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
          <TabPanel header="Overview">
            <StyledPaper>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Typography variant="h5">Account Overview</Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<LogoutIcon />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Box>

              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <InfoCard>
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        <BusinessIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6">Company</Typography>
                      </Box>
                      <Typography variant="body1">{profile.company_name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        {profile.business_type}
                      </Typography>
                    </CardContent>
                  </InfoCard>
                </Grid>

                <Grid item xs={12} md={4}>
                  <InfoCard>
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        <PersonIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6">Primary Contact</Typography>
                      </Box>
                      <Typography variant="body1">
                        {profile.primary_contact_name}
                      </Typography>
                    </CardContent>
                  </InfoCard>
                </Grid>

                <Grid item xs={12} md={4}>
                  <InfoCard>
                    <CardContent>
                      <Box display="flex" alignItems="center" mb={2}>
                        <EmailIcon color="primary" sx={{ mr: 1 }} />
                        <Typography variant="h6">Contact Email</Typography>
                      </Box>
                      <Typography variant="body1">
                        {profile.primary_contact_email}
                      </Typography>
                    </CardContent>
                  </InfoCard>
                </Grid>
              </Grid>

              <Divider sx={{ my: 4 }} />

              <Typography variant="h6" gutterBottom>
                Quick Actions
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/customer/orders')}
                  >
                    View Orders
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/customer/profile')}
                  >
                    Edit Profile
                  </Button>
                </Grid>
              </Grid>
            </StyledPaper>
          </TabPanel>
          
          <TabPanel header="Orders">
            <StyledPaper>
              {renderOrdersTab()}
            </StyledPaper>
          </TabPanel>
        </TabView>
      </Container>
    </Box>
  );
}

export default CustomerDashboard;