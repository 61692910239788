import React from 'react';
import { Box } from '@mui/material';
import '../styles/loading.css';

const LoadingAnimation = () => {
  return (
    <Box 
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
      }}
    >
      <div id="load">
        <div>C</div>
        <div>R</div>
        <div>E</div>
        <div>A</div>
        <div>T</div>
        <div>I</div>
        <div>N</div>
        <div>G</div>
      </div>
    </Box>
  );
};

export default LoadingAnimation; 