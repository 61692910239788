import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { checkCustomerAuth } from '../services/customerapi';

function CustomerProtectedRoute({ children }) {
  const location = useLocation();
  const isAuthenticated = checkCustomerAuth();
  
  if (!isAuthenticated) {
    return <Navigate to="/customer/login" state={{ from: location }} replace />;
  }

  return children;
}

export default CustomerProtectedRoute;