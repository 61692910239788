import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Chip,
  List,
  ListItem,
  ListItemText,
  Avatar,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  LocalShipping as ShippingIcon,
  Assignment as AssignmentIcon,
  Comment as CommentIcon,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Upload as UploadIcon,
  Attachment as AttachmentIcon,
  Download as DownloadIcon,
  Send as SendIcon,
  PictureAsPdf,
  Image as ImageIcon,
} from '@mui/icons-material';
import { 
  getShipmentById, 
  updateShipmentStatus, 
  addShipmentComment,
  getShipmentComments,
  getShipmentFiles,
  uploadShipmentFile,
  getFilePreviewUrl,
  downloadShipmentFile,
  getShipmentSpreadsheetData,
  getShipmentTypes,
} from '../services/api';
import { format } from 'date-fns';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import SpreadsheetExportable from './spreadsheets/SpreadsheetExportable';

const TimelineContainer = styled('div')({
  marginLeft: '16px',
  paddingLeft: '16px',
  borderLeft: '2px solid #e0e0e0',
});

const TimelineItem = styled('div')({
  position: 'relative',
  paddingBottom: '24px',
  '&:last-child': {
    paddingBottom: 0,
  },
});

const TimelineDot = styled('div')(({ theme }) => ({
  position: 'absolute',
  left: '-25px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '& svg': {
    fontSize: '12px',
  },
}));

const TimelineContent = styled('div')({
  marginLeft: '24px',
  padding: '8px 0',
});

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '400px',
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  overflow: 'hidden',
});

const ChatMessages = styled('div')({
  flex: 1,
  overflowY: 'auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column-reverse', // Show newest messages at bottom
});

const ChatInputContainer = styled('div')({
  padding: '16px',
  borderTop: '1px solid #e0e0e0',
  backgroundColor: '#fff',
});

const MessageBubble = styled('div')(({ theme, isCurrentUser }) => ({
  maxWidth: '70%',
  padding: '12px 16px',
  borderRadius: '12px',
  marginBottom: '8px',
  alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
  backgroundColor: isCurrentUser ? theme.palette.primary.main : '#e0e0e0',
  color: isCurrentUser ? theme.palette.primary.contrastText : 'inherit',
}));

const FilePreview = styled('div')({
  width: '120px',
  height: '120px',
  marginRight: '16px',
  position: 'relative',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#f5f5f5',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  '& object': {
    width: '100%',
    height: '100%',
  }
});

const UploadProgress = styled('div')({
  marginTop: '8px',
  width: '100%',
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`shipment-tabpanel-${index}`}
      aria-labelledby={`shipment-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function ShipmentDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [shipment, setShipment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [cancellationReason, setCancellationReason] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortBy, setSortBy] = useState('item_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [files, setFiles] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploads, setFileUploads] = useState(new Map());
  const [previews, setPreviews] = useState(new Map());
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedType, setSelectedType] = useState('');
  const [types, setTypes] = useState([]);
  const [spreadsheetData, setSpreadsheetData] = useState({});

  const statusOptions = {
    pending: { label: 'Pending', color: 'default' },
    processing: { label: 'Processing', color: 'info' },
    in_transit: { label: 'In Transit', color: 'primary' },
    delivered: { label: 'Delivered', color: 'success' },
    cancelled: { label: 'Cancelled', color: 'error' },
  };

  useEffect(() => {
    fetchShipmentData();
  }, [id]);

  useEffect(() => {
    files.forEach(file => {
      if (!previews.has(file.file_id)) {
        loadFilePreview(file);
      }
    });
  }, [files]);

  useEffect(() => {
    if (currentTab === 2) {
      loadSpreadsheetData();
    }
  }, [currentTab, selectedType]);

  const fetchShipmentData = async () => {
    try {
      setLoading(true);
      const [shipmentData, commentsData, filesData] = await Promise.all([
        getShipmentById(id),
        getShipmentComments(id),
        getShipmentFiles(id)
      ]);
      setShipment(shipmentData);
      setComments(commentsData);
      setFiles(filesData);
    } catch (err) {
      setError(err.message || 'Failed to fetch shipment details');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async () => {
    try {
      await updateShipmentStatus(
        id, 
        selectedStatus, 
        selectedStatus === 'cancelled' ? cancellationReason : undefined
      );
      await fetchShipmentData();
      setStatusDialogOpen(false);
      setSelectedStatus('');
      setCancellationReason('');
    } catch (err) {
      setError(err.message || 'Failed to update status');
    }
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    try {
      await addShipmentComment(id, newComment);
      await fetchShipmentData();
      setNewComment('');
    } catch (err) {
      setError(err.message || 'Failed to add comment');
    }
  };

  const getFilteredAndSortedItems = () => {
    if (!shipment?.items) return [];
    
    let filteredItems = [...shipment.items];
    
    // Apply search filter
    if (searchTerm) {
      filteredItems = filteredItems.filter(item => 
        item.item_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.item_details?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Sort items
    filteredItems.sort((a, b) => {
      const aValue = a[sortBy] || '';
      const bValue = b[sortBy] || '';
      
      if (sortOrder === 'asc') {
        return aValue.toString().localeCompare(bValue.toString());
      } else {
        return bValue.toString().localeCompare(aValue.toString());
      }
    });

    return filteredItems;
  };

  const handleFileUpload = async () => {
    try {
      if (!selectedFile) return;

      setFileUploads(prev => new Map(prev).set(selectedFile.name, 0));

      const uploadedFile = await uploadShipmentFile(
        id, 
        selectedFile,
        (progress) => {
          setFileUploads(prev => new Map(prev).set(selectedFile.name, progress));
        }
      );

      setFileUploads(prev => {
        const newMap = new Map(prev);
        newMap.delete(selectedFile.name);
        return newMap;
      });

      await fetchShipmentData();
      setUploadDialogOpen(false);
      setSelectedFile(null);
    } catch (err) {
      setError(err.message || 'Failed to upload file');
      setFileUploads(prev => {
        const newMap = new Map(prev);
        newMap.delete(selectedFile.name);
        return newMap;
      });
    }
  };

  const handleFileDownload = async (file) => {
    try {
      const blob = await downloadShipmentFile(id, file.file_id);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.file_name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Clean up the URL object
    } catch (err) {
      setError(err.message || 'Failed to download file');
    }
  };

  const loadFilePreview = async (file) => {
    try {
      if (previews.has(file.file_id)) return;
      const previewUrl = await getFilePreviewUrl(id, file.file_id);
      setPreviews(prev => new Map(prev).set(file.file_id, previewUrl));
    } catch (error) {
      setError(error.message || 'Failed to load file preview');
    }
  };

  const loadSpreadsheetData = async () => {
    try {
      setError(null);
      
      // First load available types if not loaded
      if (types.length === 0) {
        const availableTypes = await getShipmentTypes(id);
        setTypes(availableTypes);
        if (availableTypes.length > 0 && !selectedType) {
          setSelectedType(availableTypes[0]);
        }
      }
      
      // Then load data for selected type
      if (selectedType) {
        const data = await getShipmentSpreadsheetData(id, selectedType);
        // If the response is empty, set empty data structure
        if (Object.keys(data).length === 0) {
          const emptyData = {};
          const sizes = Array.from({length: 12}, (_, i) => (51 + i).toString());
          const brims = ['70', '74', '76', '78', '82', '86', '88', '90', '95', '100', '105', '110', '115'];
          
          sizes.forEach(size => {
            emptyData[size] = {};
            brims.forEach(brim => {
              emptyData[size][brim] = {
                XR: 0,
                R: 0,
                L: 0,
                X: 0
              };
            });
          });
          setSpreadsheetData(emptyData);
        } else {
          setSpreadsheetData(data);
        }
      }
    } catch (error) {
      setError(error.message || 'Failed to load spreadsheet data');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!shipment) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="info">Shipment not found</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4">
            Shipment {shipment.shipment_number}
          </Typography>
          <Button 
            variant="outlined" 
            onClick={() => setStatusDialogOpen(true)}
          >
            Update Status
          </Button>
        </Box>

        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
        >
          <Tab label="Details" />
          <Tab label="Items Grid" />
          <Tab label="Items Spreadsheet" />
          <Tab label="Comments & Files" />
        </Tabs>

        <TabPanel value={currentTab} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Shipment Details
              </Typography>
              <Box mb={2}>
                <Chip 
                  label={statusOptions[shipment.status]?.label || shipment.status}
                  color={statusOptions[shipment.status]?.color || 'default'}
                  sx={{ mb: 1 }}
                />
              </Box>
              <Typography><strong>Shipment Number:</strong> {shipment.shipment_number}</Typography>
              <Typography><strong>From:</strong> {shipment.source_warehouse_name}</Typography>
              {shipment.destination_warehouse_name && (
                <Typography><strong>To Warehouse:</strong> {shipment.destination_warehouse_name}</Typography>
              )}
              {shipment.destination_location_name && (
                <Typography><strong>To Location:</strong> {shipment.destination_location_name}</Typography>
              )}
              <Typography><strong>Type:</strong> {shipment.shipment_type}</Typography>
              <Typography><strong>Method:</strong> {shipment.shipping_method}</Typography>
              {shipment.tracking_number && (
                <Typography><strong>Tracking:</strong> {shipment.tracking_number}</Typography>
              )}
              {shipment.carrier && (
                <Typography><strong>Carrier:</strong> {shipment.carrier}</Typography>
              )}
              {shipment.notes && (
                <Typography><strong>Notes:</strong> {shipment.notes}</Typography>
              )}
              {shipment.special_instructions && (
                <Typography><strong>Special Instructions:</strong> {shipment.special_instructions}</Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Dates
              </Typography>
              <Typography>
                <strong>Created:</strong> {format(new Date(shipment.created_at), 'PPpp')}
              </Typography>
              {shipment.scheduled_date && (
                <Typography>
                  <strong>Scheduled:</strong> {format(new Date(shipment.scheduled_date), 'PPpp')}
                </Typography>
              )}
              {shipment.shipped_date && (
                <Typography>
                  <strong>Shipped:</strong> {format(new Date(shipment.shipped_date), 'PPpp')}
                </Typography>
              )}
              {shipment.estimated_delivery_date && (
                <Typography>
                  <strong>Estimated Delivery:</strong> {format(new Date(shipment.estimated_delivery_date), 'PPpp')}
                </Typography>
              )}
              {shipment.actual_delivery_date && (
                <Typography>
                  <strong>Delivered:</strong> {format(new Date(shipment.actual_delivery_date), 'PPpp')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Items
            </Typography>
            
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search items..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Sort By</InputLabel>
                  <Select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    label="Sort By"
                  >
                    <MenuItem value="warehouse_item_id">Item ID</MenuItem>
                    <MenuItem value="quantity">Quantity</MenuItem>
                    <MenuItem value="order_item_id">Order Reference</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Sort Order</InputLabel>
                  <Select
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                    label="Sort Order"
                  >
                    <MenuItem value="asc">Ascending</MenuItem>
                    <MenuItem value="desc">Descending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <TableContainer component={Paper} sx={{ mb: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Details</TableCell>
                    <TableCell>Warehouse ID</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell>Order Reference</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFilteredAndSortedItems().map((item) => (
                    <TableRow key={item.shipment_item_id}>
                      <TableCell>
                        <Typography variant="body1">
                          {item.item_name || 'N/A'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">
                          {`${item.item_type} - ${item.item_size} - ${item.item_shape} ${item.item_brim ? `- ${item.item_brim}` : ''}`}
                        </Typography>
                      </TableCell>
                      <TableCell>{item.warehouse_item_id}</TableCell>
                      <TableCell align="right">
                        <Typography variant="body1" fontWeight="medium">
                          {item.quantity}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {item.is_part_of_order ? (
                          <Chip 
                            size="small" 
                            label={`Order Item #${item.order_item_id}`}
                            color="primary" 
                            sx={{ maxWidth: '200px' }}
                          />
                        ) : (
                          <Typography variant="body2" color="textSecondary">
                            N/A
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          label={shipment.status || 'Pending'}
                          color={shipment.status === 'delivered' ? 'success' : 'default'}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </TabPanel>

        <TabPanel value={currentTab} index={2}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Shipment Items Spreadsheet
            </Typography>
            
            <FormControl sx={{ mb: 2, minWidth: 200 }}>
              <InputLabel>Item Type</InputLabel>
              <Select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                label="Item Type"
              >
                {types.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>
            
            {selectedType && (
              <SpreadsheetExportable
                data={spreadsheetData}
                title={`${selectedType} Items`}
              />
            )}
          </Box>
        </TabPanel>

        <TabPanel value={currentTab} index={3}>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Comments & History
            </Typography>
            <ChatContainer>
              <ChatMessages>
                {comments.map((comment) => (
                  <Box key={comment.comment_id} sx={{ mb: 2 }}>
                    <MessageBubble 
                      isCurrentUser={comment.user_id === parseInt(localStorage.getItem('userId'))}
                    >
                      <Box display="flex" alignItems="center" gap={1} mb={1}>
                        {comment.user_avatar && (
                          <Avatar src={comment.user_avatar} sx={{ width: 24, height: 24 }} />
                        )}
                        <Typography variant="subtitle2" component="span">
                          {comment.user_name}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          {format(new Date(comment.created_at), 'PPpp')}
                        </Typography>
                      </Box>
                      <Typography>{comment.comment_text}</Typography>
                    </MessageBubble>
                  </Box>
                ))}
              </ChatMessages>
              <ChatInputContainer>
                <form onSubmit={handleAddComment}>
                  <Box display="flex" gap={1}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder="Type a comment..."
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton 
                              type="submit"
                              disabled={!newComment.trim()}
                              color="primary"
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </form>
              </ChatInputContainer>
            </ChatContainer>
          </Paper>

          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Files & Attachments
            </Typography>
            <List>
              {files.map((file) => {
                const isUploading = fileUploads.has(file.file_name);
                const uploadProgress = fileUploads.get(file.file_name);
                const previewUrl = previews.get(file.file_id);

                return (
                  <ListItem 
                    key={file.file_id}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      py: 2,
                    }}
                  >
                    <FilePreview>
                      {file.file_type.startsWith('image/') ? (
                        previewUrl ? (
                          <img 
                            src={previewUrl} 
                            alt={file.file_name}
                            onError={(e) => {
                              console.error('Image preview failed to load');
                              e.target.parentElement.innerHTML = `
                                <Box sx={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  bgcolor: 'grey.100',
                                  borderRadius: 1,
                                }}>
                                  <ImageIcon color="action" />
                                </Box>
                              `;
                            }}
                          />
                        ) : (
                          <CircularProgress size={24} />
                        )
                      ) : file.file_type === 'application/pdf' ? (
                        previewUrl ? (
                          <iframe
                            src={previewUrl}
                            title={file.file_name}
                            width="100%"
                            height="100%"
                            style={{ border: 'none' }}
                            onError={(e) => {
                              console.error('PDF preview failed to load');
                              e.target.parentElement.innerHTML = `
                                <Box sx={{
                                  width: '100%',
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  bgcolor: 'grey.100',
                                  borderRadius: 1,
                                }}>
                                  <PictureAsPdf color="action" />
                                </Box>
                              `;
                            }}
                          />
                        ) : (
                          <PictureAsPdf color="action" />
                        )
                      ) : (
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'grey.100',
                            borderRadius: 1,
                          }}
                        >
                          <AttachmentIcon color="action" />
                        </Box>
                      )}
                    </FilePreview>
                    <ListItemText
                      primary={file.file_name}
                      secondary={
                        <>
                          <Typography variant="body2" color="textSecondary">
                            Uploaded by {file.uploaded_by_username} on {format(new Date(file.uploaded_at), 'PPpp')}
                          </Typography>
                          {isUploading && (
                            <UploadProgress>
                              <LinearProgress 
                                variant="determinate" 
                                value={uploadProgress} 
                                sx={{ mb: 1 }}
                              />
                              <Typography variant="caption" color="textSecondary">
                                Uploading: {uploadProgress}%
                              </Typography>
                            </UploadProgress>
                          )}
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton 
                        edge="end" 
                        onClick={() => handleFileDownload(file)}
                        disabled={isUploading}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </Paper>
        </TabPanel>
      </Paper>

      {/* Status Update Dialog */}
      <Dialog open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
        <DialogTitle>Update Shipment Status</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="Status"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            margin="normal"
            SelectProps={{
              native: true,
            }}
          >
            <option value="">Select status</option>
            {Object.entries(statusOptions).map(([value, { label }]) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </TextField>
          {selectedStatus === 'cancelled' && (
            <TextField
              fullWidth
              label="Cancellation Reason"
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
              margin="normal"
              multiline
              rows={3}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleStatusChange}
            disabled={!selectedStatus}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* File Upload Dialog */}
      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)}>
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <input
            type="file"
            onChange={(e) => setSelectedFile(e.target.files[0])}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input">
            <Button
              variant="outlined"
              component="span"
              startIcon={<UploadIcon />}
              sx={{ mt: 2 }}
            >
              Choose File
            </Button>
          </label>
          {selectedFile && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected: {selectedFile.name}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleFileUpload}
            disabled={!selectedFile}
            variant="contained"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ShipmentDetails; 