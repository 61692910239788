import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Paper, 
    Typography, 
    List, 
    ListItem, 
    ListItemText, 
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem 
} from '@mui/material';
import SpreadsheetEditable from './spreadsheets/SpreadsheetEditable';
import { getWarehouseInventorySpreadsheet } from '../services/api';

const WarehouseSpreadsheetView = ({ warehouseId, onClose }) => {
    const [spreadsheetData, setSpreadsheetData] = useState(null);
    const [edits, setEdits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');

    useEffect(() => {
        fetchTypes();
    }, [warehouseId]);

    useEffect(() => {
        if (selectedType) {
            fetchSpreadsheetData();
        }
    }, [selectedType]);

    const fetchTypes = async () => {
        try {
            const availableTypes = await getWarehouseInventorySpreadsheet(warehouseId);
            setTypes(availableTypes);
            if (availableTypes.length > 0) {
                setSelectedType(availableTypes[0]);
            }
        } catch (error) {
            console.error('Error fetching types:', error);
        }
    };

    const fetchSpreadsheetData = async () => {
        try {
            setLoading(true);
            const data = await getWarehouseInventorySpreadsheet(warehouseId, selectedType);
            setSpreadsheetData(data);
        } catch (error) {
            console.error('Error fetching spreadsheet data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCellValueChanged = ({ size, brim, shape, newValue, oldValue }) => {
        const edit = {
            id: Date.now(),
            type: selectedType,
            size,
            brim,
            shape,
            oldValue: oldValue || 0,
            newValue: newValue || 0,
            timestamp: new Date().toLocaleTimeString()
        };
        setEdits(prev => [edit, ...prev]);
    };

    if (loading && !spreadsheetData) return <Typography>Loading...</Typography>;

    return (
        <Box sx={{ display: 'flex', height: '100%' }}>
            {/* Spreadsheet section - 70% width */}
            <Box sx={{ width: '70%', p: 2 }}>
                <FormControl sx={{ mb: 2, minWidth: 200 }}>
                    <InputLabel>Item Type</InputLabel>
                    <Select
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        label="Item Type"
                    >
                        {types.map(type => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {spreadsheetData && (
                    <SpreadsheetEditable
                        data={spreadsheetData}
                        onCellValueChanged={handleCellValueChanged}
                        title={`${selectedType} Inventory`}
                    />
                )}
            </Box>

            {/* Edits panel - 30% width */}
            <Paper sx={{ width: '30%', p: 2, overflowY: 'auto' }}>
                <Typography variant="h6" gutterBottom>
                    Pending Changes
                </Typography>
                <List>
                    {edits.map((edit) => (
                        <React.Fragment key={edit.id}>
                            <ListItem>
                                <ListItemText
                                    primary={`${edit.type} - Size ${edit.size} Brim ${edit.brim} ${edit.shape}`}
                                    secondary={
                                        <>
                                            <Typography component="span" variant="body2">
                                                Changed from {edit.oldValue} to {edit.newValue}
                                            </Typography>
                                            <br />
                                            <Typography component="span" variant="caption" color="textSecondary">
                                                {edit.timestamp}
                                            </Typography>
                                        </>
                                    }
                                />
                            </ListItem>
                            <Divider />
                        </React.Fragment>
                    ))}
                    {edits.length === 0 && (
                        <ListItem>
                            <ListItemText
                                secondary="No changes made yet"
                                sx={{ textAlign: 'center', color: 'text.secondary' }}
                            />
                        </ListItem>
                    )}
                </List>
            </Paper>
        </Box>
    );
};

export default WarehouseSpreadsheetView; 