import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Typography,
  Snackbar,
  Alert,
  Grid,
  Divider
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  Business as BusinessIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  LocationOn as LocationIcon,
  Lock as LockIcon,
  Check as CheckIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  getAllCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getAllPortalAccess,
  createPortalAccess,
  updatePortalAccess
} from '../services/api';

// Styled components
const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3)
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2)
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2)
}));

const PasswordRequirement = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  transition: 'all 0.2s ease-in-out',
}));

const PASSWORD_REQUIREMENTS = [
  'At least 8 characters long',
  'At least one uppercase letter',
  'At least one lowercase letter',
  'At least one number',
  'At least one special character (!@#$%^&*(),.?":{}|<>)'
];

const PASSWORD_REQUIREMENT_CHECKS = [
  {
    label: 'At least 8 characters long',
    check: (password) => password?.length >= 8
  },
  {
    label: 'At least one uppercase letter',
    check: (password) => password ? /[A-Z]/.test(password) : false
  },
  {
    label: 'At least one lowercase letter',
    check: (password) => password ? /[a-z]/.test(password) : false
  },
  {
    label: 'At least one number',
    check: (password) => password ? /[0-9]/.test(password) : false
  },
  {
    label: 'At least one special character',
    check: (password) => password ? /[!@#$%^&*(),.?":{}|<>]/.test(password) : false
  }
];

function CustomerManagement() {
  const [customers, setCustomers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState({
    company_name: '',
    business_type: '',
    tax_id: '',
    primary_contact_name: '',
    primary_contact_email: '',
    primary_contact_phone: '',
    secondary_contact_name: '',
    secondary_contact_email: '',
    secondary_contact_phone: '',
    billing_address_street: '',
    billing_address_city: '',
    billing_address_state: '',
    billing_address_postal: '',
    billing_address_country: '',
    shipping_address_street: '',
    shipping_address_city: '',
    shipping_address_state: '',
    shipping_address_postal: '',
    shipping_address_country: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [portalAccess, setPortalAccess] = useState({});
  const [portalDialog, setPortalDialog] = useState(false);
  const [currentPortal, setCurrentPortal] = useState({
    username: '',
    password: ''
  });

  useEffect(() => {
    fetchCustomers();
    fetchPortalAccess();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await getAllCustomers();
      setCustomers(response.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching customers: ' + error.message,
        severity: 'error'
      });
    }
  };

  const fetchPortalAccess = async () => {
    try {
      const response = await getAllPortalAccess();
      const portalMap = {};
      response.data.forEach(portal => {
        portalMap[portal.customer_id] = portal;
      });
      setPortalAccess(portalMap);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error fetching portal access details: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleOpenDialog = (customer = null) => {
    if (customer) {
      setCurrentCustomer(customer);
      setIsEditing(true);
    } else {
      setCurrentCustomer({
        company_name: '',
        business_type: '',
        tax_id: '',
        primary_contact_name: '',
        primary_contact_email: '',
        primary_contact_phone: '',
        secondary_contact_name: '',
        secondary_contact_email: '',
        secondary_contact_phone: '',
        billing_address_street: '',
        billing_address_city: '',
        billing_address_state: '',
        billing_address_postal: '',
        billing_address_country: '',
        shipping_address_street: '',
        shipping_address_city: '',
        shipping_address_state: '',
        shipping_address_postal: '',
        shipping_address_country: ''
      });
      setIsEditing(false);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentCustomer({});
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentCustomer(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      if (isEditing) {
        await updateCustomer(currentCustomer.customer_id, currentCustomer);
        setSnackbar({
          open: true,
          message: 'Customer updated successfully',
          severity: 'success'
        });
      } else {
        await createCustomer(currentCustomer);
        setSnackbar({
          open: true,
          message: 'Customer created successfully',
          severity: 'success'
        });
      }
      fetchCustomers();
      handleCloseDialog();
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error: ${error.response?.data?.error || error.message}`,
        severity: 'error'
      });
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      try {
        await deleteCustomer(customerId);
        setSnackbar({
          open: true,
          message: 'Customer deleted successfully',
          severity: 'success'
        });
        fetchCustomers();
      } catch (error) {
        setSnackbar({
          open: true,
          message: `Error: ${error.response?.data?.error || error.message}`,
          severity: 'error'
        });
      }
    }
  };

  const handlePortalDialogOpen = (customer) => {
    setCurrentCustomer(customer);
    const existingPortal = portalAccess[customer.customer_id];
    setCurrentPortal({
      username: existingPortal?.username || '',
      password: ''
    });
    setPortalDialog(true);
  };

  const handlePortalDialogClose = () => {
    setPortalDialog(false);
    setCurrentPortal({ username: '', password: '' });
  };

  const validatePassword = (password) => {
    const requirements = [
      password.length >= 8,
      /[A-Z]/.test(password),
      /[a-z]/.test(password),
      /[0-9]/.test(password),
      /[!@#$%^&*(),.?":{}|<>]/.test(password)
    ];
    
    const errors = [];
    requirements.forEach((isValid, index) => {
      if (!isValid) {
        errors.push(PASSWORD_REQUIREMENTS[index]);
      }
    });
    
    return {
      isValid: errors.length === 0,
      errors
    };
  };

  const handlePortalSubmit = async () => {
    try {
      const strength = getPasswordStrength(currentPortal.password);
      if (strength < PASSWORD_REQUIREMENT_CHECKS.length) {
        setSnackbar({
          open: true,
          message: 'Please meet all password requirements before submitting',
          severity: 'error'
        });
        return;
      }

      const customerId = currentCustomer.customer_id;
      if (portalAccess[customerId]) {
        await updatePortalAccess(customerId, currentPortal);
      } else {
        await createPortalAccess(customerId, currentPortal);
      }
      
      await fetchPortalAccess();
      setSnackbar({
        open: true,
        message: 'Portal access updated successfully',
        severity: 'success'
      });
      handlePortalDialogClose();
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error: ${error.response?.data?.error || error.message}`,
        severity: 'error'
      });
    }
  };

  const getPasswordStrength = (password) => {
    if (!password) return 0;
    return PASSWORD_REQUIREMENT_CHECKS.filter(req => req.check(password)).length;
  };

  const renderTableRow = (customer) => (
    <TableRow key={customer.customer_id}>
      <TableCell>{customer.company_name}</TableCell>
      <TableCell>{customer.business_type}</TableCell>
      <TableCell>{customer.primary_contact_name}</TableCell>
      <TableCell>{customer.primary_contact_email}</TableCell>
      <TableCell>{customer.primary_contact_phone}</TableCell>
      <TableCell>
        <IconButton onClick={() => handleOpenDialog(customer)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDeleteCustomer(customer.customer_id)}>
          <DeleteIcon />
        </IconButton>
        <IconButton 
          onClick={() => handlePortalDialogOpen(customer)}
          color={portalAccess[customer.customer_id] ? "primary" : "default"}
        >
          <LockIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return (
    <StyledContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Title variant="h4">Enterprise Customers</Title>
        <AddButton
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
          variant="contained"
          color="primary"
        >
          Add New Customer
        </AddButton>
      </Box>

      <StyledPaper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>Business Type</TableCell>
                <TableCell>Primary Contact</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => renderTableRow(customer))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          {isEditing ? 'Edit Customer' : 'Add New Customer'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Company Information
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="company_name"
                label="Company Name"
                fullWidth
                required
                value={currentCustomer.company_name || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="business_type"
                label="Business Type"
                fullWidth
                value={currentCustomer.business_type || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="tax_id"
                label="Tax ID"
                fullWidth
                value={currentCustomer.tax_id || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Primary Contact
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="primary_contact_name"
                label="Name"
                fullWidth
                required
                value={currentCustomer.primary_contact_name || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="primary_contact_email"
                label="Email"
                fullWidth
                required
                value={currentCustomer.primary_contact_email || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="primary_contact_phone"
                label="Phone"
                fullWidth
                value={currentCustomer.primary_contact_phone || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Secondary Contact
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="secondary_contact_name"
                label="Name"
                fullWidth
                value={currentCustomer.secondary_contact_name || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="secondary_contact_email"
                label="Email"
                fullWidth
                value={currentCustomer.secondary_contact_email || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="secondary_contact_phone"
                label="Phone"
                fullWidth
                value={currentCustomer.secondary_contact_phone || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Billing Address
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="billing_address_street"
                label="Street Address"
                fullWidth
                value={currentCustomer.billing_address_street || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="billing_address_city"
                label="City"
                fullWidth
                value={currentCustomer.billing_address_city || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="billing_address_state"
                label="State/Province"
                fullWidth
                value={currentCustomer.billing_address_state || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="billing_address_postal"
                label="Postal Code"
                fullWidth
                value={currentCustomer.billing_address_postal || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="billing_address_country"
                label="Country"
                fullWidth
                value={currentCustomer.billing_address_country || ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Shipping Address
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="shipping_address_street"
                label="Street Address"
                fullWidth
                value={currentCustomer.shipping_address_street || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="shipping_address_city"
                label="City"
                fullWidth
                value={currentCustomer.shipping_address_city || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="shipping_address_state"
                label="State/Province"
                fullWidth
                value={currentCustomer.shipping_address_state || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="shipping_address_postal"
                label="Postal Code"
                fullWidth
                value={currentCustomer.shipping_address_postal || ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextField
                name="shipping_address_country"
                label="Country"
                fullWidth
                value={currentCustomer.shipping_address_country || ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            startIcon={isEditing ? <SaveIcon /> : <AddIcon />}
          >
            {isEditing ? 'Save Changes' : 'Create Customer'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={portalDialog} onClose={handlePortalDialogClose}>
        <DialogTitle>
          {portalAccess[currentCustomer?.customer_id] ? 'Update Portal Access' : 'Create Portal Access'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>
                {currentCustomer?.company_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="username"
                label="Username"
                fullWidth
                required
                value={currentPortal.username}
                onChange={(e) => setCurrentPortal(prev => ({
                  ...prev,
                  username: e.target.value
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                name="password"
                label="Password"
                type="password"
                fullWidth
                required
                value={currentPortal.password}
                onChange={(e) => setCurrentPortal(prev => ({
                  ...prev,
                  password: e.target.value
                }))}
              />
              <Box sx={{ mt: 2 }}>
                <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Password Strength:
                  </Typography>
                  <Box sx={{ 
                    flex: 1, 
                    height: 4, 
                    bgcolor: 'grey.200', 
                    borderRadius: 2,
                    overflow: 'hidden'
                  }}>
                    <Box sx={{ 
                      height: '100%', 
                      width: `${(getPasswordStrength(currentPortal.password) / PASSWORD_REQUIREMENT_CHECKS.length) * 100}%`,
                      bgcolor: (theme) => {
                        const strength = getPasswordStrength(currentPortal.password);
                        if (strength <= 2) return theme.palette.error.main;
                        if (strength <= 4) return theme.palette.warning.main;
                        return theme.palette.success.main;
                      },
                      transition: 'all 0.3s ease'
                    }} />
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  {PASSWORD_REQUIREMENT_CHECKS.map((req, index) => {
                    const isValid = req.check(currentPortal.password);
                    return (
                      <PasswordRequirement 
                        key={index}
                        sx={{ 
                          color: isValid ? 'success.main' : 'text.secondary',
                          opacity: currentPortal.password ? 1 : 0.7,
                        }}
                      >
                        {isValid ? (
                          <CheckIcon fontSize="small" color="success" />
                        ) : (
                          <CloseIcon fontSize="small" color="disabled" />
                        )}
                        <Typography 
                          variant="body2"
                          sx={{ 
                            textDecoration: isValid ? 'line-through' : 'none',
                            transition: 'all 0.2s ease'
                          }}
                        >
                          {req.label}
                        </Typography>
                      </PasswordRequirement>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePortalDialogClose}>Cancel</Button>
          <Button
            onClick={handlePortalSubmit}
            variant="contained"
            color="primary"
            disabled={!currentPortal.username || !currentPortal.password}
          >
            {portalAccess[currentCustomer?.customer_id] ? 'Update Access' : 'Create Access'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}

export default CustomerManagement;