import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  MoreVert as MoreVertIcon,
  Comment as CommentIcon,
  LocalShipping as LocalShippingIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import {
  getAllShipments,
  updateShipmentStatus,
  addShipmentComment,
  getShipmentComments,
} from '../services/api';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StatusChip = styled(Chip)(({ theme, status }) => {
  const colors = {
    pending: theme.palette.warning.main,
    in_transit: theme.palette.info.main,
    delivered: theme.palette.success.main,
    cancelled: theme.palette.error.main,
  };

  return {
    backgroundColor: colors[status] || theme.palette.grey[500],
    color: theme.palette.common.white,
    fontWeight: 600,
  };
});

function Shipments() {
  const navigate = useNavigate();
  const [shipments, setShipments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [statusMenuAnchor, setStatusMenuAnchor] = useState(null);
  const [selectedShipmentForStatus, setSelectedShipmentForStatus] = useState(null);
  const [cancellationReason, setCancellationReason] = useState('');
  const [cancellationDialogOpen, setCancellationDialogOpen] = useState(false);

  useEffect(() => {
    fetchShipments();
  }, []);

  const fetchShipments = async () => {
    try {
      const data = await getAllShipments();
      setShipments(data);
    } catch (error) {
      console.error('Error fetching shipments:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateShipment = () => {
    navigate('/shipments/create');
  };

  const handleStatusClick = (event, shipment) => {
    setStatusMenuAnchor(event.currentTarget);
    setSelectedShipmentForStatus(shipment);
  };

  const handleStatusClose = () => {
    setStatusMenuAnchor(null);
    setSelectedShipmentForStatus(null);
  };

  const handleStatusChange = async (newStatus) => {
    try {
      if (newStatus === 'cancelled') {
        setCancellationDialogOpen(true);
      } else {
        await updateShipmentStatus(selectedShipmentForStatus.shipment_id, newStatus);
        fetchShipments();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
    handleStatusClose();
  };

  const handleCancellationConfirm = async () => {
    try {
      await updateShipmentStatus(
        selectedShipmentForStatus.shipment_id,
        'cancelled',
        cancellationReason
      );
      fetchShipments();
      setCancellationDialogOpen(false);
      setCancellationReason('');
    } catch (error) {
      console.error('Error cancelling shipment:', error);
    }
  };

  const handleCommentClick = async (shipment) => {
    setSelectedShipment(shipment);
    try {
      const commentsData = await getShipmentComments(shipment.shipment_id);
      setComments(commentsData);
      setCommentDialogOpen(true);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  const handleAddComment = async () => {
    try {
      await addShipmentComment(selectedShipment.shipment_id, newComment);
      const updatedComments = await getShipmentComments(selectedShipment.shipment_id);
      setComments(updatedComments);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const getStatusLabel = (status) => {
    const labels = {
      pending: 'Pending',
      in_transit: 'In Transit',
      delivered: 'Delivered',
      cancelled: 'Cancelled',
    };
    return labels[status] || status;
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <StyledContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4">Shipments</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateShipment}
        >
          Create Shipment
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shipment #</TableCell>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Items</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shipments.map((shipment) => (
              <TableRow key={shipment.shipment_id}>
                <TableCell>{shipment.shipment_number}</TableCell>
                <TableCell>{shipment.source_warehouse_name}</TableCell>
                <TableCell>
                  {shipment.destination_warehouse_name || shipment.destination_location_name}
                </TableCell>
                <TableCell>
                  <StatusChip
                    label={getStatusLabel(shipment.status)}
                    status={shipment.status}
                    onClick={(e) => handleStatusClick(e, shipment)}
                  />
                </TableCell>
                <TableCell>
                  {format(new Date(shipment.created_at), 'MMM d, yyyy HH:mm')}
                </TableCell>
                <TableCell>{shipment.items?.length || 0} items</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() => navigate(`/shipments/${shipment.shipment_id}`)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Comments">
                    <IconButton onClick={() => handleCommentClick(shipment)}>
                      <CommentIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={statusMenuAnchor}
        open={Boolean(statusMenuAnchor)}
        onClose={handleStatusClose}
      >
        <MenuItem onClick={() => handleStatusChange('in_transit')}>Mark as In Transit</MenuItem>
        <MenuItem onClick={() => handleStatusChange('delivered')}>Mark as Delivered</MenuItem>
        <MenuItem onClick={() => handleStatusChange('cancelled')}>Cancel Shipment</MenuItem>
      </Menu>

      <Dialog open={commentDialogOpen} onClose={() => setCommentDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Comments</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            {comments.map((comment) => (
              <Box key={comment.comment_id} mb={2}>
                <Typography variant="subtitle2">{comment.user_name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {format(new Date(comment.created_at), 'MMM d, yyyy HH:mm')}
                </Typography>
                <Typography>{comment.comment_text}</Typography>
              </Box>
            ))}
          </Box>
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            placeholder="Add a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCommentDialogOpen(false)}>Close</Button>
          <Button
            onClick={handleAddComment}
            color="primary"
            disabled={!newComment.trim()}
          >
            Add Comment
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={cancellationDialogOpen}
        onClose={() => setCancellationDialogOpen(false)}
      >
        <DialogTitle>Cancel Shipment</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label="Reason for cancellation"
            value={cancellationReason}
            onChange={(e) => setCancellationReason(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancellationDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleCancellationConfirm}
            color="primary"
            disabled={!cancellationReason.trim()}
          >
            Confirm Cancellation
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
}

export default Shipments;