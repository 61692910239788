import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  Grid,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { createOrder, updateOrder, getOrderById, getAllCustomers, getAllItems } from '../services/api';
import { toast } from 'react-toastify';
import { TabView, TabPanel } from 'primereact/tabview';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { classNames } from 'primereact/utils';
import { styled } from '@mui/system';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import SpreadsheetEditable from './spreadsheets/SpreadsheetEditable';

const SpreadsheetWrapper = styled('div')({
  padding: '1rem',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  width: '100%',
  overflow: 'hidden',
});

const SpreadsheetTable = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  '& th, & td': {
    border: '1px solid #c0c0c0',
    padding: '10px',
    position: 'relative',
  },
  '& th': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    padding: '8px',
    borderBottom: '2px solid #333',
  },
  '& tr:first-child th': {
    padding: '8px',
    borderBottom: '2px solid #333',
  },
  '& tr:nth-child(2) th': {
    padding: '8px',
    borderBottom: '2px solid #333',
    '&:not(:first-child)': {
      borderLeft: '1px solid #c0c0c0',
      borderRight: '1px solid #c0c0c0',
    }
  },
  '& td': {
    backgroundColor: '#fff',
    minWidth: '80px',
    textAlign: 'center',
    padding: 0,
    '&:hover': {
      backgroundColor: '#f8f8f8',
    },
    '&.empty': {
      backgroundColor: '#fafafa',
    },
    '& input': {
      width: '100%',
      height: '100%',
      border: 'none',
      padding: '8px',
      textAlign: 'center',
      backgroundColor: 'transparent',
      '&:focus': {
        outline: '2px solid #2196f3',
        outlineOffset: '-2px',
      },
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      }
    }
  },
  '& .sticky-corner': {
    borderRight: '2px solid #333',
    borderBottom: '2px solid #333',
    backgroundColor: '#e6e6e6',
    position: 'sticky',
    left: 0,
    zIndex: 2,
  },
});

const ControlsPanel = styled('div')({
  display: 'flex',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  marginBottom: '1rem',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const ControlGroup = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  padding: '0.5rem',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  backgroundColor: '#fff',
});

const OrderForm = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [formData, setFormData] = useState({
    customer_id: '',
    order_number: '',
    status: 'pending',
    notes: '',
    items: []
  });
  const [activeTab, setActiveTab] = useState(0);
  const [allItems, setAllItems] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [hiddenSizes, setHiddenSizes] = useState(new Set());
  const [hiddenBrims, setHiddenBrims] = useState(new Set());
  const [hiddenShapes, setHiddenShapes] = useState(new Set());
  const [showHideControls, setShowHideControls] = useState(false);
  const [tableSize, setTableSize] = useState('100');
  const [activeStep, setActiveStep] = useState(0);
  const [hideZeros, setHideZeros] = useState(false);
  const [spreadsheetData, setSpreadsheetData] = useState(null);

  const steps = ['Order Details', 'Items'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const customersData = await getAllCustomers();
        setCustomers(customersData.data);

        if (orderId) {
          const orderData = await getOrderById(orderId);
          setFormData(orderData);
        }
      } catch (error) {
        toast.error('Failed to fetch data');
      }
    };

    fetchData();
  }, [orderId]);

  useEffect(() => {
    const fetchAllItems = async () => {
      try {
        const items = await getAllItems();
        const sortedItems = items.sort((a, b) => {
          if (a.type !== b.type) return a.type.localeCompare(b.type);
          if (a.size !== b.size) return parseFloat(a.size) - parseFloat(b.size);
          if (a.brim !== b.brim) return parseFloat(a.brim) - parseFloat(b.brim);
          return a.shape.localeCompare(b.shape);
        });
        setAllItems(sortedItems);
      } catch (error) {
        toast.error('Failed to fetch items');
      }
    };
    fetchAllItems();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAddItem = () => {
    setFormData({
      ...formData,
      items: [...formData.items, {
        item_id: '',
        quantity: 1,
        status: 'pending',
        notes: ''
      }]
    });
  };

  const handleRemoveItem = (index) => {
    const newItems = formData.items.filter((_, i) => i !== index);
    setFormData({ ...formData, items: newItems });
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...formData.items];
    newItems[index] = { ...newItems[index], [field]: value };
    setFormData({ ...formData, items: newItems });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (orderId) {
        await updateOrder(orderId, formData);
        toast.success('Order updated successfully');
      } else {
        await createOrder(formData);
        toast.success('Order created successfully');
      }
      navigate('/orders');
    } catch (error) {
      toast.error('Failed to save order');
    }
  };

  const toggleSize = (size) => {
    setHiddenSizes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(size)) {
        newSet.delete(size);
      } else {
        newSet.add(size);
      }
      return newSet;
    });
  };

  const toggleBrim = (brim) => {
    setHiddenBrims(prev => {
      const newSet = new Set(prev);
      if (newSet.has(brim)) {
        newSet.delete(brim);
      } else {
        newSet.add(brim);
      }
      return newSet;
    });
  };

  const toggleShape = (shape) => {
    setHiddenShapes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(shape)) {
        newSet.delete(shape);
      } else {
        newSet.add(shape);
      }
      return newSet;
    });
  };

  const handleSpreadsheetChange = useCallback(({ size, brim, shape, newValue }) => {
    const matchingItem = allItems.find(item => 
      item.type === selectedType && 
      item.size === size && 
      item.brim === brim && 
      item.shape === shape
    );

    if (!matchingItem) return;

    const newItems = [...formData.items];
    const existingItemIndex = newItems.findIndex(item => 
      item.item_id === matchingItem.id
    );

    const numValue = parseInt(newValue) || 0;

    if (numValue > 0) {
      if (existingItemIndex >= 0) {
        newItems[existingItemIndex].quantity = numValue;
      } else {
        newItems.push({
          item_id: matchingItem.id,
          quantity: numValue,
          status: 'pending',
          notes: ''
        });
      }
    } else {
      if (existingItemIndex >= 0) {
        newItems.splice(existingItemIndex, 1);
      }
    }

    setFormData(prev => ({
      ...prev,
      items: newItems
    }));
  }, [selectedType, allItems, formData.items]);

  const prepareSpreadsheetData = useCallback(() => {
    if (!selectedType || !allItems.length) return null;

    const typeItems = allItems.filter(item => item.type === selectedType);
    const data = {};

    typeItems.forEach(item => {
      if (!data[item.size]) {
        data[item.size] = {};
      }
      if (!data[item.size][item.brim]) {
        data[item.size][item.brim] = {};
      }
      
      const orderItem = formData.items.find(i => i.item_id === item.id);
      data[item.size][item.brim][item.shape] = orderItem ? orderItem.quantity : 0;
    });

    return data;
  }, [selectedType, allItems, formData.items]);

  useEffect(() => {
    const data = prepareSpreadsheetData();
    setSpreadsheetData(data);
  }, [selectedType, allItems, formData.items, prepareSpreadsheetData]);

  const types = useMemo(() => {
    return [...new Set(allItems.map(item => item.type))].sort();
  }, [allItems]);

  const handleNext = () => {
    if (activeStep === 0 && (!formData.customer_id || !formData.order_number)) {
      toast.error('Please fill in all required fields');
      return;
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const renderOrderDetails = () => (
    <Paper sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            label="Customer"
            name="customer_id"
            value={formData.customer_id}
            onChange={handleChange}
            required
          >
            {customers.map((customer) => (
              <MenuItem key={customer.customer_id} value={customer.customer_id}>
                {customer.company_name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Order Number"
            name="order_number"
            value={formData.order_number}
            onChange={handleChange}
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            select
            fullWidth
            label="Status"
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="processing">Processing</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Notes"
            name="notes"
            value={formData.notes}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );

  const renderSpreadsheetView = () => (
    <Box sx={{ p: 3 }}>
      <ControlsPanel>
        <ControlGroup>
          <Typography variant="subtitle2">Type:</Typography>
          <TextField
            select
            size="small"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            sx={{ minWidth: 200 }}
          >
            {types.map(type => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </TextField>
        </ControlGroup>
      </ControlsPanel>

      {spreadsheetData && (
        <SpreadsheetEditable
          data={spreadsheetData}
          title={`Order Items - ${selectedType}`}
          onCellValueChanged={handleSpreadsheetChange}
          isLoading={false}
        />
      )}
    </Box>
  );

  const renderItemsSection = () => (
    <TabView activeIndex={activeTab} onTabChange={(e) => setActiveTab(e.index)}>
      <TabPanel header="Form View">
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Typography variant="h6">Order Items</Typography>
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddItem}
                  variant="outlined"
                >
                  Add Item
                </Button>
              </Box>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Notes</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.items.map((item, index) => {
                      const itemDetails = allItems.find(i => i.id === item.item_id) || {};
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <TextField
                              select
                              fullWidth
                              value={item.item_id}
                              onChange={(e) => handleItemChange(index, 'item_id', e.target.value)}
                              required
                            >
                              {allItems.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {`${option.type} - ${option.size} - ${option.brim} - ${option.shape}`}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={item.quantity}
                              onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                              required
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              select
                              fullWidth
                              value={item.status}
                              onChange={(e) => handleItemChange(index, 'status', e.target.value)}
                              required
                            >
                              <MenuItem value="pending">Pending</MenuItem>
                              <MenuItem value="processing">Processing</MenuItem>
                              <MenuItem value="completed">Completed</MenuItem>
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <TextField
                              fullWidth
                              value={item.notes}
                              onChange={(e) => handleItemChange(index, 'notes', e.target.value)}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color="error"
                              onClick={() => handleRemoveItem(index)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </TabPanel>

      <TabPanel header="Spreadsheet View">
        {renderSpreadsheetView()}
      </TabPanel>
    </TabView>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        {orderId ? 'Edit Order' : 'Create New Order'}
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === 0 ? renderOrderDetails() : renderItemsSection()}

      <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 3 }}>
        <Button
          variant="outlined"
          onClick={() => navigate('/orders')}
        >
          Cancel
        </Button>
        
        {activeStep > 0 && (
          <Button
            variant="outlined"
            onClick={handleBack}
          >
            Back
          </Button>
        )}

        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            {orderId ? 'Update Order' : 'Create Order'}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleNext}
          >
            Next
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default OrderForm; 