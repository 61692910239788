import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Tabs,
  Tab,
  IconButton,
  Button,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { getWarehouseInventorySpreadsheet, getSpreadsheetTypes } from '../services/api';

const SpreadsheetContainer = styled(Paper)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#f4f4f4'
}));

const ToolbarContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderBottom: '1px solid #ddd',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: '#fff'
}));

const SpreadsheetTable = styled('table')(({ theme }) => ({
  borderCollapse: 'collapse',
  width: '100%',
  backgroundColor: '#fff',
  '& th, & td': {
    border: '1px solid #e0e0e0',
    padding: '8px',
    fontSize: '0.875rem',
    position: 'relative',
    minWidth: '80px',
  },
  '& th': {
    backgroundColor: '#f5f5f5',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    '&.sticky-corner': {
      zIndex: 3,
      left: 0,
    }
  },
  '& td': {
    textAlign: 'right',
    '&:hover': {
      backgroundColor: '#f8f9fa',
    },
    '&.editable:hover': {
      cursor: 'pointer',
      backgroundColor: '#e3f2fd',
    }
  },
  '& tr > th:first-child': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#f5f5f5',
    zIndex: 1,
  },
  '& .brim-group-start': {
    borderLeft: '2px solid #9e9e9e',
  }
}));

const ScrollContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'auto',
  position: 'relative'
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  zIndex: 1000
}));

const WarehouseSpreadsheet = ({ warehouseId, onClose }) => {
  const [activeType, setActiveType] = useState(null);
  const [availableTypes, setAvailableTypes] = useState([]);
  const [spreadsheetData, setSpreadsheetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hiddenSizes, setHiddenSizes] = useState(new Set());
  const [hiddenBrims, setHiddenBrims] = useState(new Set());
  const [hiddenShapes, setHiddenShapes] = useState(new Set());

  useEffect(() => {
    fetchTypes();
  }, []);

  useEffect(() => {
    if (activeType) {
      fetchSpreadsheetData();
    }
  }, [activeType, hiddenSizes, hiddenBrims, hiddenShapes]);

  const fetchTypes = async () => {
    try {
      const types = await getSpreadsheetTypes();
      setAvailableTypes(types);
      if (types.length > 0) {
        setActiveType(types[0]); // Set first type as default
      }
    } catch (error) {
      console.error('Error fetching spreadsheet types:', error);
    }
  };

  const fetchSpreadsheetData = async () => {
    setLoading(true);
    try {
      const data = await getWarehouseInventorySpreadsheet({
        warehouseId,
        type: activeType,
        hiddenSizes: Array.from(hiddenSizes),
        hiddenBrims: Array.from(hiddenBrims),
        hiddenShapes: Array.from(hiddenShapes)
      });
      setSpreadsheetData(data);
    } catch (error) {
      console.error('Error fetching spreadsheet data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTypeChange = (event, newValue) => {
    setActiveType(newValue);
  };

  if (!activeType) {
    return (
      <SpreadsheetContainer>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      </SpreadsheetContainer>
    );
  }

  return (
    <SpreadsheetContainer>
      <ToolbarContainer>
        <Tabs value={activeType} onChange={handleTypeChange}>
          {availableTypes.map(type => (
            <Tab key={type} label={type} value={type} />
          ))}
        </Tabs>
        
        <Box sx={{ flex: 1 }} />
        
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
      </ToolbarContainer>

      <ScrollContainer>
        {loading && (
          <LoadingOverlay>
            <CircularProgress />
          </LoadingOverlay>
        )}
        
        {spreadsheetData && (
          <SpreadsheetTable>
            <thead>
              <tr>
                <th className="sticky-corner">Size</th>
                {spreadsheetData.brims.map(brim => (
                  <th key={brim} colSpan={spreadsheetData.shapes.length}>
                    {brim}
                  </th>
                ))}
              </tr>
              <tr>
                <th></th>
                {spreadsheetData.brims.map(brim => 
                  spreadsheetData.shapes.map(shape => (
                    <th key={`${brim}-${shape}`}>{shape}</th>
                  ))
                )}
              </tr>
            </thead>
            <tbody>
              {spreadsheetData.sizes.map(size => (
                <tr key={size}>
                  <th>{size}</th>
                  {spreadsheetData.brims.map(brim =>
                    spreadsheetData.shapes.map((shape, index) => {
                      const key = `${size}-${brim}-${shape}`;
                      const item = spreadsheetData.itemsMap[key] || { value: 0, warehouseItemId: null };
                      return (
                        <td 
                          key={key}
                          className={`
                            ${index === 0 ? 'brim-group-start' : ''}
                            ${item.value > 0 ? 'has-value' : 'empty-cell'}
                          `}
                        >
                          {item.value}
                        </td>
                      );
                    })
                  )}
                </tr>
              ))}
            </tbody>
          </SpreadsheetTable>
        )}
      </ScrollContainer>
    </SpreadsheetContainer>
  );
};

export default WarehouseSpreadsheet; 