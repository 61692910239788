import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Add createTheme import
import CssBaseline from '@mui/material/CssBaseline';
import { PrimeReactProvider } from 'primereact/api';
import Login from './components/Login';
import Home from './components/Home';
import Navigation from './components/Navigation'; // Update this import
import Locations from './components/Locations';
import Settings from './components/Settings';
import POSManagement from './components/POSManagement';
import UserManagement from './components/UserManagement';
import { logout } from './services/api';
import LocationDetails from './components/LocationDetails';
import { jwtDecode } from 'jwt-decode';
import Sales from './components/Sales';
import ReplenishLevels from './components/ReplenishLevels';
import DeletedLocations from './components/DeletedLocations';
import Optimize from './components/Optimize';
import TransferOrders from './components/TransferOrders'; // Import the new component
import EmbeddedSheet from './components/EmbeddedSheet';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import ItemForwarders from './components/ItemForwarders';
import CustomerManagement from './components/CustomerManagement';
import Orders from './components/Orders';
import OrderForm from './components/OrderForm';
import Warehouses from './components/Warehouses';
import WarehouseDetails from './components/WarehouseDetails';
import ProductionEntry from './components/ProductionEntry';
import InventoryCheckForm from './components/InventoryCheckForm';
import Shipments from './components/Shipments';
import CreateShipment from './components/CreateShipment';
import ShipmentDetails from './components/ShipmentDetails';
import TestSpreadsheet from './components/spreadsheets/testspreadsheet';
import TestSpreadsheet2 from './components/spreadsheets/testspreadsheet2';
import TestSpreadsheet3 from './components/spreadsheets/testspreadsheet3';
import OrderDetails from './components/OrderDetails';
import CustomerLogin from './components/CustomerLogin';
import CustomerDashboard from './components/CustomerDashboard';
import CustomerProtectedRoute from './components/CustomerProtectedRoute';
import CustomerForgotPassword from './components/CustomerForgotPassword';
import CustomerResetPassword from './components/CustomerResetPassword';
import CustomerLayout from './components/layouts/CustomerLayout';
import PublicCustomerRoute from './components/PublicCustomerRoute';
import CustomerOrderForm from './components/CustomerOrderForm';
import CustomerOrderDetails from './components/CustomerOrderDetails';

// Hard code the frontend port if needed
const FRONTEND_PORT = 3000;

const theme = createTheme({
  palette: {
    mode: 'light', // Add this line
    primary: {
      main: '#1a2b3c',
    },
    secondary: {
      main: '#20b2aa',
    },
    background: {
      default: '#f5f7fa',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 > Date.now()) {
            const user = JSON.parse(localStorage.getItem('user'));
            setUser(user);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          handleLogout();
        }
      }
      setLoading(false);
    };

    checkToken();
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    logout();
    setUser(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <PrimeReactProvider>
        <CssBaseline />
        <Router>
          {user && !window.location.pathname.startsWith('/customer') && (
            <Navigation user={user} onLogout={handleLogout} />
          )}
          
          <Routes>
            <Route path="/customer/*" element={<CustomerLayout />}>
              <Route path="login" element={
                <PublicCustomerRoute>
                  <CustomerLogin />
                </PublicCustomerRoute>
              } />
              <Route path="forgot-password" element={
                <PublicCustomerRoute>
                  <CustomerForgotPassword />
                </PublicCustomerRoute>
              } />
              <Route path="reset-password" element={
                <PublicCustomerRoute>
                  <CustomerResetPassword />
                </PublicCustomerRoute>
              } />
              <Route path="dashboard" element={
                <CustomerProtectedRoute>
                  <CustomerDashboard />
                </CustomerProtectedRoute>
              } />
              <Route path="orders/create" element={
                <CustomerProtectedRoute>
                  <CustomerOrderForm />
                </CustomerProtectedRoute>
              } />
              <Route path="orders/:orderId" element={
                <CustomerProtectedRoute>
                  <CustomerOrderDetails />
                </CustomerProtectedRoute>
              } />
            </Route>

            <Route path="/login" element={user ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
            <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
            <Route path="/locations" element={user ? <Locations /> : <Navigate to="/login" />} />
            <Route path="/locations/:id" element={user ? <LocationDetails /> : <Navigate to="/login" />} />
            <Route path="/locations/:id/replenish-levels" element={user ? <ReplenishLevels /> : <Navigate to="/login" />} />
            <Route path="/settings" element={user && user.role === 'admin' ? <Settings /> : <Navigate to="/" />} />
            <Route path="/pos-management" element={user && user.role === 'admin' ? <POSManagement /> : <Navigate to="/" />} />
            <Route path="/user-management" element={user && user.role === 'admin' ? <UserManagement /> : <Navigate to="/" />} />
            <Route 
              path="/sales" 
              element={user && user.role === 'admin' ? <Sales /> : <Navigate to="/" />}
            />
            <Route path="/deleted-locations" element={<DeletedLocations />} />
            <Route 
              path="/optimize" 
              element={user ? <Optimize /> : <Navigate to="/login" />}
            />
            <Route 
              path="/transfer-orders"
              element={user && (user.role === 'admin' || user.role === 'manager') ? <TransferOrders /> : <Navigate to="/" />}
            />
            <Route path="/locations/:id/embedded-sheet/:sheetType" element={<EmbeddedSheet />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route 
              path="/item-forwarders" 
              element={user && user.role === 'admin' ? <ItemForwarders /> : <Navigate to="/" />}
            />
            <Route path="/customers" element={<CustomerManagement />} />
            <Route 
              path="/orders" 
              element={user ? <Orders /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/orders/create" 
              element={user ? <OrderForm /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/orders/:orderId/edit" 
              element={user ? <OrderForm /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/orders/:orderId/details" 
              element={user ? <OrderDetails /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/warehouses" 
              element={user ? <Warehouses /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/warehouses/:id" 
              element={user ? <WarehouseDetails /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/warehouses/:warehouseId/production/create" 
              element={user ? <ProductionEntry /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/warehouses/:warehouseId/production/:productionId/edit" 
              element={user ? <ProductionEntry /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/warehouses/:warehouseId/inventory-checks/create" 
              element={user ? <InventoryCheckForm /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/warehouses/:warehouseId/inventory-checks/:checkId/edit" 
              element={user ? <InventoryCheckForm /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/shipments" 
              element={user ? <Shipments /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/shipments/create" 
              element={user ? <CreateShipment /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/shipments/:id" 
              element={user ? <ShipmentDetails /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/testspreadsheet/1" 
              element={user ? <TestSpreadsheet /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/testspreadsheet/2" 
              element={user ? <TestSpreadsheet2 /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/testspreadsheet/3" 
              element={user ? <TestSpreadsheet3 /> : <Navigate to="/login" />} 
            />
          </Routes>
        </Router>
      </PrimeReactProvider>
    </ThemeProvider>
  );
}

export default App;
