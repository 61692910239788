import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkCustomerAuth } from '../services/customerapi';

function PublicCustomerRoute({ children }) {
  const isAuthenticated = checkCustomerAuth();
  
  if (isAuthenticated) {
    return <Navigate to="/customer/dashboard" replace />;
  }

  return children;
}

export default PublicCustomerRoute; 