import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  CircularProgress,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Grid,
  TablePagination,
  Tabs,
  Tab,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  VisibilityOff as VisibilityOffIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { styled as muiStyled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import SpreadsheetEditable from './spreadsheets/SpreadsheetEditable';
import {
  createShipment,
  getAllWarehouses,
  getLocations,
  getWarehouseInventory,
  getAvailableOrderItems,
  getShipmentSpreadsheetData,
} from '../services/api';
import { classNames } from 'primereact/utils';
import styled from '@emotion/styled';
import SpreadsheetOrderView from './spreadsheets/SpreadsheetOrderView';

const StyledContainer = muiStyled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = muiStyled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledSelect = styled('select')`
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const ControlGroup = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const SpreadsheetWrapper = styled('div')({
  padding: '1rem',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  width: '100%',
  overflow: 'hidden',
});

const ControlsPanel = styled('div')({
  display: 'flex',
  gap: '1rem',
  padding: '1rem',
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  marginBottom: '1rem',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const SpreadsheetTable = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  '& th, & td': {
    border: '1px solid #ddd',
    padding: '8px',
    position: 'relative',
    textAlign: 'center',
    minWidth: '80px',
  },
  '& th': {
    backgroundColor: '#f5f5f5',
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    zIndex: 1,
    borderBottom: '2px solid #333',
  },
  '& tr:first-child th': {
    borderTop: '2px solid #333',
    borderBottom: '2px solid #333',
    borderLeft: '1px solid #ddd',
    borderRight: '1px solid #ddd',
    '&:first-child': {
      borderLeft: '2px solid #333',
    },
    '&:last-child': {
      borderRight: '2px solid #333',
    },
  },
  '& tr:nth-child(2) th': {
    borderBottom: '2px solid #333',
    borderLeft: '1px solid #ddd',
    borderRight: '1px solid #ddd',
    '&:first-child': {
      borderLeft: '2px solid #333',
    },
    '&:last-child': {
      borderRight: '2px solid #333',
    },
  },
  '& td': {
    backgroundColor: '#fff',
    borderLeft: '1px solid #ddd',
    borderRight: '1px solid #ddd',
    borderBottom: '1px solid #ddd',
    '&:hover': {
      backgroundColor: '#f8f8f8',
    },
    '&.empty': {
      backgroundColor: '#fafafa',
    },
    '&.insufficient': {
      color: '#d32f2f',
      fontWeight: 'bold',
    },
  },
  '& .sticky-corner': {
    position: 'sticky',
    left: 0,
    zIndex: 2,
    backgroundColor: '#e6e6e6',
    borderRight: '2px solid #333',
    borderBottom: '2px solid #333',
    borderLeft: '2px solid #333',
  },
  '& th[colspan]': {
    borderLeft: '2px solid #333',
    borderRight: '2px solid #333',
    borderTop: '2px solid #333',
    fontWeight: 'bold',
    backgroundColor: '#e6e6e6',
  },
  '& tbody th': {
    position: 'sticky',
    left: 0,
    backgroundColor: '#f5f5f5',
    zIndex: 1,
    borderRight: '2px solid #333',
    borderLeft: '2px solid #333',
    borderBottom: '1px solid #ddd',
  },
  '& tbody tr': {
    '&:last-child': {
      '& td, & th': {
        borderBottom: '2px solid #333',
      },
    },
  },
  '& td.brim-group-start': {
    borderLeft: '2px solid #333',
  },
  '& td.brim-group-end': {
    borderRight: '2px solid #333',
  },
  '& input': {
    width: '100%',
    padding: '4px',
    border: 'none',
    background: 'transparent',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
      backgroundColor: '#f0f7ff',
    },
  },
});

const ToggleButton = styled('button')`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  
  &:hover {
    background-color: #f8f9fa;
  }
`;

const OrderItemsList = styled('div')({
  marginTop: '1rem',
  '& .MuiAccordion-root': {
    marginBottom: '1rem',
  },
});

const FullPageContainer = muiStyled('div')({
  height: '100vh',
  width: '100vw',
  position: 'fixed',
  top: 0,
  left: 0,
  backgroundColor: '#f5f5f5',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column'
});

const ShipmentSummary = muiStyled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: '#fff',
  borderBottom: '1px solid #e0e0e0',
}));

const SplitViewContainer = muiStyled('div')({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
});

const OrderListPanel = muiStyled(Paper)(({ theme }) => ({
  width: '30%',
  height: '100%',
  overflow: 'auto',
  borderRight: '1px solid #e0e0e0',
  backgroundColor: '#fff',
}));

const OrderItemsPanel = ({ 
  selectedOrderId,
  loadingSpreadsheet,
  selectedOrderSpreadsheet,
  handleSpreadsheetChange,
  selectedItems,
  handleItemSelect,
  handleQuantityChange,
  getSelectedOrderItems,
  isItemSelected,
  getItemQuantity,
  handleSelectAllItems,
  isOrderFullySelected,
  isOrderPartiallySelected
}) => {
  const [viewMode, setViewMode] = useState('list');
  
  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Tabs
        value={viewMode}
        onChange={(e, newValue) => setViewMode(newValue)}
        sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
      >
        <Tab value="list" label="List View" />
        <Tab value="spreadsheet" label="Spreadsheet View" />
      </Tabs>

      {viewMode === 'list' ? (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isOrderFullySelected(selectedOrderId)}
                    indeterminate={isOrderPartiallySelected(selectedOrderId)}
                    onChange={(e) => handleSelectAllItems(selectedOrderId, e.target.checked)}
                  />
                </TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Details</TableCell>
                <TableCell align="right">Ordered</TableCell>
                <TableCell align="right">Available</TableCell>
                <TableCell align="right">Quantity to Ship</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getSelectedOrderItems().map(item => (
                <TableRow key={item.order_item_id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isItemSelected(item.order_item_id)}
                      onChange={(e) => handleItemSelect(item, e.target.checked)}
                    />
                  </TableCell>
                  <TableCell>{item.item_name}</TableCell>
                  <TableCell>
                    {item.item_type} - {item.size} - {item.shape}
                  </TableCell>
                  <TableCell align="right">{item.ordered_quantity}</TableCell>
                  <TableCell align="right">{item.available_quantity}</TableCell>
                  <TableCell align="right">
                    <TextField
                      type="number"
                      size="small"
                      value={getItemQuantity(item.order_item_id)}
                      onChange={(e) => handleQuantityChange(
                        item.order_item_id,
                        e.target.value
                      )}
                      disabled={!isItemSelected(item.order_item_id)}
                      inputProps={{
                        min: 1,
                        max: item.shippable_quantity,
                        style: { textAlign: 'right' }
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box flex={1} overflow="auto">
          {selectedOrderId && !loadingSpreadsheet ? (
            <SpreadsheetOrderView
              data={selectedOrderSpreadsheet}
              onCellValueChanged={handleSpreadsheetChange}
            />
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" p={3}>
              {loadingSpreadsheet ? (
                <CircularProgress />
              ) : (
                <Typography color="textSecondary">
                  Select an order to view spreadsheet
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

const OrderListItem = muiStyled(ListItem)(({ theme, selected }) => ({
  borderBottom: '1px solid #e0e0e0',
  backgroundColor: selected ? theme.palette.action.selected : 'inherit',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const InventoryTable = muiStyled(TableContainer)(({ theme }) => ({
  maxHeight: '70vh',
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.background.paper,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  }
}));

function CreateShipment() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [locations, setLocations] = useState([]);
  const [sourceInventory, setSourceInventory] = useState([]);
  const [addItemDialogOpen, setAddItemDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemQuantity, setItemQuantity] = useState('');
  const [availableQuantities, setAvailableQuantities] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [detailsValid, setDetailsValid] = useState(false);
  const [availableOrders, setAvailableOrders] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [selectedOrderSpreadsheet, setSelectedOrderSpreadsheet] = useState(null);
  const [loadingSpreadsheet, setLoadingSpreadsheet] = useState(false);

  const [formData, setFormData] = useState({
    source_warehouse_id: '',
    destination_type: 'warehouse',
    destination_warehouse_id: '',
    destination_location_id: '',
    shipping_method: 'air',
    shipment_type: 'warehouse_delivery',
    notes: '',
    special_instructions: '',
    items: [],
  });

  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    const isValid = formData.source_warehouse_id && 
      ((formData.destination_type === 'warehouse' && formData.destination_warehouse_id) ||
       (formData.destination_type === 'location' && formData.destination_location_id)) &&
      formData.shipping_method &&
      formData.shipment_type;
    
    setDetailsValid(isValid);
  }, [formData]);

  useEffect(() => {
    if (formData.source_warehouse_id) {
      fetchAvailableOrders(formData.source_warehouse_id);
    }
  }, [formData.source_warehouse_id]);

  useEffect(() => {
    if (selectedOrderId && formData.source_warehouse_id) {
      fetchSpreadsheetData(selectedOrderId);
    }
  }, [selectedOrderId, formData.source_warehouse_id, selectedItems]);

  const fetchInitialData = async () => {
    try {
      const [warehousesData, locationsData] = await Promise.all([
        getAllWarehouses(),
        getLocations(),
      ]);
      setWarehouses(warehousesData);
      setLocations(locationsData);
    } catch (error) {
      setError('Failed to fetch initial data');
      console.error('Error fetching initial data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSourceInventory = async (warehouseId) => {
    try {
      const inventory = await getWarehouseInventory(warehouseId);
      setSourceInventory(inventory);
      
      const quantityMap = inventory.reduce((acc, item) => {
        acc[item.warehouse_item_id] = item.quantity;
        return acc;
      }, {});
      setAvailableQuantities(quantityMap);
    } catch (error) {
      setError('Failed to fetch warehouse inventory');
      console.error('Error fetching warehouse inventory:', error);
    }
  };

  const fetchAvailableOrders = async (warehouseId) => {
    setLoadingOrders(true);
    try {
      const orders = await getAvailableOrderItems(warehouseId);
      setAvailableOrders(orders);
    } catch (error) {
      setError('Failed to fetch available orders');
      console.error('Error fetching available orders:', error);
    } finally {
      setLoadingOrders(false);
    }
  };

  const fetchSpreadsheetData = async (orderId) => {
    setLoadingSpreadsheet(true);
    try {
      const response = await getShipmentSpreadsheetData(
        formData.source_warehouse_id,
        orderId,
        selectedItems.map(item => ({
          warehouse_item_id: item.warehouse_item_id,
          quantity: item.quantity
        }))
      );
      setSpreadsheetData(prev => ({
        ...prev,
        [orderId]: response
      }));
      setSelectedOrderSpreadsheet(response);
    } catch (error) {
      setError('Failed to fetch spreadsheet data');
      console.error('Error fetching spreadsheet data:', error);
    } finally {
      setLoadingSpreadsheet(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    if (name === 'source_warehouse_id' && value) {
      fetchSourceInventory(value);
    }
  };

  const handleAddItem = () => {
    if (!selectedItem || !itemQuantity || itemQuantity <= 0) return;

    setFormData(prev => ({
      ...prev,
      items: [
        ...prev.items,
        {
          warehouse_item_id: selectedItem.warehouse_item_id,
          quantity: parseInt(itemQuantity),
          item_name: selectedItem.item_name,
          item_details: `${selectedItem.item_type} - ${selectedItem.size} - ${selectedItem.shape}`
        }
      ]
    }));

    setSelectedItem(null);
    setItemQuantity('');
    setAddItemDialogOpen(false);
  };

  const handleRemoveItem = (index) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleItemSelect = (item, checked) => {
    if (checked) {
      setSelectedItems(prev => [...prev, {
        warehouse_item_id: item.warehouse_item_id,
        order_item_id: item.order_item_id,
        quantity: item.shippable_quantity,
        is_part_of_order: true,
        item_name: item.item_name,
        item_details: `${item.item_type} - ${item.size} - ${item.shape}`
      }]);
    } else {
      setSelectedItems(prev => prev.filter(i => i.order_item_id !== item.order_item_id));
    }
  };

  const handleQuantityChange = (orderItemId, newQuantity) => {
    setSelectedItems(prev => prev.map(item => {
      if (item.order_item_id === orderItemId) {
        return { ...item, quantity: parseInt(newQuantity) || 0 };
      }
      return item;
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const shipmentData = {
        ...formData,
        source_warehouse_id: parseInt(formData.source_warehouse_id),
        destination_warehouse_id: formData.destination_type === 'warehouse' ? 
          parseInt(formData.destination_warehouse_id) : null,
        destination_location_id: formData.destination_type === 'location' ? 
          parseInt(formData.destination_location_id) : null,
        shipment_items: [...selectedItems, ...formData.items]
          .filter(item => item.quantity > 0)
          .map(item => ({
            warehouse_item_id: parseInt(item.warehouse_item_id),
            order_item_id: item.order_item_id ? parseInt(item.order_item_id) : null,
            quantity: parseInt(item.quantity),
            is_part_of_order: Boolean(item.is_part_of_order)
          }))
      };

      await createShipment(shipmentData);
      navigate('/shipments');
    } catch (error) {
      setError(error.response?.data?.error || 'Failed to create shipment');
      console.error('Error creating shipment:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getSelectedOrderItems = () => {
    return availableOrders.find(order => order.order_id === selectedOrderId)?.items || [];
  };

  const handleSpreadsheetChange = useCallback((changes) => {
    if (!selectedOrderId) return;
    
    const updatedItems = [...selectedItems];
    
    changes.forEach(change => {
      const { data: cellData, newValue } = change;
      const { warehouse_item_id, order_item_id } = cellData.metadata || {};
      
      if (warehouse_item_id && order_item_id) {
        const existingItemIndex = updatedItems.findIndex(i => 
          i.warehouse_item_id === warehouse_item_id && 
          i.order_item_id === order_item_id
        );
        
        if (existingItemIndex >= 0) {
          if (newValue > 0) {
            updatedItems[existingItemIndex].quantity = parseInt(newValue);
          } else {
            updatedItems.splice(existingItemIndex, 1);
          }
        } else if (newValue > 0) {
          updatedItems.push({
            warehouse_item_id,
            order_item_id,
            quantity: parseInt(newValue),
            is_part_of_order: true
          });
        }
      }
    });
    
    setSelectedItems(updatedItems);
  }, [selectedOrderId, selectedItems]);

  const ShipmentDetailsForm = () => (
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Source and Destination
      </Typography>
      <Box display="grid" gap={3} gridTemplateColumns="repeat(2, 1fr)">
        <TextField
          select
          label="Source Warehouse"
          name="source_warehouse_id"
          id="source-warehouse-id"
          value={formData.source_warehouse_id}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            htmlFor: "source-warehouse-id"
          }}
        >
          {warehouses.map((warehouse) => (
            <MenuItem key={warehouse.warehouse_id} value={warehouse.warehouse_id}>
              {warehouse.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label="Destination Type"
          name="destination_type"
          id="destination-type"
          value={formData.destination_type}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            htmlFor: "destination-type"
          }}
        >
          <MenuItem value="warehouse">Warehouse</MenuItem>
          <MenuItem value="location">Location</MenuItem>
        </TextField>

        {formData.destination_type === 'warehouse' ? (
          <TextField
            select
            label="Destination Warehouse"
            name="destination_warehouse_id"
            id="destination-warehouse-id"
            value={formData.destination_warehouse_id}
            onChange={handleChange}
            required
            fullWidth
            InputLabelProps={{
              htmlFor: "destination-warehouse-id"
            }}
          >
            {warehouses
              .filter(w => w.warehouse_id !== formData.source_warehouse_id)
              .map((warehouse) => (
                <MenuItem key={warehouse.warehouse_id} value={warehouse.warehouse_id}>
                  {warehouse.name}
                </MenuItem>
              ))}
          </TextField>
        ) : (
          <TextField
            select
            label="Destination Location"
            name="destination_location_id"
            id="destination-location-id"
            value={formData.destination_location_id}
            onChange={handleChange}
            required
            fullWidth
            InputLabelProps={{
              htmlFor: "destination-location-id"
            }}
          >
            {locations.map((location) => (
              <MenuItem key={location.db_location_id} value={location.db_location_id}>
                {location.name}
              </MenuItem>
            ))}
          </TextField>
        )}

        <TextField
          select
          label="Shipping Method"
          name="shipping_method"
          id="shipping-method"
          value={formData.shipping_method}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            htmlFor: "shipping-method"
          }}
        >
          <MenuItem value="air">Air</MenuItem>
          <MenuItem value="sea">Sea</MenuItem>
        </TextField>

        <TextField
          select
          label="Shipment Type"
          name="shipment_type"
          id="shipment-type"
          value={formData.shipment_type}
          onChange={handleChange}
          required
          fullWidth
          InputLabelProps={{
            htmlFor: "shipment-type"
          }}
        >
          <MenuItem value="customer_delivery">Customer Delivery</MenuItem>
          <MenuItem value="warehouse_delivery">Warehouse Delivery</MenuItem>
          <MenuItem value="general_delivery">General Delivery</MenuItem>
        </TextField>
      </Box>
      
      <Box mt={3}>
        <Typography variant="h6" gutterBottom>
          Additional Information
        </Typography>
        <Box display="grid" gap={3}>
          <TextField
            multiline
            rows={4}
            label="Notes"
            name="notes"
            id="notes"
            value={formData.notes}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              htmlFor: "notes"
            }}
            inputProps={{
              style: { resize: 'vertical' }
            }}
          />
          <TextField
            multiline
            rows={4}
            label="Special Instructions"
            name="special_instructions"
            id="special-instructions"
            value={formData.special_instructions}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              htmlFor: "special-instructions"
            }}
            inputProps={{
              style: { resize: 'vertical' }
            }}
          />
        </Box>
      </Box>
    </StyledPaper>
  );

  const ItemSelectionForm = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({
      key: 'quantity',
      direction: 'desc'
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [filters, setFilters] = useState({
      type: '',
      size: '',
      shape: ''
    });

    const filterOptions = useMemo(() => {
      return {
        types: [...new Set(sourceInventory.map(item => item.item_type))],
        sizes: [...new Set(sourceInventory.map(item => item.size))],
        shapes: [...new Set(sourceInventory.map(item => item.shape))]
      };
    }, [sourceInventory]);

    const filteredInventory = useMemo(() => {
      let items = [...sourceInventory];

      if (filters.type) {
        items = items.filter(item => item.item_type === filters.type);
      }
      if (filters.size) {
        items = items.filter(item => item.size === filters.size);
      }
      if (filters.shape) {
        items = items.filter(item => item.shape === filters.shape);
      }

      if (searchTerm) {
        items = items.filter(item => 
          item.item_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.item_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.size.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.shape.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      items.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
        
        if (sortConfig.key === 'quantity') {
          aValue = availableQuantities[a.warehouse_item_id] || 0;
          bValue = availableQuantities[b.warehouse_item_id] || 0;
        }
        
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });

      return items;
    }, [sourceInventory, searchTerm, sortConfig, filters, availableQuantities]);

    const paginatedInventory = useMemo(() => {
      const start = page * rowsPerPage;
      return filteredInventory.slice(start, start + rowsPerPage);
    }, [filteredInventory, page, rowsPerPage]);

    const handleSort = (key) => {
      setSortConfig(prev => ({
        key,
        direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
      }));
    };

    const handleFilterChange = (filterName, value) => {
      setFilters(prev => ({
        ...prev,
        [filterName]: value
      }));
      setPage(0);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const getReservedQuantity = (itemId) => {
      return selectedItems.reduce((sum, item) => {
        if (item.warehouse_item_id === itemId) {
          return sum + item.quantity;
        }
        return sum;
      }, 0);
    };

    const handleQuantityChange = (item, value) => {
      const numericValue = value.replace(/[^0-9]/g, '');
      const quantity = parseInt(numericValue) || 0;
      const reservedQty = getReservedQuantity(item.warehouse_item_id);
      const availableQty = availableQuantities[item.warehouse_item_id] || 0;
      
      if (quantity + reservedQty > availableQty) {
        setError(`Cannot exceed available quantity (${availableQty - reservedQty} remaining)`);
        return;
      }

      setFormData(prev => {
        const existingItemIndex = prev.items.findIndex(i => 
          i.warehouse_item_id === item.warehouse_item_id
        );

        if (existingItemIndex >= 0) {
          const updatedItems = [...prev.items];
          updatedItems[existingItemIndex] = {
            ...updatedItems[existingItemIndex],
            quantity
          };
          return { ...prev, items: updatedItems };
        } else if (quantity > 0) {
          return {
            ...prev,
            items: [
              ...prev.items,
              {
                warehouse_item_id: item.warehouse_item_id,
                quantity,
                item_name: item.item_name,
                item_details: `${item.item_type} - ${item.size} - ${item.shape}`
              }
            ]
          };
        }
        return prev;
      });
    };

    const getAdditionalQuantity = (itemId) => {
      const item = formData.items.find(i => i.warehouse_item_id === itemId);
      return item ? item.quantity : '';
    };

    return (
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Warehouse Inventory
        </Typography>

        <Box mb={2} display="flex" gap={2} flexWrap="wrap">
          <TextField
            label="Search Items"
            id="search-items"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(0);
            }}
            sx={{ minWidth: 200 }}
            InputLabelProps={{
              htmlFor: "search-items"
            }}
          />

          <TextField
            select
            label="Type"
            id="filter-type"
            value={filters.type}
            onChange={(e) => handleFilterChange('type', e.target.value)}
            size="small"
            sx={{ minWidth: 150 }}
            InputLabelProps={{
              htmlFor: "filter-type"
            }}
          >
            <MenuItem value="">All Types</MenuItem>
            {filterOptions.types.map(type => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Size"
            id="filter-size"
            value={filters.size}
            onChange={(e) => handleFilterChange('size', e.target.value)}
            size="small"
            sx={{ minWidth: 150 }}
            InputLabelProps={{
              htmlFor: "filter-size"
            }}
          >
            <MenuItem value="">All Sizes</MenuItem>
            {filterOptions.sizes.map(size => (
              <MenuItem key={size} value={size}>{size}</MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Shape"
            id="filter-shape"
            value={filters.shape}
            onChange={(e) => handleFilterChange('shape', e.target.value)}
            size="small"
            sx={{ minWidth: 150 }}
            InputLabelProps={{
              htmlFor: "filter-shape"
            }}
          >
            <MenuItem value="">All Shapes</MenuItem>
            {filterOptions.shapes.map(shape => (
              <MenuItem key={shape} value={shape}>{shape}</MenuItem>
            ))}
          </TextField>
        </Box>

        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell 
                  onClick={() => handleSort('item_name')} 
                  style={{ cursor: 'pointer' }}
                >
                  Item Name {sortConfig.key === 'item_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </TableCell>
                <TableCell 
                  onClick={() => handleSort('quantity')} 
                  style={{ cursor: 'pointer' }}
                >
                  Available {sortConfig.key === 'quantity' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </TableCell>
                <TableCell>Details</TableCell>
                <TableCell align="right">Remaining</TableCell>
                <TableCell align="right">Quantity to Add</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedInventory.map((item) => {
                const reservedQty = getReservedQuantity(item.warehouse_item_id);
                const availableQty = availableQuantities[item.warehouse_item_id] || 0;
                const remainingQty = availableQty - reservedQty;

                return (
                  <TableRow key={item.warehouse_item_id}>
                    <TableCell>{item.item_name}</TableCell>
                    <TableCell>{availableQty}</TableCell>
                    <TableCell>{item.size} - {item.shape}</TableCell>
                    <TableCell align="right">{remainingQty}</TableCell>
                    <TableCell align="right">
                      <TextField
                        type="number"
                        size="small"
                        value={getAdditionalQuantity(item.warehouse_item_id)}
                        onChange={(e) => handleQuantityChange(item, e.target.value)}
                        inputProps={{
                          min: 0,
                          max: remainingQty,
                          style: { textAlign: 'right' }
                        }}
                        disabled={remainingQty <= 0}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={filteredInventory.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </StyledPaper>
    );
  };

  const OrderItemSelectionForm = () => (
    <StyledPaper>
      <Typography variant="h6" gutterBottom>
        Select Order Items
      </Typography>

      {loadingOrders ? (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      ) : availableOrders.length === 0 ? (
        <Typography color="textSecondary" align="center" py={3}>
          No pending orders available for this warehouse
        </Typography>
      ) : (
        <OrderItemsList>
          {availableOrders.map(order => (
            <Accordion key={order.order_id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Order #{order.order_number} - {order.customer_name}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {order.items.map(item => {
                    const isSelected = selectedItems.some(
                      si => si.order_item_id === item.order_item_id
                    );
                    const selectedItem = selectedItems.find(
                      si => si.order_item_id === item.order_item_id
                    );

                    return (
                      <ListItem key={item.order_item_id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isSelected}
                              onChange={(e) => handleItemSelect(item, e.target.checked)}
                            />
                          }
                          label={
                            <Box>
                              <Typography variant="subtitle1">
                                {item.item_name}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {item.item_type} - {item.size} - {item.shape}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                Ordered: {item.ordered_quantity} | 
                                Shipped: {item.shipped_quantity} | 
                                Remaining: {item.remaining_quantity} |
                                Available: {item.available_quantity}
                              </Typography>
                            </Box>
                          }
                        />
                        {isSelected && (
                          <ListItemSecondaryAction>
                            <TextField
                              type="number"
                              label="Quantity"
                              value={selectedItem.quantity}
                              onChange={(e) => handleQuantityChange(
                                item.order_item_id,
                                e.target.value
                              )}
                              inputProps={{
                                min: 1,
                                max: item.shippable_quantity
                              }}
                              error={selectedItem.quantity > item.shippable_quantity}
                              helperText={
                                selectedItem.quantity > item.shippable_quantity
                                  ? "Exceeds available quantity"
                                  : ""
                              }
                              size="small"
                            />
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </OrderItemsList>
      )}
    </StyledPaper>
  );

  const isItemSelected = (orderItemId) => {
    return selectedItems.some(item => item.order_item_id === orderItemId);
  };

  const getItemQuantity = (orderItemId) => {
    const item = selectedItems.find(item => item.order_item_id === orderItemId);
    return item ? item.quantity : '';
  };

  const isOrderFullySelected = useCallback((orderId) => {
    const orderItems = availableOrders.find(order => order.order_id === orderId)?.items || [];
    return orderItems.length > 0 && 
      orderItems.every(item => isItemSelected(item.order_item_id));
  }, [availableOrders, isItemSelected]);

  const isOrderPartiallySelected = useCallback((orderId) => {
    const orderItems = availableOrders.find(order => order.order_id === orderId)?.items || [];
    const selectedCount = orderItems.filter(item => isItemSelected(item.order_item_id)).length;
    return selectedCount > 0 && selectedCount < orderItems.length;
  }, [availableOrders, isItemSelected]);

  const handleSelectAllItems = useCallback((orderId, checked = true) => {
    const orderItems = availableOrders.find(order => order.order_id === orderId)?.items || [];
    
    if (checked) {
      const newItems = orderItems.map(item => ({
        warehouse_item_id: item.warehouse_item_id,
        order_item_id: item.order_item_id,
        quantity: item.shippable_quantity,
        is_part_of_order: true,
        item_name: item.item_name,
        item_details: `${item.item_type} - ${item.size} - ${item.shape}`
      }));
      
      setSelectedItems(prev => {
        const filtered = prev.filter(item => 
          !orderItems.some(oi => oi.order_item_id === item.order_item_id)
        );
        return [...filtered, ...newItems];
      });
    } else {
      setSelectedItems(prev => 
        prev.filter(item => 
          !orderItems.some(oi => oi.order_item_id === item.order_item_id)
        )
      );
    }
  }, [availableOrders, setSelectedItems]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <FullPageContainer>
      <Box p={2}>
        <Typography variant="h4" gutterBottom>
          Create Shipment
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
          <Step>
            <StepLabel>Shipment Details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Select Order Items</StepLabel>
          </Step>
          <Step>
            <StepLabel>Additional Items</StepLabel>
          </Step>
        </Stepper>
      </Box>

      {activeStep === 0 ? (
        <Box p={2} sx={{ overflow: 'auto' }}>
          <ShipmentDetailsForm />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={!detailsValid}
            >
              Next
            </Button>
          </Box>
        </Box>
      ) : activeStep === 1 ? (
        <>
          <ShipmentSummary>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">
                  Source: {warehouses.find(w => w.warehouse_id === formData.source_warehouse_id)?.name}
                </Typography>
                <Typography variant="subtitle1">
                  Destination: {
                    formData.destination_type === 'warehouse' 
                      ? warehouses.find(w => w.warehouse_id === formData.destination_warehouse_id)?.name
                      : locations.find(l => l.db_location_id === formData.destination_location_id)?.name
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">
                  Shipping Method: {formData.shipping_method.toUpperCase()}
                </Typography>
                <Typography variant="subtitle1">
                  Type: {formData.shipment_type.replace('_', ' ').toUpperCase()}
                </Typography>
              </Grid>
              {selectedItems.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Selected Items: {selectedItems.length} (Total Quantity: {
                      selectedItems.reduce((sum, item) => sum + item.quantity, 0)
                    })
                  </Typography>
                </Grid>
              )}
            </Grid>
          </ShipmentSummary>

          <SplitViewContainer>
            <OrderListPanel>
              <List>
                {availableOrders.map(order => (
                  <OrderListItem
                    key={order.order_id}
                    selected={selectedOrderId === order.order_id}
                    onClick={() => setSelectedOrderId(order.order_id)}
                    button
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          Order #{order.order_number}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2">
                            Customer: {order.customer_name}
                          </Typography>
                          <Typography variant="body2">
                            Items: {order.items.length}
                          </Typography>
                        </>
                      }
                    />
                  </OrderListItem>
                ))}
              </List>
            </OrderListPanel>

            <Box component={Paper} sx={{ width: '70%', height: '100%', overflow: 'auto' }}>
              <OrderItemsPanel
                selectedOrderId={selectedOrderId}
                loadingSpreadsheet={loadingSpreadsheet}
                selectedOrderSpreadsheet={selectedOrderSpreadsheet}
                handleSpreadsheetChange={handleSpreadsheetChange}
                selectedItems={selectedItems}
                handleItemSelect={handleItemSelect}
                handleQuantityChange={handleQuantityChange}
                getSelectedOrderItems={getSelectedOrderItems}
                isItemSelected={isItemSelected}
                getItemQuantity={getItemQuantity}
                handleSelectAllItems={handleSelectAllItems}
                isOrderFullySelected={isOrderFullySelected}
                isOrderPartiallySelected={isOrderPartiallySelected}
              />
            </Box>
          </SplitViewContainer>

          <Box display="flex" justifyContent="space-between" p={2}>
            <Button onClick={handleBack}>
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleNext}
            >
              Next
            </Button>
          </Box>
        </>
      ) : (
        <Box p={2} sx={{ overflow: 'auto' }}>
          <ItemSelectionForm />
          <Box display="flex" justifyContent="space-between" mt={2}>
            <Button onClick={handleBack}>
              Back
            </Button>
            <Box display="flex" gap={2}>
              <Button
                variant="outlined"
                onClick={() => navigate('/shipments')}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting || (formData.items.length === 0 && selectedItems.length === 0)}
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <>
                    <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />
                    Creating Shipment...
                  </>
                ) : (
                  'Create Shipment'
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </FullPageContainer>
  );
}

export default CreateShipment; 