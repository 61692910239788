import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getLocationById,
  deleteLocation,
  updateLocationType,
  generateOrderSheet,
  generateReplenishSheet,
  generateInventorySheet,
  getTransferOrders,
  updateInventoryForLocation,
  getInventoryUpdateStatus,
} from '../services/api';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Box,
  Divider,
  CircularProgress,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Snackbar,
  Alert,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,  // Add this line
  DialogActions,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LinkIcon from '@mui/icons-material/Link';
import { styled, keyframes } from '@mui/material/styles';
import logger from '../utils/logger';
import ReactECharts from 'echarts-for-react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RefreshIcon from '@mui/icons-material/Refresh';

// Updated styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#ffffff',
  borderRadius: 8,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
  padding: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#2c3e50', // Dark blue-gray
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 700,
  fontSize: '2rem',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  color: '#34495e', // Darker blue-gray
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 600,
  fontSize: '1.25rem',
}));

const SheetLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: theme.spacing(1),
}));

const InventoryCategory = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  color: '#2c3e50', // Dark blue-gray
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    backgroundColor: '#34495e', // Darker blue-gray
    color: '#ffffff',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  fontWeight: 600,
}));

const SheetButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  color: '#34495e',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: 'calc(100% - 100px)', // Adjust width to make space for the "Get New" button
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

const GetNewButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  minWidth: '80px',
}));

const EmbedButton = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  padding: '4px',
}));

const loadingDotsAnimation = keyframes`
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60%, 100% {
    content: '...';
  }
`;

const LoadingDots = styled('span')({
  '&::after': {
    content: '\'\'',
    animation: `${loadingDotsAnimation} 1.5s infinite`,
  },
});

function LocationDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [chartType, setChartType] = useState('bar');
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [locationType, setLocationType] = useState('');
  const [editingLocationType, setEditingLocationType] = useState(false);
  const [generatingSheet, setGeneratingSheet] = useState({});
  const [generatingAllSheets, setGeneratingAllSheets] = useState(false);
  const [sheetProgress, setSheetProgress] = useState({
    inventory: 0,
    replenish: 0,
    order: 0,
    all: 0,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [sheetNotificationOpen, setSheetNotificationOpen] = useState(false);
  const [sheetNotificationMessage, setSheetNotificationMessage] = useState('');
  const [sheetNotificationAction, setSheetNotificationAction] = useState(null);
  const [sheetGenerationTimes, setSheetGenerationTimes] = useState({
    inventory: 5000,
    replenish: 7000,
    order: 6000,
    all: 15000,
  });
  const [transferOrders, setTransferOrders] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [inventoryChanges, setInventoryChanges] = useState(null);
  const [inventoryUpdateStatus, setInventoryUpdateStatus] = useState(null);
  const [inventoryUpdating, setInventoryUpdating] = useState(false);

  useEffect(() => {
    fetchLocationDetails();
    fetchTransferOrders();
  }, [id]);

  const fetchLocationDetails = async () => {
    setLoading(true);
    setError(null);

    try {
      const data = await getLocationById(id);
      setLocation(data);
      setLocationType(data.locationType || '');
    } catch (err) {
      console.error('Error fetching location details:', err);
      setError('Failed to fetch location details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateReplenishLevels = () => {
    navigate(`/locations/${id}/replenish-levels`);
  };

  const getChartOption = () => {
    if (!location.inventoryTypes) return {};
    
    const categories = location.replenishTypes || ['G', 'M', 'G2', 'M2'];
    const inventoryData = categories.map(
      (category) => location.InventoryByCategory?.[category] || 0
    );
    const replenishData = categories.map(
      (category) => location.ReplenishLevels?.[category] || 0
    );

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        data: ['Current Inventory', 'Replenish Level'],
      },
      xAxis: {
        type: 'category',
        data: categories,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'Current Inventory',
          type: chartType,
          data: inventoryData,
          itemStyle: {
            color: '#3498db',
          },
        },
        {
          name: 'Replenish Level',
          type: chartType,
          data: replenishData,
          itemStyle: {
            color: '#34495e',
          },
        },
      ],
      toolbox: {
        feature: {
          saveAsImage: { show: true, title: 'Save as Image' },
        },
      },
    };
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
    handleMenuClose();
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteLocation(id);
      navigate('/locations');
      setSnackbarMessage('Location deleted successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting location:', err);
      setError('Failed to delete location. Please try again.');
      setSnackbarMessage('Failed to delete location. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
    setDeleteConfirmOpen(false);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleLocationTypeChange = (event) => {
    setLocationType(event.target.value);
  };

  const saveLocationType = async () => {
    try {
      const result = await updateLocationType(id, locationType);
      setEditingLocationType(false);
      setSnackbarMessage(result.message);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      // Update the location state with the new type
      setLocation((prevLocation) => ({
        ...prevLocation,
        locationType: result.location.location_type,
      }));
    } catch (err) {
      console.error('Error updating location type:', err);
      setError('Failed to update location type. Please try again.');
      setSnackbarMessage('Failed to update location type. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleGenerateSheet = async (sheetType) => {
    setGeneratingSheet((prev) => ({ ...prev, [sheetType]: true }));
    setMessage(null);
    setError(null);
    setSheetProgress((prev) => ({ ...prev, [sheetType]: 0 }));

    const startTime = Date.now();
    let progressInterval;

    try {
      // Start progress bar animation
      progressInterval = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const progress = Math.min((elapsed / sheetGenerationTimes[sheetType]) * 100, 99);
        setSheetProgress((prev) => ({ ...prev, [sheetType]: progress }));
      }, 100);

      let newSheetUrl;
      switch (sheetType) {
        case 'inventory':
          newSheetUrl = await generateInventorySheet(id, true);
          break;
        case 'replenish':
          newSheetUrl = await generateReplenishSheet(id, true);
          break;
        case 'order':
          newSheetUrl = await generateOrderSheet(id, true);
          break;
        default:
          throw new Error('Invalid sheet type');
      }

      // Update the location state with the new sheet URL
      setLocation((prevLocation) => ({
        ...prevLocation,
        [`${sheetType.charAt(0).toUpperCase() + sheetType.slice(1)}Sheet`]: newSheetUrl,
      }));

      // Set progress to 100%
      setSheetProgress((prev) => ({ ...prev, [sheetType]: 100 }));

      // Update average generation time
      const generationTime = Date.now() - startTime;
      setSheetGenerationTimes((prev) => ({
        ...prev,
        [sheetType]: (prev[sheetType] + generationTime) / 2,
      }));

      // Show notification with the correct link
      setSheetNotificationMessage(
        `${sheetType.charAt(0).toUpperCase() + sheetType.slice(1)} sheet generated successfully!`
      );
      setSheetNotificationAction(() => () => window.open(newSheetUrl, '_blank'));
      setSheetNotificationOpen(true);
      // Auto-close after 10 seconds
      setTimeout(() => setSheetNotificationOpen(false), 10000);
    } catch (err) {
      console.error(`Error generating ${sheetType} sheet:`, err);
      setError(
        `Failed to generate ${sheetType} sheet. ${
          err.response ? err.response.data : err.message
        }`
      );
      setSnackbarMessage(
        `Failed to generate ${sheetType} sheet. ${
          err.response ? err.response.data : err.message
        }`
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      clearInterval(progressInterval);
      setGeneratingSheet((prev) => ({ ...prev, [sheetType]: false }));
    }
  };

  const handleReloadAllSheets = async () => {
    setGeneratingAllSheets(true);
    setMessage(null);
    setError(null);
    setSheetProgress((prev) => ({ ...prev, all: 0 }));

    const startTime = Date.now();
    let progressInterval;

    try {
      // Start progress bar animation
      progressInterval = setInterval(() => {
        const elapsed = Date.now() - startTime;
        const progress = Math.min((elapsed / sheetGenerationTimes.all) * 100, 99);
        setSheetProgress((prev) => ({ ...prev, all: progress }));
      }, 100);

      const [inventoryUrl, replenishUrl, orderUrl] = await Promise.all([
        generateInventorySheet(id, true),
        generateReplenishSheet(id, true),
        generateOrderSheet(id, true),
      ]);

      // Update the location state with the new sheet URLs
      setLocation((prevLocation) => ({
        ...prevLocation,
        InventorySheet: inventoryUrl,
        RecommendedInventorySheet: replenishUrl,
        OrderSheet: orderUrl,
      }));

      // Set progress to 100%
      setSheetProgress((prev) => ({ ...prev, all: 100 }));

      // Update average generation time for all sheets
      const generationTime = Date.now() - startTime;
      setSheetGenerationTimes((prev) => ({
        ...prev,
        all: (prev.all + generationTime) / 2,
      }));

      setSnackbarMessage('All sheets generated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error generating sheets:', err);
      setError('Failed to generate sheets. Please try again.');
      setSnackbarMessage('Failed to generate sheets. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      clearInterval(progressInterval);
      setGeneratingAllSheets(false);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSheetNotificationClose = () => {
    setSheetNotificationOpen(false);
  };

  const handleOpenEmbeddedSheet = (sheetType) => {
    navigate(`/locations/${id}/embedded-sheet/${sheetType}`);
  };

  const fetchTransferOrders = async () => {
    try {
      const orders = await getTransferOrders();
      const relevantOrders = orders.filter(order => 
        (order.to_location_id === parseInt(id) || order.from_locations.some(loc => loc.from_location_id === parseInt(id))) &&
        order.status_name !== 'Completed' && order.status_name !== 'Cancelled'
      );
      setTransferOrders(relevantOrders);
    } catch (error) {
      console.error('Error fetching transfer orders:', error);
      setError('Failed to fetch transfer orders. Please try again later.');
    }
  };

  const handleOpenDialog = (content, title) => {
    setDialogContent({ content, title });
    setOpenDialog(true);
  };

  const handleUpdateInventory = async () => {
    try {
      setInventoryUpdating(true);
      setInventoryChanges(null); // Reset inventory changes
      const result = await updateInventoryForLocation(id);
      setSnackbarMessage(result.message);
      setSnackbarSeverity('info');
      setSnackbarOpen(true);

      // Start polling for status updates
      const pollInterval = setInterval(async () => {
        try {
          const status = await getInventoryUpdateStatus(id);
          if (status.success !== undefined) {
            clearInterval(pollInterval);
            setInventoryUpdating(false);
            if (status.success) {
              setInventoryChanges(status.changes);
              setLocation(prevLocation => ({
                ...prevLocation,
                TotalInventory: status.changes.current.G + status.changes.current.M + status.changes.current.G2 + status.changes.current.M2,
                InventoryByCategory: status.changes.current
              }));
              // Clear inventory changes after updating the location state
              setTimeout(() => setInventoryChanges(null), 5000);
            }
            setSnackbarMessage(status.message);
            setSnackbarSeverity(status.success ? 'success' : 'error');
            setSnackbarOpen(true);
          }
        } catch (error) {
          console.error('Error polling inventory update status:', error);
        }
      }, 5000); // Poll every 5 seconds

      // Clean up the interval on component unmount
      return () => clearInterval(pollInterval);
    } catch (error) {
      console.error('Error starting inventory update:', error);
      setSnackbarMessage('Failed to start inventory update. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setInventoryUpdating(false);
    }
  };

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <Typography color="error" variant="h6">
        {error}
      </Typography>
    );
  if (!location)
    return (
      <Typography color="error" variant="h6">
        Location not found
      </Typography>
    );

  return (
    <StyledContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Title variant="h4">{location.name}</Title>
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <CardTitle variant="h6">
                    <InventoryIcon /> Location Type
                  </CardTitle>
                  {editingLocationType ? (
                    <FormControl fullWidth>
                      <InputLabel id="location-type-label">Location Type</InputLabel>
                      <Select
                        labelId="location-type-label"
                        value={locationType}
                        onChange={handleLocationTypeChange}
                      >
                        <MenuItem value="store">Store</MenuItem>
                        <MenuItem value="warehouse">Warehouse</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <IconButton
                          onClick={() => setEditingLocationType(false)}
                          color="secondary"
                        >
                          <UndoIcon />
                        </IconButton>
                        <IconButton onClick={saveLocationType} color="primary">
                          <SaveIcon />
                        </IconButton>
                      </Box>
                    </FormControl>
                  ) : (
                    <Box>
                      <Typography>{locationType || 'Not set'}</Typography>
                      <IconButton onClick={() => setEditingLocationType(true)} color="primary">
                        <EditIcon />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CardTitle variant="h6">
                    <LocalShippingIcon /> Active Transfer Orders
                  </CardTitle>
                  <Typography>
                    {transferOrders.length > 0 
                      ? `${transferOrders.length} order${transferOrders.length > 1 ? 's' : ''}`
                      : 'No active orders'}
                  </Typography>
                  {transferOrders.length > 0 && (
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => handleOpenDialog(transferOrders, "Transfer Orders")}
                      startIcon={<LocalShippingIcon />}
                    >
                      View Details
                    </Button>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">
                <InventoryIcon /> Inventory Levels
              </CardTitle>
              <Typography variant="h5" gutterBottom>
                Total: {location.TotalInventory || 'N/A'}
                {inventoryUpdating && <LoadingDots />}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Grid container spacing={2}>
                {location.inventoryTypes && location.inventoryTypes.map((type) => (
                  <Grid item xs={6} sm={4} md={3} key={type}>
                    <InventoryCategory>
                      {type}: {location.InventoryByCategory?.[type] || 'N/A'}
                      {inventoryUpdating && <LoadingDots />}
                      {inventoryChanges && !inventoryUpdating && (
                        <Typography variant="body2" color="textSecondary">
                          Previous: {inventoryChanges.previous[type] || 0}
                        </Typography>
                      )}
                    </InventoryCategory>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <StyledButton
                  onClick={handleUpdateInventory}
                  variant="contained"
                  color="primary"
                  startIcon={<RefreshIcon />}
                  disabled={inventoryUpdating}
                >
                  {inventoryUpdating ? 'Updating' : 'Update Inventory'}
                </StyledButton>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">
                <AssessmentIcon /> Replenish Levels
              </CardTitle>
              <Grid container spacing={2}>
                {location.replenishTypes && location.replenishTypes.map((type) => (
                  <Grid item xs={6} sm={4} md={3} key={type}>
                    <Typography variant="body1">
                      {type}: {location.ReplenishLevels?.[type] ?? 'N/A'}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <StyledButton
                  onClick={handleUpdateReplenishLevels}
                  variant="contained"
                  color="primary"
                >
                  Edit Replenish Levels
                </StyledButton>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">
                <LinkIcon /> Sheet Links
              </CardTitle>
              <SheetLinks>
                {location.InventorySheet && (
                  <Box display="flex" alignItems="center" position="relative">
                    {generatingSheet.inventory || generatingAllSheets ? (
                      <LinearProgress
                        variant="determinate"
                        value={sheetProgress.inventory}
                        sx={{ width: '100%', position: 'absolute', height: '100%' }}
                      />
                    ) : null}
                    <SheetButton
                      href={location.InventorySheet}
                      target="_blank"
                      rel="noopener noreferrer"
                      startIcon={<DescriptionIcon />}
                      disabled={generatingAllSheets || generatingSheet.inventory}
                      sx={{ opacity: generatingSheet.inventory || generatingAllSheets ? 0.5 : 1 }}
                    >
                      Inventory Sheet
                    </SheetButton>
                    <EmbedButton
                      onClick={() => handleOpenEmbeddedSheet('inventory')}
                      disabled={generatingAllSheets || generatingSheet.inventory}
                    >
                      <OpenInNewIcon fontSize="small" />
                    </EmbedButton>
                    <GetNewButton
                      variant="contained"
                      color="primary"
                      onClick={() => handleGenerateSheet('inventory')}
                      disabled={generatingAllSheets || generatingSheet.inventory}
                    >
                      {generatingSheet.inventory ? 'Generating...' : 'Get New'}
                    </GetNewButton>
                  </Box>
                )}
                {location.RecommendedInventorySheet && (
                  <Box display="flex" alignItems="center" position="relative">
                    {generatingSheet.replenish || generatingAllSheets ? (
                      <LinearProgress
                        variant="determinate"
                        value={sheetProgress.replenish}
                        sx={{ width: '100%', position: 'absolute', height: '100%' }}
                      />
                    ) : null}
                    <SheetButton
                      href={location.RecommendedInventorySheet}
                      target="_blank"
                      rel="noopener noreferrer"
                      startIcon={<AssignmentIcon />}
                      disabled={generatingAllSheets || generatingSheet.replenish}
                      sx={{ opacity: generatingSheet.replenish || generatingAllSheets ? 0.5 : 1 }}
                    >
                      Recommended Inventory
                    </SheetButton>
                    <EmbedButton
                      onClick={() => handleOpenEmbeddedSheet('replenish')}
                      disabled={generatingAllSheets || generatingSheet.replenish}
                    >
                      <OpenInNewIcon fontSize="small" />
                    </EmbedButton>
                    <GetNewButton
                      variant="contained"
                      color="primary"
                      onClick={() => handleGenerateSheet('replenish')}
                      disabled={generatingAllSheets || generatingSheet.replenish}
                    >
                      {generatingSheet.replenish ? 'Generating...' : 'Get New'}
                    </GetNewButton>
                  </Box>
                )}
                {location.OrderSheet && (
                  <Box display="flex" alignItems="center" position="relative">
                    {generatingSheet.order || generatingAllSheets ? (
                      <LinearProgress
                        variant="determinate"
                        value={sheetProgress.order}
                        sx={{ width: '100%', position: 'absolute', height: '100%' }}
                      />
                    ) : null}
                    <SheetButton
                      href={location.OrderSheet}
                      target="_blank"
                      rel="noopener noreferrer"
                      startIcon={<ShoppingCartIcon />}
                      disabled={generatingAllSheets || generatingSheet.order}
                      sx={{ opacity: generatingSheet.order || generatingAllSheets ? 0.5 : 1 }}
                    >
                      Order Sheet
                    </SheetButton>
                    <EmbedButton
                      onClick={() => handleOpenEmbeddedSheet('order')}
                      disabled={generatingAllSheets || generatingSheet.order}
                    >
                      <OpenInNewIcon fontSize="small" />
                    </EmbedButton>
                    <GetNewButton
                      variant="contained"
                      color="primary"
                      onClick={() => handleGenerateSheet('order')}
                      disabled={generatingAllSheets || generatingSheet.order}
                    >
                      {generatingSheet.order ? 'Generating...' : 'Get New'}
                    </GetNewButton>
                  </Box>
                )}
              </SheetLinks>
              <Box sx={{ mt: 2 }}>
                {generatingAllSheets ? (
                  <LinearProgress
                    variant="determinate"
                    value={sheetProgress.all}
                    sx={{ mb: 2 }}
                  />
                ) : null}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReloadAllSheets}
                  disabled={generatingAllSheets}
                >
                  {generatingAllSheets ? 'Generating All Sheets...' : 'Create All Sheets'}
                </Button>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <CardTitle variant="h6">
                <InventoryIcon style={{ color: '#34495e' }} /> Inventory Chart
              </CardTitle>
              <Box
                sx={{
                  mb: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={chartType === 'bar'}
                      onChange={() => setChartType(chartType === 'bar' ? 'line' : 'bar')}
                      name="chartTypeSwitch"
                      color="primary"
                    />
                  }
                  label={`Chart Type: ${chartType === 'bar' ? 'Bar' : 'Line'}`}
                />
                <Typography variant="body2" color="textSecondary">
                  Use the save icon in the top-right corner to save the chart as an image.
                </Typography>
              </Box>
              <ReactECharts
                option={{
                  ...getChartOption(),
                  color: ['#3498db', '#e74c3c'], // Update chart colors
                }}
                style={{ height: '400px' }}
              />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>
          <DeleteIcon style={{ marginRight: 8 }} /> Delete
        </MenuItem>
      </Menu>

      <StyledDialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the location "{location.name}"? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={handleDeleteCancel} color="primary" variant="outlined">
            Cancel
          </StyledButton>
          <StyledButton onClick={handleDeleteConfirm} color="error" variant="contained" autoFocus>
            Delete
          </StyledButton>
        </DialogActions>
      </StyledDialog>

      {/* Snackbar for general messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Snackbar for sheet notifications */}
      <Snackbar
        open={sheetNotificationOpen}
        onClose={handleSheetNotificationClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <Button color="secondary" size="small" onClick={sheetNotificationAction}>
            Open Sheet
          </Button>
        }
        message={sheetNotificationMessage}
      />

      {/* Add this to your existing Dialog component or create a new one */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>{dialogContent?.title}</DialogTitle>
        <DialogContent>
          {dialogContent?.title === "Transfer Orders" && (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order ID</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dialogContent.content.map((order) => (
                    <TableRow key={order.id}>
                      <TableCell>{order.id}</TableCell>
                      <TableCell>{order.from_locations.map(loc => loc.from_location_name).join(', ')}</TableCell>
                      <TableCell>{order.to_location_name}</TableCell>
                      <TableCell>{order.status_name}</TableCell>
                      <TableCell>{new Date(order.created_at).toLocaleString()}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {/* ... (other dialog content conditions) */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
}

export default LocationDetails;