import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  Chip,
  TextField,
  IconButton,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Edit as EditIcon,
  LocalShipping as ShippingIcon,
  Assignment as AssignmentIcon,
  Comment as CommentIcon,
  Send as SendIcon,
  CheckCircle as ApproveIcon,
  Cancel as RejectIcon,
} from '@mui/icons-material';
import { 
  getOrderById, 
  updateOrderStatus, 
  addOrderComment,
  getOrderComments,
  getOrderSpreadsheetData,
  getOrderTypes,
} from '../services/api';
import { format } from 'date-fns';
import SpreadsheetEditable from './spreadsheets/SpreadsheetEditable';
import { toast } from 'react-hot-toast';

const ChatContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '400px',
  backgroundColor: '#f8f9fa',
  borderRadius: '8px',
  overflow: 'hidden',
});

const ChatMessages = styled('div')({
  flex: 1,
  overflowY: 'auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column-reverse',
});

const ChatInputContainer = styled('div')({
  padding: '16px',
  borderTop: '1px solid #e0e0e0',
  backgroundColor: '#fff',
});

const MessageBubble = styled('div')(({ theme, isCurrentUser }) => ({
  maxWidth: '70%',
  padding: '12px 16px',
  borderRadius: '12px',
  marginBottom: '8px',
  alignSelf: isCurrentUser ? 'flex-end' : 'flex-start',
  backgroundColor: isCurrentUser ? theme.palette.primary.main : '#e0e0e0',
  color: isCurrentUser ? theme.palette.primary.contrastText : 'inherit',
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`order-tabpanel-${index}`}
      aria-labelledby={`order-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function OrderDetails() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedType, setSelectedType] = useState('');
  const [types, setTypes] = useState([]);
  const [spreadsheetData, setSpreadsheetData] = useState({});
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');

  const statusOptions = {
    pending: { label: 'Pending', color: 'default' },
    processing: { label: 'Processing', color: 'info' },
    completed: { label: 'Completed', color: 'success' },
    cancelled: { label: 'Cancelled', color: 'error' },
  };

  useEffect(() => {
    fetchOrderData();
  }, [orderId]);

  useEffect(() => {
    if (currentTab === 1) {
      loadSpreadsheetData();
    }
  }, [currentTab, selectedType]);

  const fetchOrderData = async () => {
    try {
      setLoading(true);
      const [orderData, commentsData] = await Promise.all([
        getOrderById(orderId),
        getOrderComments(orderId)
      ]);
      setOrder(orderData);
      setComments(commentsData || []);
    } catch (err) {
      setError(err.message || 'Failed to fetch order details');
    } finally {
      setLoading(false);
    }
  };

  const handleStatusChange = async () => {
    try {
      await updateOrderStatus(orderId, selectedStatus);
      await fetchOrderData();
      setStatusDialogOpen(false);
      setSelectedStatus('');
    } catch (err) {
      setError(err.message || 'Failed to update status');
    }
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    try {
      await addOrderComment(orderId, newComment);
      await fetchOrderData();
      setNewComment('');
    } catch (err) {
      setError(err.message || 'Failed to add comment');
    }
  };

  const loadSpreadsheetData = async () => {
    try {
      setError(null);
      
      if (types.length === 0) {
        const availableTypes = await getOrderTypes(orderId);
        setTypes(availableTypes);
        if (availableTypes.length > 0 && !selectedType) {
          setSelectedType(availableTypes[0]);
        }
      }
      
      if (selectedType) {
        const data = await getOrderSpreadsheetData(orderId, selectedType);
        setSpreadsheetData(data);
      }
    } catch (error) {
      setError(error.message || 'Failed to load spreadsheet data');
    }
  };

  const handleApproveRequest = async () => {
    try {
      await updateOrderStatus(orderId, 'processing');
      await fetchOrderData();
      toast.success('Order request approved successfully');
    } catch (error) {
      setError(error.message || 'Failed to approve order request');
    }
  };

  const handleRejectRequest = async () => {
    try {
      if (!rejectionReason.trim()) {
        throw new Error('Rejection reason is required');
      }
      await updateOrderStatus(orderId, 'rejected', rejectionReason);
      await fetchOrderData();
      setRejectDialogOpen(false);
      setRejectionReason('');
      toast.success('Order request rejected successfully');
    } catch (error) {
      setError(error.message || 'Failed to reject order request');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!order) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Alert severity="info">Order not found</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h4">
            Order {order.order_number}
          </Typography>
          <Box>
            <Button 
              variant="outlined" 
              onClick={() => setStatusDialogOpen(true)}
              sx={{ mr: 1 }}
            >
              Update Status
            </Button>
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => navigate(`/orders/${orderId}/edit`)}
            >
              Edit Order
            </Button>
          </Box>
        </Box>

        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
        >
          <Tab label="Details" />
          <Tab label="Items Spreadsheet" />
          <Tab label="Comments" />
        </Tabs>

        <TabPanel value={currentTab} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Order Details
              </Typography>
              <Box mb={2}>
                <Chip 
                  label={statusOptions[order.status]?.label || order.status}
                  color={statusOptions[order.status]?.color || 'default'}
                  sx={{ mb: 1 }}
                />
              </Box>
              <Typography><strong>Order Number:</strong> {order.order_number}</Typography>
              <Typography><strong>Customer:</strong> {order.company_name}</Typography>
              {order.notes && (
                <Typography><strong>Notes:</strong> {order.notes}</Typography>
              )}
              {order.is_customer_request && (
                <Box mt={2}>
                  <Typography variant="h6" gutterBottom>
                    Request Details
                  </Typography>
                  <Typography><strong>Requested By:</strong> {order.customer_name}</Typography>
                  <Typography><strong>Requested At:</strong> {format(new Date(order.requested_at), 'PPpp')}</Typography>
                  {order.eta_type === 'date' && (
                    <Typography><strong>Requested ETA:</strong> {format(new Date(order.requested_eta_date), 'PPp')}</Typography>
                  )}
                  {order.customer_notes && (
                    <Typography><strong>Customer Notes:</strong> {order.customer_notes}</Typography>
                  )}
                  {order.request_status === 'awaiting_approval' && (
                    <Box mt={2}>
                      <Button
                        variant="contained"
                        color="success"
                        startIcon={<ApproveIcon />}
                        onClick={handleApproveRequest}
                        sx={{ mr: 2 }}
                      >
                        Approve Request
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<RejectIcon />}
                        onClick={() => setRejectDialogOpen(true)}
                      >
                        Reject Request
                      </Button>
                    </Box>
                  )}
                  {order.request_status === 'rejected' && (
                    <Typography color="error" mt={1}>
                      <strong>Rejection Reason:</strong> {order.rejection_reason}
                    </Typography>
                  )}
                </Box>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Dates
              </Typography>
              <Typography>
                <strong>Created:</strong> {format(new Date(order.created_at), 'PPpp')}
              </Typography>
              <Typography>
                <strong>Last Updated:</strong> {format(new Date(order.updated_at), 'PPpp')}
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Order Items Spreadsheet
            </Typography>
            
            <TextField
              select
              label="Item Type"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
              sx={{ minWidth: 200, mb: 2 }}
              SelectProps={{
                native: true,
              }}
            >
              <option value="">Select type</option>
              {types.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </TextField>

            {spreadsheetData && Object.keys(spreadsheetData).length > 0 && (
              <SpreadsheetEditable
                data={spreadsheetData}
                title={`Order Items - ${selectedType}`}
                readOnly={true}
              />
            )}
          </Box>
        </TabPanel>

        <TabPanel value={currentTab} index={2}>
          <Typography variant="h6" gutterBottom>
            Comments & Updates
          </Typography>
          <ChatContainer>
            <ChatMessages>
              {comments.map((comment) => (
                <Box key={comment.comment_id} sx={{ mb: 2 }}>
                  <MessageBubble 
                    isCurrentUser={
                      (!comment.is_customer_comment && comment.commenter_id === parseInt(localStorage.getItem('userId'))) ||
                      (comment.is_customer_comment && comment.commenter_id === parseInt(localStorage.getItem('customerId')))
                    }
                  >
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <Typography variant="subtitle2" component="span">
                        {comment.user_name}
                        {comment.is_customer_comment && ' (Customer)'}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {format(new Date(comment.created_at), 'PPpp')}
                      </Typography>
                    </Box>
                    <Typography>{comment.comment_text}</Typography>
                  </MessageBubble>
                </Box>
              ))}
            </ChatMessages>
            <ChatInputContainer>
              <form onSubmit={handleAddComment}>
                <Box display="flex" gap={1}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Type a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    variant="outlined"
                  />
                  <IconButton 
                    type="submit"
                    disabled={!newComment.trim()}
                    color="primary"
                  >
                    <SendIcon />
                  </IconButton>
                </Box>
              </form>
            </ChatInputContainer>
          </ChatContainer>
        </TabPanel>
      </Paper>

      {/* Status Update Dialog */}
      <Dialog open={statusDialogOpen} onClose={() => setStatusDialogOpen(false)}>
        <DialogTitle>Update Order Status</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            label="Status"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
            margin="normal"
            SelectProps={{
              native: true,
            }}
          >
            <option value="">Select status</option>
            {Object.entries(statusOptions).map(([value, { label }]) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setStatusDialogOpen(false)}>Cancel</Button>
          <Button 
            onClick={handleStatusChange}
            disabled={!selectedStatus}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={rejectDialogOpen} onClose={() => setRejectDialogOpen(false)}>
        <DialogTitle>Reject Order Request</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Rejection Reason"
            fullWidth
            multiline
            rows={4}
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRejectDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleRejectRequest} color="error" variant="contained">
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default OrderDetails; 