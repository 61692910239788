import React, { useState, useEffect } from 'react';
import { fetchUsers, fetchRoles, addUser, updateUser, deleteUser } from '../services/api';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, FormHelperText, Snackbar, Alert, Box, FormControl, InputLabel, InputAdornment } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Edit, Delete, Add, ContentCopy, WhatsApp, Email, CheckCircleOutline, PersonAdd, PersonOutline, EmailOutlined, LockOutlined, InfoOutlined, Badge, Save } from '@mui/icons-material';
import CustomButton from './CustomButton';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: '#ffffff',
  borderRadius: 8,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: '#2c3e50',
  fontFamily: 'Roboto, sans-serif',
  fontWeight: 700,
  fontSize: '2rem',
}));

const AddButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(1, 3),
  borderRadius: theme.shape.borderRadius,
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '1rem',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
  },
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
}));

const SuccessDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
}));

const SuccessIcon = styled(CheckCircleOutline)(({ theme }) => ({
  fontSize: 48,
  color: theme.palette.success.main,
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    overflow: 'hidden',
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '1.5rem',
    fontWeight: 600,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    '& .MuiSvgIcon-root': {
      fontSize: '2rem',
    },
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    backgroundColor: '#f8fafc',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(3),
    backgroundColor: '#f8fafc',
    borderTop: '1px solid #e2e8f0',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    backgroundColor: '#ffffff',
    transition: 'all 0.2s ease-in-out',
    border: '1px solid #e2e8f0',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    },
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
      backgroundColor: '#ffffff',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.15)',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#64748b',
    fontWeight: 500,
  },
  '& .MuiInputBase-input': {
    padding: '16px',
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: 12,
  backgroundColor: '#ffffff',
  transition: 'all 0.2s ease-in-out',
  border: '1px solid #e2e8f0',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
  },
  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
    backgroundColor: '#ffffff',
    boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.15)',
  },
  '& .MuiSelect-select': {
    padding: '16px',
  },
}));

const DialogButton = styled(Button)(({ theme }) => ({
  borderRadius: 12,
  padding: '12px 24px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '0.95rem',
  boxShadow: 'none',
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0 4px 6px rgba(66, 153, 225, 0.2)',
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.action.disabledBackground,
      color: theme.palette.action.disabled,
    },
  },
  '&.MuiButton-outlined': {
    borderColor: '#e2e8f0',
    color: '#64748b',
    '&:hover': {
      backgroundColor: '#f8fafc',
      borderColor: '#cbd5e1',
    },
  },
}));

function UserManagement() {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState({ username: '', email: '', role_id: '', password: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [newUserCredentials, setNewUserCredentials] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchUserData();
    fetchRoleData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetchUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchRoleData = async () => {
    try {
      const response = await fetchRoles();
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleOpenDialog = (user = { username: '', email: '', role_id: '', password: '' }) => {
    setCurrentUser(user);
    setIsEditing(!!user.id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentUser({ username: '', email: '', role_id: '', password: '' });
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
    if (name === 'password') {
      setPasswordError(validatePassword(value));
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number';
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return 'Password must contain at least one special character (!@#$%^&*)';
    }
    return '';
  };

  const handleSubmit = async () => {
    if (!isEditing && passwordError) {
      return;
    }
    try {
      if (isEditing) {
        await updateUser(currentUser.id, currentUser);
        setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
      } else {
        const response = await addUser(currentUser);
        setNewUserCredentials(response.data);
        setSuccessDialogOpen(true);
      }
      fetchUserData();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving user:', error);
      const errorMessage = error.response?.data?.error || error.message || 'An unknown error occurred';
      setSnackbar({ open: true, message: `Error: ${errorMessage}`, severity: 'error' });
    }
  };

  const handleEditUser = async () => {
    try {
      await updateUser(currentUser.id, currentUser);
      setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
      fetchUserData();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating user:', error);
      setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(userId);
        setSnackbar({ open: true, message: 'User deleted successfully', severity: 'success' });
        fetchUserData();
      } catch (error) {
        console.error('Error deleting user:', error);
        setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
      }
    }
  };

  const handleCopyCredentials = () => {
    const credentialsText = `Username: ${newUserCredentials.user.username}\nPassword: ${newUserCredentials.password}`;
    navigator.clipboard.writeText(credentialsText);
    setSnackbar({ open: true, message: 'Credentials copied to clipboard', severity: 'success' });
  };

  const handleShareWhatsApp = () => {
    const credentialsText = `Your new account credentials:\nUsername: ${newUserCredentials.user.username}\nPassword: ${newUserCredentials.password}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(credentialsText)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleShareEmail = () => {
    const subject = 'Your New Account Credentials';
    const body = `Your new account credentials:\n\nUsername: ${newUserCredentials.user.username}\nPassword: ${newUserCredentials.password}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };

  return (
    <StyledContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Title variant="h4">User Management</Title>
        <AddButton
          startIcon={<Add />}
          onClick={() => handleOpenDialog()}
          variant="contained"
        >
          Add New User
        </AddButton>
      </Box>
      
      <StyledPaper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{roles.find(role => role.role_id === user.role_id)?.role_name}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDialog(user)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(user.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <StyledDialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {isEditing ? (
            <>
              <Edit /> Edit User
            </>
          ) : (
            <>
              <PersonAdd /> Add New User
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              {isEditing 
                ? "Update user information and permissions"
                : "Create a new user account with appropriate permissions"
              }
            </Typography>
          </Box>
          <StyledTextField
            autoFocus
            name="username"
            label="Username"
            fullWidth
            value={currentUser.username}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutline sx={{ color: '#64748b' }} />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            name="email"
            label="Email Address"
            fullWidth
            value={currentUser.email}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined sx={{ color: '#64748b' }} />
                </InputAdornment>
              ),
            }}
          />
          {!isEditing && (
            <>
              <StyledTextField
                name="password"
                label="Password"
                type="password"
                fullWidth
                value={currentUser.password}
                onChange={handleInputChange}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined sx={{ color: '#64748b' }} />
                    </InputAdornment>
                  ),
                }}
              />
              <Paper 
                elevation={0} 
                sx={{ 
                  p: 2, 
                  backgroundColor: '#f1f5f9',
                  border: '1px solid #e2e8f0',
                  borderRadius: 3
                }}
              >
                <Typography variant="subtitle2" color="primary" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <InfoOutlined fontSize="small" /> Password Requirements
                </Typography>
                <Box component="ul" sx={{ m: 0, pl: 2 }}>
                  {[
                    'At least 8 characters',
                    'One uppercase letter',
                    'One lowercase letter',
                    'One number',
                    'One special character (!@#$%^&*)'
                  ].map((req, index) => (
                    <Typography 
                      key={index} 
                      component="li" 
                      variant="body2" 
                      color="textSecondary"
                      sx={{ 
                        mt: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        '&::before': {
                          content: '"✓"',
                          color: theme.palette.success.main,
                        }
                      }}
                    >
                      {req}
                    </Typography>
                  ))}
                </Box>
              </Paper>
            </>
          )}
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <StyledSelect
              name="role_id"
              value={currentUser.role_id}
              onChange={handleInputChange}
              label="Role"
              startAdornment={
                <InputAdornment position="start">
                  <Badge sx={{ color: '#64748b' }} />
                </InputAdornment>
              }
            >
              {roles.map((role) => (
                <MenuItem key={role.role_id} value={role.role_id}>
                  {role.role_name}
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <DialogButton 
            onClick={handleCloseDialog}
            variant="outlined"
          >
            Cancel
          </DialogButton>
          <DialogButton
            onClick={handleSubmit}
            disabled={!isEditing && (!!passwordError || !currentUser.password)}
            variant="contained"
            startIcon={isEditing ? <Save /> : <Add />}
          >
            {isEditing ? 'Save Changes' : 'Create User'}
          </DialogButton>
        </DialogActions>
      </StyledDialog>

      <SuccessDialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
        <DialogTitle>
          <SuccessIcon />
          User Created Successfully
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Username: {newUserCredentials?.user.username}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Password: {newUserCredentials?.password}
          </Typography>
          <ActionButton
            variant="outlined"
            startIcon={<ContentCopy />}
            onClick={handleCopyCredentials}
          >
            Copy to Clipboard
          </ActionButton>
          <ActionButton
            variant="outlined"
            startIcon={<WhatsApp />}
            onClick={handleShareWhatsApp}
          >
            Share via WhatsApp
          </ActionButton>
          <ActionButton
            variant="outlined"
            startIcon={<Email />}
            onClick={handleShareEmail}
          >
            Share via Email
          </ActionButton>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setSuccessDialogOpen(false)}>Close</CustomButton>
        </DialogActions>
      </SuccessDialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}

export default UserManagement;
