import React, { useState, useCallback } from 'react';
import SpreadsheetEditable from './SpreadsheetEditable';

function TestSpreadsheet3() {
  // Sample initial data
  const [spreadsheetData, setSpreadsheetData] = useState({
    '6': {
      '57': {
        'XR': 10,
        'R': 15,
        'L': 12,
        'X': 8
      },
      '58': {
        'XR': 5,
        'R': 7,
        'L': 9,
        'X': 4
      }
    },
    '7': {
      '57': {
        'XR': 20,
        'R': 25,
        'L': 22,
        'X': 18
      },
      '58': {
        'XR': 15,
        'R': 17,
        'L': 19,
        'X': 14
      }
    }
  });

  const handleCellValueChanged = useCallback(({ size, brim, shape, newValue, oldValue }) => {
    console.log('Cell value changed:', { size, brim, shape, newValue, oldValue });
    
    // Update local state
    setSpreadsheetData(prevData => {
      const newData = { ...prevData };
      if (!newData[size]) newData[size] = {};
      if (!newData[size][brim]) newData[size][brim] = {};
      newData[size][brim][shape] = newValue;
      return newData;
    });

    // Here you would typically make an API call to update the backend
    // Example:
    // api.updateSpreadsheetData({ size, brim, shape, value: newValue })
    //   .then(response => {
    //     console.log('Successfully updated backend:', response);
    //   })
    //   .catch(error => {
    //     console.error('Failed to update backend:', error);
    //     // Optionally revert the change in the UI
    //     setSpreadsheetData(prevData => {
    //       const newData = { ...prevData };
    //       newData[size][brim][shape] = oldValue;
    //       return newData;
    //     });
    //   });
  }, []);

  return (
    <div style={{ padding: '20px' }}>
      <SpreadsheetEditable
        data={spreadsheetData}
        title="Editable Inventory Spreadsheet"
        onCellValueChanged={handleCellValueChanged}
      />
    </div>
  );
}

export default TestSpreadsheet3; 