import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Box,
  Tab,
  Tabs,
  Autocomplete,
  Chip,
  Alert,
  Snackbar,
  CircularProgress,
  Checkbox
} from '@mui/material';
import { Delete as DeleteIcon, Add as AddIcon, Search as SearchIcon, FilterList as FilterIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  getAllItemForwarders,
  createItemForwarder,
  deleteItemForwarder,
  createBatchItemForwarders,
  deleteBatchItemForwarders,
  getAllItems
} from '../services/api';
import InputAdornment from '@mui/material/InputAdornment';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

function ItemForwarders() {
  const [forwarders, setForwarders] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openBatchDialog, setOpenBatchDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [sourceItem, setSourceItem] = useState(null);
  const [targetItem, setTargetItem] = useState(null);
  const [oldBrim, setOldBrim] = useState('');
  const [newBrim, setNewBrim] = useState('');
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [filters, setFilters] = useState({
    search: '',
    type: '',
    size: '',
    brim: '',
    dateFrom: '',
    dateTo: ''
  });
  const [selectedForwarders, setSelectedForwarders] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [deleteProgress, setDeleteProgress] = useState({ current: 0, total: 0 });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [forwardersData, itemsData] = await Promise.all([
        getAllItemForwarders(),
        getAllItems()
      ]);
      setForwarders(forwardersData);
      setItems(itemsData);
    } catch (error) {
      showSnackbar('Error fetching data', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateForwarder = async () => {
    try {
      await createItemForwarder(sourceItem.id, targetItem.id);
      showSnackbar('Item forwarder created successfully');
      fetchData();
      setOpenDialog(false);
      resetForm();
    } catch (error) {
      showSnackbar(error.response?.data?.error || 'Error creating forwarder', 'error');
    }
  };

  const handleCreateBatchForwarders = async () => {
    try {
      await createBatchItemForwarders(oldBrim, newBrim, selectedSizes);
      showSnackbar('Batch forwarders created successfully');
      fetchData();
      setOpenBatchDialog(false);
      resetForm();
    } catch (error) {
      showSnackbar(error.response?.data?.error || 'Error creating batch forwarders', 'error');
    }
  };

  const handleDeleteForwarder = async (id) => {
    try {
      await deleteItemForwarder(id);
      showSnackbar('Forwarder deleted successfully');
      fetchData();
    } catch (error) {
      showSnackbar('Error deleting forwarder', 'error');
    }
  };

  const handleDeleteSelected = async (selectedIds) => {
    try {
      await deleteBatchItemForwarders(selectedIds);
      showSnackbar('Selected forwarders deleted successfully');
      fetchData();
    } catch (error) {
      showSnackbar('Error deleting forwarders', 'error');
    }
  };

  const resetForm = () => {
    setSourceItem(null);
    setTargetItem(null);
    setOldBrim('');
    setNewBrim('');
    setSelectedSizes([]);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getUniqueValues = (field) => {
    const values = new Set();
    forwarders.forEach(forwarder => {
      values.add(forwarder[`source_item_${field}`]);
      values.add(forwarder[`target_item_${field}`]);
    });
    return Array.from(values).filter(Boolean);
  };

  const filteredForwarders = forwarders.filter(forwarder => {
    const matchesSearch = filters.search.toLowerCase() === '' || 
      forwarder.source_item_name.toLowerCase().includes(filters.search.toLowerCase()) ||
      forwarder.target_item_name.toLowerCase().includes(filters.search.toLowerCase());

    const matchesType = !filters.type || 
      forwarder.source_item_type === filters.type ||
      forwarder.target_item_type === filters.type;

    const matchesSize = !filters.size || 
      forwarder.source_item_size === filters.size ||
      forwarder.target_item_size === filters.size;

    const matchesBrim = !filters.brim || 
      forwarder.source_item_brim === filters.brim ||
      forwarder.target_item_brim === filters.brim;

    const createdAt = new Date(forwarder.created_at);
    const matchesDateFrom = !filters.dateFrom || 
      createdAt >= new Date(filters.dateFrom);
    const matchesDateTo = !filters.dateTo || 
      createdAt <= new Date(filters.dateTo);

    return matchesSearch && matchesType && matchesSize && 
           matchesBrim && matchesDateFrom && matchesDateTo;
  });

  const handleBulkDelete = async () => {
    try {
      setDeleteInProgress(true);
      const totalItems = selectedForwarders.length;
      setDeleteProgress({ current: 0, total: totalItems });
      
      let successCount = 0;
      let failCount = 0;

      for (let i = 0; i < selectedForwarders.length; i++) {
        try {
          await deleteItemForwarder(selectedForwarders[i]);
          successCount++;
        } catch (error) {
          failCount++;
          console.error(`Failed to delete forwarder ${selectedForwarders[i]}:`, error);
        }
        setDeleteProgress({ current: i + 1, total: totalItems });
      }

      showSnackbar(`Successfully deleted ${successCount} forwarders${failCount > 0 ? `, ${failCount} failed` : ''}`);
      setSelectedForwarders([]);
      setSelectAll(false);
      fetchData();
    } catch (error) {
      showSnackbar('Error during bulk delete operation', 'error');
    } finally {
      setDeleteInProgress(false);
      setDeleteProgress({ current: 0, total: 0 });
    }
  };

  const handleSelectAllSizes = () => {
    const allSizes = [...new Set(items.map(item => item.size))];
    setSelectedSizes(allSizes);
  };

  const renderFilters = () => (
    <Box sx={{ mb: 3, display: 'flex', gap: 2, flexWrap: 'wrap' }}>
      <TextField
        size="small"
        placeholder="Search items..."
        value={filters.search}
        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      
      <Autocomplete
        size="small"
        options={getUniqueValues('type')}
        value={filters.type}
        onChange={(e, value) => setFilters({ ...filters, type: value || '' })}
        renderInput={(params) => (
          <TextField {...params} label="Type" sx={{ minWidth: 150 }} />
        )}
        sx={{ flexGrow: 1 }}
      />

      <Autocomplete
        size="small"
        options={getUniqueValues('size')}
        value={filters.size}
        onChange={(e, value) => setFilters({ ...filters, size: value || '' })}
        renderInput={(params) => (
          <TextField {...params} label="Size" sx={{ minWidth: 150 }} />
        )}
        sx={{ flexGrow: 1 }}
      />

      <Autocomplete
        size="small"
        options={getUniqueValues('brim')}
        value={filters.brim}
        onChange={(e, value) => setFilters({ ...filters, brim: value || '' })}
        renderInput={(params) => (
          <TextField {...params} label="Brim" sx={{ minWidth: 150 }} />
        )}
        sx={{ flexGrow: 1 }}
      />

      <TextField
        size="small"
        type="date"
        label="From Date"
        value={filters.dateFrom}
        onChange={(e) => setFilters({ ...filters, dateFrom: e.target.value })}
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        size="small"
        type="date"
        label="To Date"
        value={filters.dateTo}
        onChange={(e) => setFilters({ ...filters, dateTo: e.target.value })}
        InputLabelProps={{ shrink: true }}
      />

      <Button
        variant="outlined"
        startIcon={<FilterIcon />}
        onClick={() => setFilters({
          search: '',
          type: '',
          size: '',
          brim: '',
          dateFrom: '',
          dateTo: ''
        })}
      >
        Clear Filters
      </Button>
    </Box>
  );

  if (loading) {
    return (
      <StyledContainer>
        <CircularProgress />
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <Typography variant="h4" gutterBottom>
        Item Forwarders
      </Typography>

      <Box sx={{ mb: 3 }}>
        <Tabs value={selectedTab} onChange={(e, newValue) => setSelectedTab(newValue)}>
          <Tab label="Single Forwarder" />
          <Tab label="Batch Forwarders" />
        </Tabs>
      </Box>

      <StyledPaper>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => selectedTab === 0 ? setOpenDialog(true) : setOpenBatchDialog(true)}
            >
              {selectedTab === 0 ? 'Add Forwarder' : 'Add Batch Forwarders'}
            </Button>
            
            {selectedForwarders.length > 0 && (
              <Button
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleBulkDelete}
                disabled={deleteInProgress}
              >
                Delete Selected ({selectedForwarders.length})
              </Button>
            )}
          </Box>
          <Typography variant="subtitle2" color="textSecondary">
            {filteredForwarders.length} results found
          </Typography>
        </Box>

        {renderFilters()}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => {
                      setSelectAll(e.target.checked);
                      setSelectedForwarders(e.target.checked ? filteredForwarders.map(f => f.id) : []);
                    }}
                  />
                </TableCell>
                <TableCell>Source Item</TableCell>
                <TableCell>Target Item</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredForwarders.map((forwarder) => (
                <TableRow key={forwarder.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedForwarders.includes(forwarder.id)}
                      onChange={(e) => {
                        setSelectedForwarders(prev => 
                          e.target.checked 
                            ? [...prev, forwarder.id]
                            : prev.filter(id => id !== forwarder.id)
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {forwarder.source_item_name}
                    <Typography variant="caption" display="block">
                      Type: {forwarder.source_item_type}, Size: {forwarder.source_item_size},
                      Brim: {forwarder.source_item_brim}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {forwarder.target_item_name}
                    <Typography variant="caption" display="block">
                      Type: {forwarder.target_item_type}, Size: {forwarder.target_item_size},
                      Brim: {forwarder.target_item_brim}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {new Date(forwarder.created_at).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteForwarder(forwarder.id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      {/* Single Forwarder Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create Item Forwarder</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={items}
            getOptionLabel={(item) => `${item.name} (${item.type} ${item.size} ${item.brim})`}
            value={sourceItem}
            onChange={(e, value) => setSourceItem(value)}
            renderInput={(params) => (
              <TextField {...params} label="Source Item" margin="normal" fullWidth />
            )}
          />
          <Autocomplete
            options={items}
            getOptionLabel={(item) => `${item.name} (${item.type} ${item.size} ${item.brim})`}
            value={targetItem}
            onChange={(e, value) => setTargetItem(value)}
            renderInput={(params) => (
              <TextField {...params} label="Target Item" margin="normal" fullWidth />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateForwarder}
            disabled={!sourceItem || !targetItem}
            variant="contained"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Batch Forwarders Dialog */}
      <Dialog open={openBatchDialog} onClose={() => setOpenBatchDialog(false)}>
        <DialogTitle>Create Batch Forwarders</DialogTitle>
        <DialogContent>
          <TextField
            label="Old Brim"
            value={oldBrim}
            onChange={(e) => setOldBrim(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            label="New Brim"
            value={newBrim}
            onChange={(e) => setNewBrim(e.target.value)}
            margin="normal"
            fullWidth
          />
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
            <Autocomplete
              multiple
              options={[...new Set(items.map(item => item.size))]}
              value={selectedSizes}
              onChange={(e, value) => setSelectedSizes(value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} label="Sizes" margin="normal" fullWidth />
              )}
              sx={{ flexGrow: 1 }}
            />
            <Button
              variant="outlined"
              onClick={handleSelectAllSizes}
              sx={{ mt: 2 }}
            >
              Select All Sizes
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBatchDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleCreateBatchForwarders}
            disabled={!oldBrim || !newBrim || selectedSizes.length === 0}
            variant="contained"
          >
            Create Batch
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteInProgress} >
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <CircularProgress />
            <Typography>
              Deleting forwarders... ({deleteProgress.current}/{deleteProgress.total})
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}

export default ItemForwarders; 