import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  TextField,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { getOrderDetails, addOrderComment } from '../services/customerapi';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: '10px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const StatusChip = styled(Chip)(({ theme, status }) => ({
  fontWeight: 600,
  backgroundColor: 
    status === 'awaiting_approval' ? theme.palette.warning.light :
    status === 'rejected' ? theme.palette.error.light :
    status === 'processing' ? theme.palette.info.light :
    status === 'shipped' ? theme.palette.success.light :
    theme.palette.grey[100],
  color: 
    status === 'awaiting_approval' ? theme.palette.warning.dark :
    status === 'rejected' ? theme.palette.error.dark :
    status === 'processing' ? theme.palette.info.dark :
    status === 'shipped' ? theme.palette.success.dark :
    theme.palette.grey[800],
}));

function CustomerOrderDetails() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        const data = await getOrderDetails(orderId);
        if (mounted) {
          setOrder(data);
        }
      } catch (error) {
        if (mounted) {
          toast.error('Failed to load order details');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [orderId]);

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      await addOrderComment(orderId, newComment);
      setNewComment('');
      
      const updatedData = await getOrderDetails(orderId);
      setOrder(updatedData);
      
      toast.success('Comment added successfully');
    } catch (error) {
      toast.error('Failed to add comment');
    }
  };

  const renderOrderItems = () => {
    if (!order.items || order.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            No items found
          </TableCell>
        </TableRow>
      );
    }

    return order.items.map((item) => (
      <TableRow key={item.order_item_id}>
        <TableCell>{item.type || 'N/A'}</TableCell>
        <TableCell>{item.size || 'N/A'}</TableCell>
        <TableCell>{item.brim || 'N/A'}</TableCell>
        <TableCell>{item.shape || 'N/A'}</TableCell>
        <TableCell align="right">{item.quantity || 0}</TableCell>
        <TableCell>
          <Chip 
            label={(item.status || 'pending').toUpperCase()}
            size="small"
            color={
              item.status === 'pending' ? 'default' :
              item.status === 'processing' ? 'primary' :
              item.status === 'completed' ? 'success' :
              'default'
            }
          />
        </TableCell>
      </TableRow>
    ));
  };

  const renderComments = () => {
    if (!order.comments || order.comments.length === 0) {
      return (
        <Typography color="textSecondary">
          No comments yet
        </Typography>
      );
    }

    return order.comments.map((comment) => (
      <Box key={comment.comment_id} sx={{ mb: 2 }}>
        <Typography variant="subtitle2" color="textSecondary">
          {comment.user_name}
          {comment.is_customer_comment ? ' (Customer)' : ' (Staff)'}
          {' • '}
          {new Date(comment.created_at).toLocaleString()}
        </Typography>
        <Typography>{comment.comment_text}</Typography>
      </Box>
    ));
  };

  if (loading) {
    return (
      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!order) {
    return (
      <Container>
        <Typography color="error">Order not found</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/customer/dashboard')}
          sx={{ mb: 2 }}
        >
          Back to Dashboard
        </Button>
        
        <Typography variant="h4" gutterBottom>
          Order Details
        </Typography>
      </Box>

      <StyledPaper>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Order Number
            </Typography>
            <Typography variant="h6">
              {order.order_number}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Status
            </Typography>
            <StatusChip
              label={order.request_status.replace('_', ' ').toUpperCase()}
              status={order.request_status}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Order Date
            </Typography>
            <Typography>
              {new Date(order.created_at).toLocaleDateString()}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="textSecondary">
              ETA Type
            </Typography>
            <Typography>
              {order.eta_type === 'asap' ? 'As Soon As Possible' : 'Specific Date'}
            </Typography>
          </Grid>

          {order.eta_type === 'date' && (
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Requested ETA Date
              </Typography>
              <Typography>
                {new Date(order.requested_eta_date).toLocaleDateString()}
              </Typography>
            </Grid>
          )}

          {order.rejection_reason && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="error">
                Rejection Reason
              </Typography>
              <Typography color="error">
                {order.rejection_reason}
              </Typography>
            </Grid>
          )}
        </Grid>
      </StyledPaper>

      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Order Items
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item Type</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Brim</TableCell>
                <TableCell>Shape</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderOrderItems()}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Comments
        </Typography>
        
        <Box sx={{ mb: 3 }}>
          <TextField
            fullWidth
            multiline
            rows={3}
            placeholder="Add a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            sx={{ mb: 1 }}
          />
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleAddComment}
            disabled={!newComment.trim()}
          >
            Add Comment
          </Button>
        </Box>

        <Divider sx={{ my: 2 }} />

        {renderComments()}
      </StyledPaper>
    </Container>
  );
}

export default CustomerOrderDetails;