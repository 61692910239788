import React, { useEffect } from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Container, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { checkCustomerAuth, customerLogout } from '../../services/customerapi';

const RootContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: '#f5f7fa',
  display: 'flex',
  flexDirection: 'column'
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#ffffff',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
}));

const Logo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  fontSize: '1.5rem',
  textDecoration: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.dark,
  },
}));

const MainContent = styled(Container)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const CustomerLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = checkCustomerAuth();
  
  const publicPaths = [
    '/customer/login',
    '/customer/forgot-password',
    '/customer/reset-password'
  ];
  
  useEffect(() => {
    // Only redirect to login if not authenticated and not on a public path
    if (!isAuthenticated && !publicPaths.some(path => location.pathname.startsWith(path))) {
      navigate('/customer/login', { 
        state: { from: location },
        replace: true 
      });
    }
  }, [isAuthenticated, location, navigate]);

  const handleLogout = () => {
    customerLogout();
    navigate('/customer/login', { replace: true });
  };

  return (
    <RootContainer>
      <StyledAppBar position="static">
        <Toolbar>
          <Logo 
            variant="h6" 
            component={Link} 
            to={isAuthenticated ? "/customer/dashboard" : "/customer/login"}
            sx={{ textDecoration: 'none' }}
          >
            Pim By Eli Customer Portal
          </Logo>
          {isAuthenticated && !publicPaths.includes(location.pathname) && (
            <Button 
              color="inherit" 
              onClick={handleLogout}
              sx={{ ml: 'auto' }}
            >
              Logout
            </Button>
          )}
        </Toolbar>
      </StyledAppBar>
      <MainContent maxWidth="lg">
        <Outlet />
      </MainContent>
    </RootContainer>
  );
};

export default CustomerLayout; 