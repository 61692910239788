import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { resetPassword } from '../services/customerapi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const StyledPaper = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const Form = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  padding: theme.spacing(1.5),
}));

const PasswordRequirement = styled(ListItem)(({ theme, met }) => ({
  color: met ? theme.palette.success.main : theme.palette.text.secondary,
  '& .MuiListItemIcon-root': {
    color: met ? theme.palette.success.main : theme.palette.error.main,
  },
}));

function CustomerResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Get token from URL query parameters
  const token = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    if (!token) {
      setStatus({
        type: 'error',
        message: 'Invalid reset link. Please request a new password reset.'
      });
    }
  }, [token]);

  const passwordRequirements = [
    { test: pwd => pwd.length >= 8, text: 'At least 8 characters long' },
    { test: pwd => /[A-Z]/.test(pwd), text: 'Contains uppercase letter' },
    { test: pwd => /[a-z]/.test(pwd), text: 'Contains lowercase letter' },
    { test: pwd => /[0-9]/.test(pwd), text: 'Contains number' },
    { test: pwd => /[!@#$%^&*]/.test(pwd), text: 'Contains special character' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setStatus({
        type: 'error',
        message: 'Passwords do not match'
      });
      return;
    }

    const allRequirementsMet = passwordRequirements.every(req => req.test(password));
    if (!allRequirementsMet) {
      setStatus({
        type: 'error',
        message: 'Password does not meet all requirements'
      });
      return;
    }

    setLoading(true);
    setStatus({ type: '', message: '' });

    try {
      await resetPassword(token, password);
      setStatus({
        type: 'success',
        message: 'Password has been reset successfully. You will be redirected to login.'
      });
      setTimeout(() => navigate('/customer/login'), 3000);
    } catch (err) {
      setStatus({
        type: 'error',
        message: err.response?.data?.error || 'Failed to reset password. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <StyledPaper>
        <Typography variant="h4" gutterBottom>
          Pim By Eli
        </Typography>
        <Typography variant="h5" gutterBottom>
          Reset Password
        </Typography>

        {status.message && (
          <Alert 
            severity={status.type} 
            sx={{ width: '100%', mb: 2 }}
          >
            {status.message}
          </Alert>
        )}

        <Form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading || !token}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={loading || !token}
          />

          <List dense>
            {passwordRequirements.map((req, index) => (
              <PasswordRequirement key={index} met={req.test(password)}>
                <ListItemIcon>
                  {req.test(password) ? (
                    <CheckCircleIcon fontSize="small" />
                  ) : (
                    <CancelIcon fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText primary={req.text} />
              </PasswordRequirement>
            ))}
          </List>

          <SubmitButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading || !token}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Reset Password'
            )}
          </SubmitButton>
        </Form>
      </StyledPaper>
    </Container>
  );
}

export default CustomerResetPassword; 