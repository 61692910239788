import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllWarehouses, createWarehouse } from '../services/api';
import LoadingAnimation from './LoadingAnimation';

const Warehouses = () => {
  const navigate = useNavigate();
  const [warehouses, setWarehouses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newWarehouse, setNewWarehouse] = useState({
    name: '',
    code: '',
    total_capacity: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  useEffect(() => {
    fetchWarehouses();
  }, []);

  const fetchWarehouses = async () => {
    try {
      const data = await getAllWarehouses();
      setWarehouses(data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch warehouses',
        severity: 'error',
      });
    }
  };

  const handleCreateWarehouse = async () => {
    try {
      setIsLoading(true);
      await createWarehouse(newWarehouse);
      setOpenDialog(false);
      setNewWarehouse({ name: '', code: '', total_capacity: '' });
      await fetchWarehouses();
      setShowSuccessDialog(true);
      setSnackbar({
        open: true,
        message: 'Warehouse created successfully',
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.response?.data?.error || 'Failed to create warehouse',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleWarehouseClick = (id) => {
    navigate(`/warehouses/${id}`);
  };

  const handleSuccessAction = (action) => {
    setShowSuccessDialog(false);
    if (action === 'details') {
      const newWarehouseId = warehouses[warehouses.length - 1].warehouse_id;
      navigate(`/warehouses/${newWarehouseId}`);
    } else if (action === 'new') {
      setOpenDialog(true);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Warehouses</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
        >
          Add Warehouse
        </Button>
      </Box>

      <Grid container spacing={3}>
        {warehouses.map((warehouse) => (
          <Grid item xs={12} sm={6} md={4} key={warehouse.warehouse_id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                '&:hover': { boxShadow: 6 }
              }}
              onClick={() => handleWarehouseClick(warehouse.warehouse_id)}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {warehouse.name}
                </Typography>
                <Typography color="textSecondary">
                  Code: {warehouse.code}
                </Typography>
                <Typography>
                  Capacity: {warehouse.total_capacity}
                </Typography>
                <Typography>
                  Available: {warehouse.available_capacity}
                </Typography>
                <Typography>
                  Status: {warehouse.status}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Create New Warehouse</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={newWarehouse.name}
            onChange={(e) => setNewWarehouse({ ...newWarehouse, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Code"
            fullWidth
            value={newWarehouse.code}
            onChange={(e) => setNewWarehouse({ ...newWarehouse, code: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Total Capacity"
            type="number"
            fullWidth
            value={newWarehouse.total_capacity}
            onChange={(e) => setNewWarehouse({ ...newWarehouse, total_capacity: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateWarehouse} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading && <LoadingAnimation />}

      <Dialog open={showSuccessDialog} onClose={() => setShowSuccessDialog(false)}>
        <DialogTitle>Warehouse Created Successfully!</DialogTitle>
        <DialogContent>
          <Typography>What would you like to do next?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSuccessAction('stay')}>
            Stay Here
          </Button>
          <Button onClick={() => handleSuccessAction('new')} color="primary">
            Create Another
          </Button>
          <Button onClick={() => handleSuccessAction('details')} variant="contained">
            Go to Warehouse Details
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} onClose={() => setSnackbar({ ...snackbar, open: false })}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Warehouses; 