import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  ArrowBack as ArrowBackIcon,
} from '@mui/icons-material';
import {
  getWarehouseItemsForProduction,
  getProductionEntryById,
  createProductionEntry,
  updateProductionEntry,
} from '../services/api';
import LoadingOverlay from './LoadingOverlay';

const ProductionEntry = () => {
  const { warehouseId, productionId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    warehouse_id: warehouseId,
    notes: '',
    items: []
  });
  const [availableItems, setAvailableItems] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    fetchData();
  }, [warehouseId, productionId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const items = await getWarehouseItemsForProduction(warehouseId);
      setAvailableItems(items);

      if (productionId) {
        const productionData = await getProductionEntryById(productionId);
        setFormData(productionData);
      }
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to fetch data: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddItem = () => {
    setFormData(prev => ({
      ...prev,
      items: [...prev.items, { 
        warehouse_item_id: '', 
        quantity: 0, 
        notes: '' 
      }]
    }));
  };

  const handleRemoveItem = (index) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index)
    }));
  };

  const handleItemChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      items: prev.items.map((item, i) => 
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  const validateForm = () => {
    if (formData.items.length === 0) {
      setSnackbar({
        open: true,
        message: 'Please add at least one item',
        severity: 'error'
      });
      return false;
    }

    for (const item of formData.items) {
      if (!item.warehouse_item_id || item.quantity <= 0) {
        setSnackbar({
          open: true,
          message: 'Please fill in all required fields for each item',
          severity: 'error'
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    
    try {
      setLoading(true);
      if (productionId) {
        await updateProductionEntry(productionId, formData);
      } else {
        await createProductionEntry(formData);
      }
      
      setSnackbar({
        open: true,
        message: `Production entry ${productionId ? 'updated' : 'created'} successfully`,
        severity: 'success'
      });
      
      // Navigate back after successful submission
      setTimeout(() => {
        navigate(`/warehouses/${warehouseId}`);
      }, 1500);
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to ${productionId ? 'update' : 'create'} production entry: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (formData.items.length > 0) {
      setConfirmDialog(true);
    } else {
      navigate(`/warehouses/${warehouseId}`);
    }
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" alignItems="center" mb={3}>
        <IconButton onClick={handleCancel} sx={{ mr: 2 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">
          {productionId ? 'Edit Production Entry' : 'Create Production Entry'}
        </Typography>
      </Box>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Notes"
              value={formData.notes}
              onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6">Production Items</Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleAddItem}
              >
                Add Item
              </Button>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formData.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          select
                          fullWidth
                          value={item.warehouse_item_id}
                          onChange={(e) => handleItemChange(index, 'warehouse_item_id', e.target.value)}
                          SelectProps={{
                            native: true,
                          }}
                          required
                        >
                          <option value="">Select an item</option>
                          {availableItems.map((option) => (
                            <option key={option.warehouse_item_id} value={option.warehouse_item_id}>
                              {option.item_name} ({option.current_quantity} available)
                            </option>
                          ))}
                        </TextField>
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          value={item.quantity}
                          onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value) || 0)}
                          inputProps={{ min: 0 }}
                          required
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          fullWidth
                          value={item.notes || ''}
                          onChange={(e) => handleItemChange(index, 'notes', e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveItem(index)} color="error">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={handleSubmit}
              >
                {productionId ? 'Update' : 'Create'} Production Entry
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
        <DialogTitle>Confirm Navigation</DialogTitle>
        <DialogContent>
          Are you sure you want to leave? Any unsaved changes will be lost.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog(false)}>Cancel</Button>
          <Button 
            onClick={() => navigate(`/warehouses/${warehouseId}`)} 
            color="primary"
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProductionEntry; 